/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useGetCoursesByIdQuery, useUnenrollCourseMutation } from 'services/dataApi';
import Layout from 'components/Layout';
import MyCourseDetails from 'modules/InformationCourses/MyCourseDetails';
import LoadingIcon from 'components/LoadingIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { IState } from 'redux/interfaces';
import ViewFormLayout from 'components/ViewFormLayout';
import { Button, Tooltip } from '@mui/material';
import {
  ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT, COURSE_TYPES_ENUM, FILTERS_OPTIONS_COURSE_STATUS,
} from 'utils/constants';
import { useSnackbar } from 'notistack';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import useHandleApiError from 'hooks/useHandleApiError';

function MyCourseDetailsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const { userId } = useSelector((state: IState) => state.userProfile);
  const { enqueueSnackbar } = useSnackbar();
  const [unenrollSelfService, { isLoading: isLoadingUnenrollService }] = useUnenrollCourseMutation();

  const {
    data: dataCourse,
    isLoading,
    isError,
    error,
  } = useGetCoursesByIdQuery({ userId, learningInstanceId: String(courseId) });

  useHandleApiError(isError, error as FetchBaseQueryError);

  function getLaunchButton(learningInstanceId: number, learningTypeId: number): React.ReactNode {
    if (learningTypeId === COURSE_TYPES_ENUM.ONLINE) {
      return (
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.COURSE_ACTIONS}>
          <Button variant="contained" onClick={() => navigate(`/dashboard/course/launch/${learningInstanceId}`)}>LAUNCH</Button>
        </Tooltip>
      )
    }
    return null;
  }

  const handleClickUnenroll = async () => {
    try {
      await unenrollSelfService({
        userId: Number(userId),
        learningObjectInstanceId: Number(courseId),
      }).unwrap()
      enqueueSnackbar(`You have been unenrolled successfully from ${dataCourse?.courseDetails?.courseName} course`, { variant: 'success' });
      navigate('/dashboard');
    } catch (error) {
      enqueueSnackbar('Something went wrong. Could you try it again?', { variant: 'error' });
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    let navPageName = 'My Course';
    if (dataCourse?.courseDetails?.courseName) {
      navPageName += ` - ${dataCourse?.courseDetails?.courseName}`
    }
    dispatch(setNavigationPage(navPageName))
  }, [dataCourse]);

  return (
    <Layout>
      <ViewFormLayout
        pl={0}
        pr={0}
        testId="content-library-view"
      >
        <ViewFormLayout.ActionBar>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => navigate(-1)}>{BUTTON_TEXT.BACK}</Button>
          </Tooltip>
          {dataCourse && getLaunchButton(dataCourse?.courseDetails?.courseInstanceId, dataCourse?.courseDetails?.courseTypeId)}
          {dataCourse?.courseDetails?.courseProcessStatusTypeName === FILTERS_OPTIONS_COURSE_STATUS.ENROLLED && (
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.UNENROLL_COURSE}>
            <Button disabled={isLoadingUnenrollService} variant="contained" onClick={handleClickUnenroll}>{isLoadingUnenrollService ? BUTTON_TEXT.SAVING : BUTTON_TEXT.UNENROLL}</Button>
          </Tooltip>
          )}
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Body>
          {isLoading ? <LoadingIcon /> : <MyCourseDetails course={dataCourse?.courseDetails} />}
        </ViewFormLayout.Body>

      </ViewFormLayout>
    </Layout>
  );
}

export default MyCourseDetailsPage;
