import React from 'react';
import ViewFormLayout from 'components/ViewFormLayout';
import FormView, { IFormItem } from 'components/FormView';

export default function NewEvaluationQuestionGroupForm() {
  const evaluationRatingForm: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'EvaluationRatingName',
      label: 'Evaluation Question Group Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'Weight',
      label: 'Weight',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'Deduction',
      label: 'Deduction',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'EntityType',
      label: 'Entity Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'Calculation',
      label: 'Calculation',
      titleSize: 3,
    },
  ]
  const internalInformationForm: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'CreatedBy',
      label: 'Created By',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'CreatedDate',
      label: 'Created Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'Updated By',
      label: 'Updated By',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'UpdatedDate',
      label: 'Updated Date',
      titleSize: 3,
    },
  ]
  return (
    <>
      <ViewFormLayout testId="evaluation-question-group">
        <ViewFormLayout.Title>
          Evaluation Question Group
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={evaluationRatingForm}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
      <ViewFormLayout testId="internal-information">
        <ViewFormLayout.Title>
          Internal Information
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={internalInformationForm}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
    </>
  )
}
