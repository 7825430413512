import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import {
  Button,
  Tooltip,
  Stack,
  styled,
  BadgeProps,
  Badge,
  Collapse,
} from '@mui/material';

import ViewFormLayout from 'components/ViewFormLayout';
import { CourseManagementRequest } from 'services/interfaces';
import CoursesTable from './CoursesTable';
import CourseFilterForm from './CourseFilterForm';

export const INITIAL_FILTERS = {
  LearningObjectId: null,
  IsCourseActive: null,
  CourseNumber: null,
  LearningObjectName: null,
  LearningObjectDescription: null,
  WBTTypeId: null,
  OwnerUserId: null,
}

export default function Courses() {
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState<boolean>();
  const [filters, setFilters] = useState<CourseManagementRequest>(INITIAL_FILTERS);

  const handleToggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -10,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const filtersApplyed = (filters: CourseManagementRequest):
  number => Object.values(filters).filter((value) => value !== null && value !== 0).length;

  return (
    <ViewFormLayout testId="administrator-courses">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.NEW_COURSE}>
          <Button variant="contained" onClick={() => navigate(`detail/${1}`)}>{BUTTON_TEXT.NEW_COURSE}</Button>
        </Tooltip>
        <Button
          variant="contained"
          onClick={handleToggleFilters}
        >
          <StyledBadge badgeContent={filtersApplyed(filters)} color="secondary">
            {showFilters ? 'Hide Filters' : 'Filters'}
          </StyledBadge>
        </Button>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <Stack p={1}>
          <Collapse in={showFilters}>
            <CourseFilterForm
              onSearch={(data: CourseManagementRequest) => {
                setFilters(data)
                handleToggleFilters()
              }}
              onReset={() => {
                setFilters(INITIAL_FILTERS);
                handleToggleFilters()
              }}
            />
          </Collapse>
          <CoursesTable filters={filters} />
        </Stack>
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
