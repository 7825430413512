import React, { ReactNode } from 'react';
import DialogModal from 'components/DialogModal';

interface SearchableModalProps {
    open: boolean;
    onClose: Function
    children: ReactNode;
    title: string;
  }

function SearchableModal({
  open, onClose, children, title,
}: SearchableModalProps) {
  return (
    <DialogModal open={open} onClose={onClose}>
      <DialogModal.Title>
        {title}
      </DialogModal.Title>
      <DialogModal.Content backgroundGrey>
        {children}
      </DialogModal.Content>
    </DialogModal>
  );
}

export default SearchableModal;
