import React from 'react';
import { Grid, Typography } from '@mui/material';
import { isEven } from 'utils/helpers'
import { IQuestion } from './Questions';
import Options from './Options';

interface IQuestionProps{
  questionNumber: number;
  question: IQuestion;
}

export default function Question({ question, questionNumber }: IQuestionProps) {
  return (
    <Grid item xs={12}>
      <Grid container sx={{ backgroundColor: (theme) => (isEven(questionNumber) ? 'white' : theme.palette.grey[100]) }}>
        <Grid item xs={2} p={1}>
          <Typography variant="body2">
            {questionNumber}
          </Typography>
        </Grid>
        <Grid item xs={5} p={1}>
          <Typography variant="body2">
            {question.Question}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Options options={question.Options} />
        </Grid>
      </Grid>
    </Grid>
  )
}
