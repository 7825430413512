import React from 'react'
import {
  Box,
  Typography,
  Paper,
  styled,
} from '@mui/material'

function LaunchEndView() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center" height="100%" p={2}>
      <Item>
        <Typography color="green" variant="subtitle2" gutterBottom>
          Thank you for participating in the course! You have successfully completed it.
        </Typography>
      </Item>
    </Box>
  )
}

export default LaunchEndView
