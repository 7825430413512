import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi } from 'services/authApi';
import { dataApi } from 'services/dataApi';
import { tokenApi } from 'services/tokenApi';
import userProfileReducer from './userProfileSlice';
import userDashboardReducer from './userDashboardSlice';
import userContentLibraryReducer from './userContentLibrarySlice';
import userConfigurationReduce from './userConfigurationSlice';
import testLibrariesReducer from './testLibrariesSlice';

const store = configureStore({
  reducer: {
    userDashboard: userDashboardReducer,
    userContentLibrary: userContentLibraryReducer,
    userConfiguration: userConfigurationReduce,
    testLibraries: testLibrariesReducer,
    userProfile: userProfileReducer,
    [authApi.reducerPath]: authApi.reducer,
    [dataApi.reducerPath]: dataApi.reducer,
    [tokenApi.reducerPath]: tokenApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(dataApi.middleware)
    .concat(tokenApi.middleware)
    .concat(authApi.middleware),

});

setupListeners(store.dispatch);

export default store;
