import { FormControlLabel, Grid, Radio } from '@mui/material';
import React from 'react';

interface IRadioButton{
  name: string;
}

export default function RadioButton({ name }: IRadioButton) {
  return (
    <Grid item xs={3} sx={{ textAlign: 'center' }}>
      <FormControlLabel
        value={name}
        control={<Radio />}
        label={name}
        labelPlacement="top"
      />
    </Grid>
  )
}
