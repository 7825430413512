import React, { useEffect, useState } from 'react';
import CustomTable from 'components/CustomTable';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { useGetInstancesFromCourseQuery } from 'services/dataApi';
import { CourseInstance } from 'services/interfaces';
import getType from 'components/TypeIcon';
// eslint-disable-next-line import/extensions

function createData(items: CourseInstance[]): TRows[] {
  return items?.map((item: CourseInstance) => ({
    ...item,
    details: [],
    id: Number(item.instanceId),
    isCollapsable: false,
    type: getType({ type: item?.type }),
  }));
}

export default function CourseInstancesTable({ learningObjectId }: { learningObjectId: string }) {
  // const skipQuery = params?.learningObjectId === undefined
  const { data } = useGetInstancesFromCourseQuery(
    { learningObjectId },
  );

  const [dataSet, setDataSet] = useState<TRows[] | []>([]);

  const columns: TColumns[] = [
    {
      name: 'Course Type',
      column: 'type',
      width: 120,
      sortable: true,
    },
    {
      name: 'Instance ID',
      column: 'instanceId',
      width: 200,
      sortable: true,
    },
    {
      name: 'Facility',
      column: 'facility',
      width: 120,
      sortable: true,
    },
    {
      name: 'Students',
      column: 'students',
      width: 120,
      sortable: true,
    },
    {
      name: 'Max Students',
      column: 'maxStudents',
      width: 120,
      sortable: true,
    },
    {
      name: 'Status',
      column: 'status',
      width: 120,
      sortable: true,
    },
  ];

  useEffect(() => {
    if (data) {
      setDataSet(createData(data))
    }
  }, [data])

  return (

    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      showSelectCheckbox={false}
    />
  )
}
