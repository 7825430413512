import React, { useState } from 'react';
import {
  Badge, BadgeProps, Button, Collapse, Stack, styled, Tooltip,
} from '@mui/material';
import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import { GroupsManagementRequest } from 'services/interfaces';
import GroupsTable from './GroupsTable/GroupsTable';
import GroupsFilterForm from './GroupsFilterForm';

export const INITIAL_FILTERS: GroupsManagementRequest = {
  groupId: null,
  name: null,
  PageNumber: 1,
  PageSize: 10,
  SortingBy: 'groupId',
}

export default function ManageGroups() {
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState<boolean>();
  const [filters, setFilters] = useState<GroupsManagementRequest>(INITIAL_FILTERS);

  const handleToggleFilters = () => {
    setShowFilters((prev) => !prev);
  };
  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -10,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const filtersApplyed = (filters: GroupsManagementRequest): number => Object.entries(filters)
    .filter(([key, value]) => value !== null && value !== 0 && !['PageNumber', 'PageSize', 'SortingBy'].includes(key))
    .length;

  return (
    <ViewFormLayout pl={0} pr={0} testId="manage-groups-table">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.NEW_GROUP}>
          <Button onClick={() => navigate('/admin/groups/new')} variant="contained">{BUTTON_TEXT.NEW_GROUP}</Button>
        </Tooltip>
        <Button
          variant="contained"
          onClick={handleToggleFilters}
        >
          <StyledBadge badgeContent={filtersApplyed(filters)} color="secondary">
            {showFilters ? 'Hide Filters' : 'Filters'}
          </StyledBadge>
        </Button>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <Stack p={1}>
          <Collapse in={showFilters}>
            <GroupsFilterForm
              onSearch={(data: GroupsManagementRequest) => {
                setFilters(data)
                handleToggleFilters()
              }}
              onReset={() => {
                setFilters(INITIAL_FILTERS);
                handleToggleFilters()
              }}
            />
          </Collapse>
        </Stack>

        <GroupsTable filters={filters} />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
