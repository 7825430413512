import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import AdminUsersList from 'modules/administrator/AdminUsersList';

function AdminUsersPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Users'))
  }, []);

  return (
    <Layout>
      <AdminUsersList />
    </Layout>
  );
}

export default AdminUsersPage;
