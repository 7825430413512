import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import Layout from 'components/Layout';
import ChangePassword from 'modules/UserProfile/ChangePassword';
import '../App.scss';
import { Stack } from '@mui/material';

export default function ManagePassword() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Change Password'))
  }, []);

  return (
    <Layout>
      <Stack width="100%" sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}>
        <ChangePassword />
      </Stack>
    </Layout>
  );
}
