/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, {
  useState, MouseEvent, ChangeEvent, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import { useTheme } from '@mui/material/styles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
  TablePaginationActionsProps, ICustomPaginationActionsTable, TableSortDirection, ITableHeader, TColumns, TRows,
} from 'components/RemoteCustomTable/interface';
import { Typography } from '@mui/material';

const ROW_HEIGHT = '41px';

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const {
    count, page, rowsPerPage, onPageChange,
  } = props;

  const handleFirstPageButtonClick = (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function CollapseRow({
  row,
  columns,
  collapseAllRow,
  showAllColumns,
  handleRowClick,
  isSelected,
  showSelectCheckbox,
  colSpanGroupTitle,
}:
{ row: TRows,
  columns: TColumns[],
  collapseAllRow: boolean,
  showAllColumns: boolean,
  handleRowClick: Function,
  isSelected: Function,
  showSelectCheckbox: boolean,
  colSpanGroupTitle: number}) {
  const [open, setOpen] = useState(!collapseAllRow);
  const { primaryColor } = useSelector((state: IState) => state.userProfile);

  return (
    <>
      <TableRow
        sx={{ height: ROW_HEIGHT, backgroundColor: `${primaryColor}50` }}
      >
        <TableCell
          style={{ padding: 0 }}
          colSpan={2}
        >
          {showAllColumns && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                p: 0,
              }}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              {row.courseTypeIcon}
            </Box>
          )}
        </TableCell>
        {showAllColumns && (
        <>
          <TableCell
            colSpan={colSpanGroupTitle}
            style={{ padding: 0 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{row?.courseName}</Typography>
          </TableCell>
          <TableCell
            colSpan={2}
            style={{ padding: 0 }}
          >
            {row.moreInfoIcon}
          </TableCell>
        </>
        )}
      </TableRow>
      {showAllColumns && open && (React.Children.toArray(row?.details?.map((_detailRow: TRows) => {
        const isItemSelected = isSelected(_detailRow.id);
        return (
          <TableRow
            sx={{ backgroundColor: `${primaryColor}20`, height: ROW_HEIGHT }}
            onClick={(event) => handleRowClick(event, _detailRow?.id)}
          >
            {showSelectCheckbox && (
            <TableCell sx={{ padding: '4px' }}>

              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  'aria-label': `checkBox-${row?.id}`,
                }}
              />

            </TableCell>
            )}
            <TableCell
              sx={{ width: '20px', backgroundColor: (theme) => theme.palette.common.white, border: 'none' }}
            />
            {React.Children.toArray(columns?.map((column: TColumns) => {
              const padding: number | string = 0;

              return (
                !column?.hidden && (
                <TableCell
                  sx={{
                    padding,
                    maxWidth: column?.width,
                    textAlign: column?.align || 'left',
                  }}
                >
                  {_detailRow[column?.column ?? 'id']}
                </TableCell>
                )
              )
            }))}
          </TableRow>
        )
      }))
      )}
    </>
  )
}
function DefaultRow({
  row, columns, handleRowClick, isSelected, showSelectCheckbox,
}: { row: TRows, columns: TColumns[], handleRowClick: Function, isSelected: Function, showSelectCheckbox:boolean }) {
  const isItemSelected = isSelected(row.id);
  return (
    <TableRow
      sx={{ height: ROW_HEIGHT }}
      onClick={(event) => handleRowClick(event, row.id)}
    >
      {showSelectCheckbox && (
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
        />
      </TableCell>
      )}
      <TableCell
        key="default-row-arrow"
        sx={{ width: '20px' }}
      />
      {React.Children.toArray(columns?.map((column: TColumns) => (
        !column?.hidden && (
          <TableCell
            sx={{
              p: column?.padding || 0,
              maxWidth: column?.width,
              textAlign: column?.align || 'left',
            }}
          >
            {row[column?.column ?? 'id']}
          </TableCell>
        )
      )))}
    </TableRow>
  )
}
function Row({
  row,
  columns,
  collapseAllRow,
  showAllColumns,
  handleRowClick,
  isSelected,
  showSelectCheckbox,
  colSpanGroupTitle,
}: {
  row: TRows,
  columns: TColumns[],
  collapseAllRow: boolean,
  showAllColumns: boolean,
  handleRowClick: Function,
  isSelected: Function,
  showSelectCheckbox: boolean,
  colSpanGroupTitle: number,
}) {
  if (row?.isCollapsable) {
    return (
      <CollapseRow
        row={row}
        columns={columns}
        collapseAllRow={collapseAllRow}
        colSpanGroupTitle={colSpanGroupTitle}
        showAllColumns={showAllColumns}
        handleRowClick={handleRowClick}
        isSelected={isSelected}
        showSelectCheckbox={showSelectCheckbox}
      />
    )
  }
  return (
    <DefaultRow
      row={row}
      columns={columns}
      handleRowClick={handleRowClick}
      isSelected={isSelected}
      showSelectCheckbox={showSelectCheckbox}
    />
  )
}

function TableHeader({
  column,
  sort,
  sortDirection,
  handleDescendingSort,
  handleAscendingSort,
}: ITableHeader) {
  const getSorting = () => {
    const columnName = column?.column.replace('Icon', '');
    const renderAsc = columnName === sort && TableSortDirection.ASCENDING === sortDirection
    return renderAsc ? (
      <ArrowDownwardIcon
        sx={{ cursor: 'pointer' }}
        color="primary"
        fontSize="small"
        name={column?.column}
        onClick={(e) => handleDescendingSort(e?.currentTarget.getAttribute('name') || '')}
      />
    ) : (
      <ArrowUpwardIcon
        sx={{ cursor: 'pointer' }}
        color="primary"
        fontSize="small"
        name={column?.column}
        onClick={(e) => handleAscendingSort(e?.currentTarget.getAttribute('name') || '')}
      />
    )
  }
  return (
    <TableCell
      padding="none"
      size="medium"
      variant="head"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: column?.alignTitle || 'left',
          p: 0,
        }}
      >
        {column?.name}
        {column?.sortable && getSorting()}
      </Box>
    </TableCell>
  )
}

export default function RemoteCustomTable(
  {
    columns,
    onHandleSelectCheckbox,
    rows,
    colSpanGroupTitle = 4,
    collapseAllRow = true,
    showSelectCheckbox = false,
    isSingleSelection = false,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRecords,
    sortDirection,
    setSortDirection,
    sort,
    setSort,
  }: ICustomPaginationActionsTable,
) {
  const [columnsList] = useState<TColumns[]>(columns);
  const [selected, setSelected] = React.useState<readonly number[]>([]);

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAscendingSort = (key: string) => {
    setSort(key.replace('Icon', ''));
    setSortDirection(TableSortDirection.ASCENDING);
    setPage(0);
  };

  const handleDescendingSort = (key: string) => {
    setSort(key.replace('Icon', ''));
    setSortDirection(TableSortDirection.DESCENDING);
    setPage(0);
  };

  const showAllColumns = Boolean(columnsList.some((column) => !column.hidden));
  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleRowClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    if (isSingleSelection) {
      setSelected([id]);
    } else {
      let newSelected: readonly number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((item: TRows) => {
        const ids = [item?.id];

        if (item.details && Array.isArray(item.details)) {
          item?.details?.forEach((det: TRows) => {
            ids.push(det?.id)
          });
        }

        return ids;
      }).flat()
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {

  }, [rows]);

  useEffect(() => {
    if (onHandleSelectCheckbox) {
      const filteredData = rows.filter((item) => selected.includes(item.id));
      onHandleSelectCheckbox(filteredData);
    }
  }, [selected]);

  return (
    <TableContainer data-testid="custom-pagination-table">
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow sx={{ height: '53px' }}>
            {showSelectCheckbox && (
            <TableCell padding="checkbox">
              <Checkbox
                disabled={isSingleSelection}
                color="primary"
                indeterminate={selected.length > 0 && selected.length < totalRecords}
                checked={totalRecords > 0 && selected.length === totalRecords}
                onChange={(event) => handleSelectAllClick(event)}
                inputProps={{
                  'aria-label': 'select all',
                }}
              />

            </TableCell>
            )}
            <TableCell key="table-header-row-arrow" sx={{ width: '20px' }} />
            {React.Children.toArray(columnsList?.map((column) => (
              !column?.hidden && (
                <TableHeader
                  column={column}
                  sort={sort}
                  sortDirection={sortDirection}
                  handleDescendingSort={handleDescendingSort}
                  handleAscendingSort={handleAscendingSort}
                />
              )
            )))}
          </TableRow>
        </TableHead>
        <TableBody>
          {React.Children.toArray(rows?.map((row) => (
            <Row
              row={row}
              colSpanGroupTitle={colSpanGroupTitle}
              columns={columnsList}
              collapseAllRow={collapseAllRow}
              showAllColumns={showAllColumns}
              handleRowClick={handleRowClick}
              isSelected={isSelected}
              showSelectCheckbox={showSelectCheckbox}
            />
          )))}
          {rows?.length === 0 && (
            <TableRow style={{ height: ROW_HEIGHT }} data-testid="empty-rows">
              <TableCell colSpan={(columnsList?.length || 0) + 2} align="center"> No records </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            colSpan={(columnsList?.length || 0) + 2}
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
