import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import FormView, { IFormItem } from 'components/FormView';
import ViewFormLayout from 'components/ViewFormLayout';
import {
  Alert, Button, Stack, Tooltip,
} from '@mui/material';
import { useChangePasswordMutation } from 'services/dataApi';
import { IState } from 'redux/interfaces';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';

type Passwords = {
  NewPassword: string;
  ConfirmPassword: string;
}

export default function ChangePassword() {
  const [passwords, setPasswords] = useState<Passwords>({
    NewPassword: '',
    ConfirmPassword: '',
  });
  const [errorPassword, setErrorPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [id, setId] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useSelector((state: IState) => state.userProfile);
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const changePasswordForm: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'password',
      options: undefined,
      fieldValue: '',
      fieldId: 'NewPassword',
      label: 'New Password',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'password',
      options: undefined,
      fieldValue: '',
      fieldId: 'ConfirmPassword',
      label: 'Confirm Password',
      titleSize: 3,
    },
  ];

  const handleChangePassword = async () => {
    try {
      await changePassword({
        userId,
        newPassword: passwords.NewPassword,
      }).unwrap();
      enqueueSnackbar('Your password was changed successfully.', { variant: 'success' });
      setPasswords({
        NewPassword: '',
        ConfirmPassword: '',
      })
      setId((v) => v + 1)
    } catch (error) {
      enqueueSnackbar('Something went wrong. Could you try it again?', { variant: 'error' });
      console.error(error)
    }
  }

  const handleSetPassword = (obj: {id: string, value: any}) => {
    setPasswords({
      ...passwords,
      [obj.id]: obj.value,
    })
  }

  const handleCheckPasswords = () => {
    if (passwords.NewPassword !== passwords.ConfirmPassword) {
      setErrorPassword(true)
      setIsValidPassword(false)
    } else {
      setIsValidPassword(true)
      setErrorPassword(false)
    }
  }

  useEffect(() => {
    if (passwords.NewPassword !== '' && passwords.ConfirmPassword !== '') {
      handleCheckPasswords()
    }
  }, [passwords])

  return (
    <ViewFormLayout testId="reset-password">
      <ViewFormLayout.ActionBar>
        <Tooltip
          title={isLoading ? ACTION_ITEM_TOOLTIP_TEXT.SAVING : ACTION_ITEM_TOOLTIP_TEXT.SAVE}
        >
          <Button disabled={isLoading || !isValidPassword} onClick={handleChangePassword} variant="contained">{isLoading ? BUTTON_TEXT.SAVING : BUTTON_TEXT.SAVE}</Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        {errorPassword ? <Alert severity="warning">New password and repeat password do not match</Alert> : null}
        <Stack p={0.3}>
          <FormView
            key={id}
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={changePasswordForm}
            onChangeValues={(obj: {id: string, value: any}) => handleSetPassword(obj)}
          />
        </Stack>
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
