/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import Layout from 'components/Layout';
import '../App.scss'
import {
  Grid, Tabs, Tab, AppBar, Box, Stack,
} from '@mui/material';
import AvailableDevelopmentPlan from 'modules/UserProfile/AvailableDevelopmentPlan';
import AvailableEvaluations from 'modules/UserProfile/AvailableEvaluations';
import CurrentSkills from 'modules/UserProfile/CurrentSkills';
import DevelopmentPlan from 'modules/UserProfile/DevelopmentPlan';
import FutureJobPositon from 'modules/UserProfile/FutureJobPosition';
import Groups from 'modules/UserProfile/Groups';
import GroupsIcon from '@mui/icons-material/Groups';
import JobPositionRequirements from 'modules/UserProfile/JobPositionRequirements';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AboutMe from 'modules/UserProfile/AboutMe';
import { PROFILE_TEXT } from 'utils/constants';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function Profile() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('My Profile'));
  }, []);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Grid container spacing={1}>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <AppBar position="static" sx={{ bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} variant="fullWidth">
              <Tab
                icon={<PersonOutlineIcon />}
                iconPosition="start"
                label={PROFILE_TEXT.ABOUT_ME}
              />
              <Tab
                icon={<GroupsIcon />}
                iconPosition="start"
                label={PROFILE_TEXT.MY_GROUPS}
              />
            </Tabs>
          </AppBar>
          <CustomTabPanel value={value} index={0}>
            <AboutMe />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Groups />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {/* removed for MVP */}
            <AvailableEvaluations />
            <AvailableDevelopmentPlan />
            <CurrentSkills />
            <JobPositionRequirements />
            <FutureJobPositon />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            {/* removed for MVP */}
            <Stack display="flex" flexDirection="column" gap={4}>
              <DevelopmentPlan />
            </Stack>
          </CustomTabPanel>
        </Box>
      </Grid>
    </Layout>
  );
}
