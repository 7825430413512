/* eslint-disable max-len */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ITestQuestionResponse } from 'utils/interfaces';
import { addOrReplace, addOrReplaceByName } from 'utils/helpers';
import { TestQuestion } from 'services/interfaces';
import CheckBoxQuestion from './CheckBoxQuestion';
import RadioButtonQuestion from './RadioButtonQuestion';
import TextAreaQuestion from './TextAreaQuestion';
import TextBoxQuestion from './TexBoxQuestion';

function generateQuestion(question: TestQuestion, _setTestResponse: Function) {
  if (question.testQuestionType === 'textArea' || question.forceComments) {
    return <TextAreaQuestion question={question} handleResponse={(obj: ITestQuestionResponse) => { _setTestResponse((_test: any) => addOrReplace(_test, obj)); }} />
  }
  if (question.testQuestionType === 'radioButton') {
    return (
      <div>
        <RadioButtonQuestion question={question} handleResponse={(obj: ITestQuestionResponse) => { _setTestResponse((_test: any) => addOrReplace(_test, obj)); }} />
      </div>
    )
  }
  if (question.testQuestionType === 'checkbox') {
    return (
      <CheckBoxQuestion
        question={question}
        handleResponse={(obj: ITestQuestionResponse) => {
          _setTestResponse((_test: any) => {
            const checkQ = _test.find((q: ITestQuestionResponse) => q.id === String(question?.testQuestionId));
            const newQ = { id: String(question?.testQuestionId), value: addOrReplaceByName(checkQ?.value || [], obj) };
            return addOrReplace(_test, newQ);
          });
        }}

      />
    )
  }
  if (question.testQuestionType === 'textBox') {
    return <TextBoxQuestion question={question} handleResponse={(obj: ITestQuestionResponse) => { _setTestResponse((_test: any) => addOrReplace(_test, obj)); }} />
  }
  return (
    <RadioButtonQuestion question={question} handleResponse={(obj: ITestQuestionResponse) => { _setTestResponse((_test: any) => addOrReplace(_test, obj)); }} />
  )
}

export default function QuestionList({ setTestResponse, questions }: {setTestResponse: Function, questions: TestQuestion[]|undefined}) {
  const steps = questions ? questions.map((ques) => generateQuestion(ques, setTestResponse)) : [];

  if (steps.length === 0) {
    return (
      <Box sx={{
        flexGrow: 1, justifyContent: 'center', p: 2, m: 2, alignItems: 'center',
      }}
      >
        <Typography> Please create a test for this course</Typography>
      </Box>
    )
  }
  return (
    <Box sx={{
      flexGrow: 1, justifyContent: 'center', p: 2, border: '1px solid gray', m: 2, alignItems: 'center',
    }}
    >
      {steps?.map((ques: any) => ques)}
    </Box>
  );
}
