import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import GroupUsersTable from './GroupUsersTable/GroupUsersTable';

export default function GroupUsers() {
  const navigate = useNavigate();

  const handleNewUser = () => {
    navigate('/admin/groups');
  };

  return (
    <ViewFormLayout pl={0} pr={0} testId="users-list">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
          <Button variant="text" onClick={handleNewUser}>
            {BUTTON_TEXT.BACK}
          </Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <GroupUsersTable />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
