import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import NewGroup from 'modules/administrator/NewGroup';

function NewGroupPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setNavigationPage('New Group'));
  }, []);
  return (
    <Layout>
      <NewGroup />
    </Layout>
  );
}
export default NewGroupPage;
