/* eslint-disable max-len */
import React from 'react';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import {
  Button, Stack, Tooltip, Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from 'components/Layout';
import ViewFormLayout from 'components/ViewFormLayout';
import UserTranscript from 'modules/UserTranscript';

function MyTranscriptReportPage() {
  const { userId } = useParams();
  const navigate = useNavigate();

  if (!userId) {
    <Layout>
      <Typography>Please, select an user!</Typography>
    </Layout>
  }
  return (
    <Layout>
      <ViewFormLayout testId="user-dashboard-certifications">
        <ViewFormLayout.ActionBar>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => navigate(-1)}>{BUTTON_TEXT.BACK}</Button>
          </Tooltip>
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Body>
          <Stack p={2}>
            <UserTranscript userId={userId as string} />
          </Stack>
        </ViewFormLayout.Body>
      </ViewFormLayout>

    </Layout>
  );
}

export default MyTranscriptReportPage;
