import React from 'react';
import { TColumns, TRows } from 'components/RemoteCustomTable/interface';
import LoadingBox from 'components/LoadingBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ACTION_ITEM_TOOLTIP_TEXT, ACTIVE_STATUS } from 'utils/constants';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useGetUserManagementMutation } from 'services/dataApi';
import { UserManagement, UserManagementRequest } from 'services/interfaces';
import { useNavigate } from 'react-router-dom';
import RemoteCustomTable from 'components/RemoteCustomTable';
import useRemoteTable from 'components/RemoteCustomTable/useRemoteTable';

interface UserTableProps {
  filters: UserManagementRequest;
}
export default function UsersTable({ filters }: UserTableProps) {
  const navigate = useNavigate();
  const [getUserByFilter] = useGetUserManagementMutation();

  const {
    dataSet,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sort,
    setSort,
    sortDirection,
    setSortDirection,
    isLoading,
  } = useRemoteTable<UserManagement, UserManagementRequest>({
    fetchData: (request) => getUserByFilter(request).unwrap(),
    filters,
    sortDefault: 'userId',
  });

  function getActionButtons(userId: string | number) {
    return (
      <Box display="flex">
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.VIEW_USER}>
          <IconButton onClick={() => navigate(`/admin/user/profile/${userId}`)} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <VisibilityIcon sx={{ color: (theme) => theme.palette.primary.main }} />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  function createData(items: UserManagement[]): TRows[] {
    return items?.map((item: UserManagement) => ({
      ...item,
      details: [],
      id: Number(item.userId),
      active: item.active ? ACTIVE_STATUS.YES : ACTIVE_STATUS.NO,
      isCollapsable: false,
      actions: getActionButtons(item?.userId),
    }));
  }

  const columns: TColumns[] = [
    {
      name: 'Id',
      column: 'id',
      hidden: true,
      width: 200,
      sortable: true,
    },
    {
      name: 'User ID',
      column: 'userId',
      width: 200,
      sortable: true,
    },
    {
      name: 'First Name',
      column: 'firstName',
      width: 120,
      sortable: true,
    },
    {
      name: 'Last Name',
      column: 'lastName',
      width: 120,
      sortable: true,
    },
    {
      name: 'User Name',
      column: 'userName',
      width: 120,
      sortable: true,
    },
    {
      name: 'Employee ID',
      column: 'employeeId',
      width: 120,
      sortable: true,
    },
    {
      name: 'Active',
      column: 'active',
      width: 120,
      sortable: true,
    },
    {
      name: 'Actions',
      column: 'actions',
      width: 120,
      sortable: false,
    },
  ];
  if (isLoading) {
    return <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}><LoadingBox /></Box>
  }
  return (
    <RemoteCustomTable
      rows={createData(dataSet?.data || [])}
      columns={columns}
      totalRecords={dataSet?.totalRows || 0}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      sort={sort}
      setSort={setSort}
    />

  )
}
