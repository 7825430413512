/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect, useRef, ChangeEvent,
} from 'react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import {
  Autocomplete,
  Checkbox,
  Grid,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

export interface Option {
    label: string;
    value: string;
  }

  interface MultiSelectAllProps {
    items: Option[];
    selectAllLabel: string;
    onChange: Function;
    value: Option[];
  }
function MultiSelectAll({
  items,
  selectAllLabel,
  onChange,
  value,
}: MultiSelectAllProps) {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(value);
  const [filteredOptions, setFilteredOptions] = useState<Option[] | null>(null);
  const multiSelectRef = useRef(null);
  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);
  const handleToggleOption = (_selectedOptions: Option[]) => setSelectedOptions(_selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const getOptionLabel = (option: Option) => `${option.label}`;
  const allItemsSelected = (): boolean => {
    if (filteredOptions?.length !== items.length) {
      const excludedFilteredOptions = filteredOptions?.filter(
        (opt) => !selectedOptions.find((selOpt) => selOpt.label === opt.label),
      );
      return !excludedFilteredOptions || excludedFilteredOptions.length === 0;
    }
    return items.length > 0 && items.length === selectedOptions.length;
  };
  const clearSelected = (selOptions: Option[]) => {
    if (selOptions.length > 0) {
      setSelectedOptions((prevSelected) => prevSelected.filter(
        (item) => !selOptions.find((selOption) => selOption.label === item.label),
      ));
    } else {
      setSelectedOptions([]);
    }
  };
  const handleSelectAll = (isSelected: boolean) => {
    let selectedList: Option[] = [];
    if (filteredOptions && filteredOptions?.length > 0 && filteredOptions.length !== items.length) {
      selectedList = items.filter((item) => filteredOptions.find(
        (filteredOption) => filteredOption.label === item.label,
      ));
    }
    if (isSelected) {
      if (selectedList.length > 0) {
        setSelectedOptions([...selectedOptions, ...selectedList]);
      } else {
        setSelectedOptions(items);
      }
    } else {
      clearSelected(selectedList);
    }
  };
  const handleToggleSelectAll = () => {
    handleSelectAll(!allItemsSelected());
  };
  const handleChange = (
    event: ChangeEvent<{}>,
    _selectedOptions: Option[],
    reason: string,
  ) => {
    let result: Option[] | null = null;
    if (reason === 'clear') {
      handleClearOptions();
    } else if (reason === 'selectOption' || reason === 'removeOption') {
      if (_selectedOptions.find((option) => option.value === 'select-all')) {
        handleToggleSelectAll();
        result = items.filter((el) => el.value !== 'select-all');
      } else {
        handleToggleOption(_selectedOptions);
        result = _selectedOptions;
      }
    }
    if (result !== null) {
      onChange(result);
    }
  };
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, option: Option) => {
    if (option.value === 'select-all') {
      handleToggleSelectAll();
    } else if (e.target.checked) {
      const result = [...selectedOptions, option];
      setSelectedOptions(result);
    } else {
      const result = selectedOptions.filter(
        (selOption) => selOption.value !== option.value,
      );
      setSelectedOptions(result);
    }
  };
  const optionRenderer = (
    props: {},
    option: Option,
    { selected }: { selected: boolean },
  ) => {
    const selectAllProps = option.value === 'select-all' ? { checked: allItemsSelected() } : {};
    return (
      <Grid container key={option.label}>
        <Grid item xs={12} sx={{ pl: 1, pr: 1 }}>
          <FormControlLabel
            control={(
              <Checkbox
                key={option.label}
                checked={selected}
                onChange={(e) => handleCheckboxChange(e, option)}
                {...selectAllProps}
                sx={{ mr: 1 }}
              />
              )}
            label={getOptionLabel(option)}
            key={option.label}
          />
        </Grid>
      </Grid>
    );
  };
  const debouncedStateValue = debounce((newVal: any) => {
    if (newVal && !isEqual(newVal, filteredOptions)) {
      setFilteredOptions(newVal);
    }
  }, 1000);
  const updateFilteredOptions = (filtered: Option[]) => {
    debouncedStateValue(filtered);
  };
  const inputRenderer = (params: any) => <TextField {...params} />;
  const filter = createFilterOptions();
  return (
    <Autocomplete
      ref={multiSelectRef}
      sx={{
        width: '100%',
        overflowY: 'scroll',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderBottom: '2px solid black',
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
        },
      }}
      multiple
      size="small"
      options={items}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, val) => option.value === val.value}
      filterOptions={(options, params: any) => {
        const filtered = filter(options, params);
        updateFilteredOptions(filtered as Option[]);
        return [{ label: selectAllLabel, value: 'select-all' }, ...filtered] as Option[];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
}
export default MultiSelectAll;
