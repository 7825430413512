/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  COURSE_TYPE_ID, ACTION_ITEM_TOOLTIP_TEXT, COURSE_TYPES_ENUM, COURSE_STATUS_BY_DATE,
} from 'utils/constants';
import { categorizeByDueDate, formatDate, getRequirmentLabel } from 'utils/helpers';
import { GroupCoursesDetails } from 'services/interfaces';
import { LearningObject } from 'modules/SearchLearningObject/shared';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CustomTable from 'components/CustomTable';
import DialogModal from 'components/DialogModal';
import getType from 'components/TypeIcon';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PreviewLaunch from 'modules/PreviewLaunch';
import Tooltip from '@mui/material/Tooltip';
import getStatusIconByDueDate from '../StatusIconLabel';

type PreviewLaunchType = Pick<LearningObject, 'wbtURL' | 'learningObjectName'>;

export default function MyCertification({ dataCourses = [] } : {dataCourses?: GroupCoursesDetails[]}) {
  const navigate = useNavigate();
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [previewSelected] = useState<PreviewLaunchType>();
  const [dataSet, setDataSet] = useState<TRows[] | []>([]);

  function getMoreInfoButton(learningInstanceId: number, url: string) {
    return (
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.MORE_INFO}>
        <IconButton onClick={() => navigate(`${url}/${learningInstanceId}`)}>
          <InfoIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    )
  }

  function getActions(learningInstanceId: number, learningTypeId: number): React.ReactNode {
    if (learningTypeId === COURSE_TYPES_ENUM.ONLINE) {
      return (
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.COURSE_ACTIONS}>
          <Button variant="contained" onClick={() => navigate(`/dashboard/course/launch/${learningInstanceId}`)}>LAUNCH</Button>
        </Tooltip>
      )
    }
    return null;
  }

  function createBasicData(item: GroupCoursesDetails): TRows {
    const statusByDueDate = categorizeByDueDate(item.dueDate) as COURSE_STATUS_BY_DATE;
    const dueDateFormatted = item.dueDate ? formatDate(String(item.dueDate)) : '';
    return {
      id: item?.learningInstanceId,
      courseTypeIcon: getType({ type: item.learningTypeId }),
      courseType: COURSE_TYPE_ID[item.learningTypeId],
      courseName: item.learningName,
      enrollmentType: item.learningProcessStatusTypeName,
      processStatusType: item.learningProcessStatusTypeName,
      status: item.learningProcessStatusTypeName,
      dueDate: dueDateFormatted,
      dueDateStatus: statusByDueDate,
      dueDateStatusIcon: getStatusIconByDueDate(statusByDueDate, dueDateFormatted),
      startDate: item.startDate ? formatDate(String(item.startDate)) : '',
      moreInfo: getMoreInfoButton(item?.learningInstanceId, '/dashboard/course/detail'),
      actions: getActions(item?.learningInstanceId, item.learningTypeId),
      isCollapsable: item.isCollapsable,
      details: [],
      requirement: getRequirmentLabel(item?.dueDate),
    };
  }

  useEffect(() => {
    if (dataCourses?.length > 0) {
      const tableDataByType: GroupCoursesDetails[] = dataCourses;

      const tableData = tableDataByType.length > 0
        ? tableDataByType?.map((item: GroupCoursesDetails) => createBasicData(item)) : [];
      setDataSet(tableData)
    }
  }, [dataCourses])

  const columns: TColumns[] = [
    {
      name: 'Course Type',
      column: 'courseTypeIcon',
      width: 10,
      alignTitle: 'left',
      sortable: false,
      hidden: false,
      searchable: true,
      filter: true,
      filterLabel: 'Course Type',
    },
    {
      name: 'Course Name',
      column: 'courseName',
      width: 300,
      alignTitle: 'left',
      sortable: true,
      hidden: false,
      searchable: true,
    },
    {
      name: 'Requirement',
      column: 'requirement',
      width: 100,
      align: 'center',
      alignTitle: 'center',
      sortable: false,
      hidden: false,
      filter: true,
      filterLabel: 'Requirement',
    },
    {
      name: 'Status',
      column: 'status',
      width: 100,
      align: 'left',
      alignTitle: 'left',
      sortable: false,
      hidden: false,
      filter: true,
      filterLabel: 'Status',
    },
    {
      name: 'Start Date',
      column: 'startDate',
      width: 100,
      align: 'left',
      alignTitle: 'left',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Due Date',
      column: 'dueDateStatusIcon',
      width: 100,
      align: 'left',
      alignTitle: 'left',
      sortable: true,
      hidden: false,
    },

    {
      name: 'More Info',
      column: 'moreInfo',
      width: 50,
      alignTitle: 'left',
      sortable: false,
      hidden: false,
    },
    {
      name: 'Actions',
      column: 'actions',
      width: 100,
      alignTitle: 'left',
      sortable: false,
      hidden: false,
    },
  ];

  return (
    <>
      <CustomTable
        collapseAllRow={false}
        columns={columns}
        defaultSort="dueDate"
        rows={dataSet}
        showColumnsControl={false}
        showSearchBar
      />
      <DialogModal open={openPreview} onClose={() => setOpenPreview(false)}>
        <DialogModal.Title>
          Course Launcher
        </DialogModal.Title>
        <DialogModal.Content>
          <PreviewLaunch previewSelected={previewSelected as PreviewLaunchType} />
        </DialogModal.Content>
      </DialogModal>
    </>
  )
}
