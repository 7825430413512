import React, { useState } from 'react';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import { IconButton, Tooltip } from '@mui/material';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { useNavigate } from 'react-router-dom';
import CustomTable from 'components/CustomTable';
import EditIcon from '@mui/icons-material/Edit';
// eslint-disable-next-line import/extensions
import scormCourses from '../scormCourses.json'

function getViewButton(id: string | number, _navigate: Function) {
  return (
    <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.EDIT_SCORM_COURSE}>
      <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => _navigate(`detail/${id}`)}>
        <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
      </IconButton>
    </Tooltip>
  )
}

function createData(items: any[], _navigate: Function): TRows[] {
  return items?.map((item: any) => ({
    ...item,
    details: [],
    isCollapsable: false,
    courseName: '',
    isWBT: item?.isWBT ? 'Yes' : 'No',
    isTest: item?.isTest ? 'Yes' : 'No',
    inactive: item?.inactive ? 'Yes' : 'No',
    action: getViewButton(item?.id, _navigate),
  }));
}

export default function ScormCoursesTable() {
  const navigate = useNavigate();
  const [dataSet] = useState<TRows[] | []>(createData(scormCourses, navigate));

  const columns: TColumns[] = [
    {
      name: 'ID',
      column: 'id',
      width: 100,
      sortable: true,
    },
    {
      name: 'Title',
      column: 'title',
      width: 200,
      sortable: true,
    },
    {
      name: 'Folder',
      column: 'folder',
      width: 250,
      sortable: true,
    },
    {
      name: 'Company Name',
      column: 'companyName',
      width: 150,
      sortable: true,
    },
    {
      name: 'Usage',
      column: 'usage',
      width: 70,
      sortable: true,
    },
    {
      name: 'Course Usage',
      column: 'courseUsage',
      width: 70,
      align: 'center',
      alignTitle: 'center',
      sortable: true,
    },
    {
      name: 'Test Usage',
      column: 'testUsage',
      width: 70,
      align: 'center',
      alignTitle: 'center',
      sortable: true,
    },
    {
      name: 'Is WBT',
      column: 'isWBT',
      align: 'center',
      alignTitle: 'center',
      width: 70,
      sortable: false,
    },
    {
      name: 'Is Test',
      column: 'isTest',
      align: 'center',
      alignTitle: 'center',
      width: 70,
      sortable: false,
    },
    {
      name: 'Inactive',
      column: 'inactive',
      align: 'center',
      alignTitle: 'center',
      width: 70,
      sortable: false,
    },
    {
      name: 'Action',
      column: 'action',
      width: 70,
      sortable: false,
    },
  ];

  return (
    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      defaultSort="id"
    />
  )
}
