import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import { IState } from 'redux/interfaces';
import { useValidateTokenQuery } from 'services/tokenApi';
import { CHECK_TOKEN_EVERY_TIME } from 'utils/constants';

function ProtectedRoutes() {
  // here we have to define a function to check if the user is logged or not

  const { authenticated, webApiClientId } = useSelector((state: IState) => state?.userProfile);

  useValidateTokenQuery(undefined, {
    pollingInterval: CHECK_TOKEN_EVERY_TIME, skip: !authenticated,
  })

  if (!authenticated) {
    return <Navigate to={`/${webApiClientId}/login`} />;
  }

  return <Outlet />;
}

export default ProtectedRoutes;
