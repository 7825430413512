import React from 'react';
import {
  Container, Box, Typography,
} from '@mui/material';
import BackgroundImage from '../images/background_image.jpg';

export default function NoPageError() {
  return (
    <Container
      maxWidth={false}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="left"
        width={650}
      >
        <Typography variant="h5" gutterBottom>
          Oops! Looks like something went wrong, please try again later.
        </Typography>
        <Typography variant="subtitle1">
          Contact your administrator or your company&apos;s support team.
        </Typography>
      </Box>
    </Container>
  );
}
