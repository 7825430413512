import { YELLLOWFIN_COOKIE_NAME, YELLLOWFIN_COOKIE_DOMAIN } from 'utils/constants';

export default function handleUnauthorizedResponse(): boolean {
  const webApiClientId = localStorage.getItem('webApiClientId') || '';
  let rediretUrl = `/${webApiClientId}/login`;
  if (window.location.href.includes('sso')) {
    rediretUrl = '/error-login';
  }

  window.location.href = rediretUrl;
  localStorage.removeItem('accessToken');
  localStorage.removeItem('authenticated');
  localStorage.removeItem('userId');
  localStorage.removeItem('companyBackgroundImageURL');
  document.cookie = `${YELLLOWFIN_COOKIE_NAME}=; domain=${YELLLOWFIN_COOKIE_DOMAIN}; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  return false
}
