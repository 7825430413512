/* eslint-disable react/button-has-type */
import React from 'react';
import Box from '@mui/material/Box';
import Calendar from 'react-calendar';
import './CalendarWidget.scss';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { useDispatch } from 'react-redux';
import { filterDate } from 'redux/userDashboardSlice';

export default function CalendarWidget() {
  const dispatch = useDispatch();

  const handleOnChange = (date: Value) => {
    const date1 = date?.toLocaleString();
    const date2 = new Date(date1 as string);

    const month = (date2.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, add 1
    const day = date2.getUTCDate().toString().padStart(2, '0');
    const year = date2.getUTCFullYear();

    const filterDateFormat = `${month}/${day}/${year}`;
    dispatch(filterDate(filterDateFormat));
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <Calendar value={new Date()} onChange={handleOnChange} />
    </Box>
  );
}
