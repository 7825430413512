import React from 'react';
import { Box, RadioGroup } from '@mui/material';
import Option from './Option';

interface IOptionsProps{
  options: string[];
}

export default function Options({ options }: IOptionsProps) {
  return (
    <RadioGroup
      row
      aria-labelledby="question"
      name="options"
      defaultValue=""
    >
      <Box display="flex" justifyContent="space-around" width="100%">
        {
          React.Children.toArray(
            options.map((option) => <Option option={option} />),
          )
        }
      </Box>
    </RadioGroup>
  )
}
