import React from 'react';
import {
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
} from '@mui/material';
import {
  Menu as MenuIcon,
  HelpOutline as HelpIcon,
} from '@mui/icons-material';
import { IState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import TopDropDownMenu from './components/TopDropDownMenu';
import CompanyFavicon from '../../../images/company-favicon.png';

interface ITopMenu {
  open: boolean,
  toggleDrawer: () => void
}
function TopMenu({ open, toggleDrawer }: ITopMenu) {
  const userNavigationPage = useSelector(
    (state: IState) => state.userProfile.userNavigationPage,
  );

  const companyIcon = useSelector((state: IState) => state.userProfile.companyIcon)

  const DRAWERWIDTH = 240;
  const AppBarStyle = open ? ({
    marginLeft: DRAWERWIDTH,
    width: `calc(100% - ${DRAWERWIDTH}px)`,
  }) : null;

  return (
    <AppBar position="absolute" sx={AppBarStyle}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {!open ? (
          <Box mr={2}>
            <img style={{ width: '25px' }} src={companyIcon || CompanyFavicon} alt="Company Favicon" />
          </Box>
        ) : null}
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {userNavigationPage}
        </Typography>
        <IconButton color="inherit" role="button" aria-label="Help" onClick={() => window.open('https://support.learnsoft.com', '_blank')}>
          <HelpIcon />
        </IconButton>
        <TopDropDownMenu />
      </Toolbar>
    </AppBar>
  );
}

export default TopMenu;
