import React, { useEffect, useState } from 'react'
import { find } from 'lodash';
import { Button, Tooltip } from '@mui/material'
import ViewFormLayout from 'components/ViewFormLayout'
import QuestionList from 'modules/LearnerTest/questionList';
import { ITestQuestionResponse } from 'utils/interfaces';
import { useGetPreTestByIdQuery, useSubmitTestMutation } from 'services/dataApi';
import { TestAnswer, TestAnswerObj, TestQuestion } from 'services/interfaces';
import { ACTION_ITEM_TOOLTIP_TEXT, AnswerTypesId, BUTTON_TEXT } from 'utils/constants';
import LoadingIcon from 'components/LoadingIcon';

export function getTestAnswers(
  answers: ITestQuestionResponse[],
  questions: TestQuestion[],
  sessionId: number,
  testInstanceId: number,
): TestAnswerObj[] {
  const newAnswers: TestAnswerObj[] = []
  answers.forEach((ans) => {
    const question = find(questions, { testQuestionId: Number(ans.id) }) as TestQuestion
    if (
      question && question.testAnswers
      && question.testAnswers.length > 0
      && testInstanceId && question?.testQuestionId) {
      const testAnswer = find(question.testAnswers, { answerText: ans.value }) as
      TestAnswer;
      const newAnswer = {
        learningObjectSessionId: sessionId,
        testInstanceId,
        testQuestionId: question?.testQuestionId,
        testAnswerId: testAnswer?.testAnswerId,
        answerTypeId: AnswerTypesId[testAnswer.answerTypeId || 0],
        answerText: testAnswer.answerText!,
      }
      newAnswers.push(newAnswer)
    }
  })
  return newAnswers
}

function LaunchPreTestView({
  onSubmitPreTest, testId, sessionId, passPreTest, testInstanceId,
}
  : {
    onSubmitPreTest: Function,
    testId: number|null,
    testInstanceId: number|null,
    sessionId: number,
    passPreTest: Function}) {
  const [testResponse, setTestResponse] = useState<ITestQuestionResponse[]>([]);
  const [submitDone, setSubmitDone] = useState(false);
  const skipQuery = !sessionId || !testId;
  // eslint-disable-next-line max-len
  const { data, isLoading } = useGetPreTestByIdQuery({ testId, learningObjectSessionId: sessionId }, {
    skip: skipQuery,
  });

  const [
    submitTest,
    { isLoading: isLoadingSubmitTest }] = useSubmitTestMutation();

  const handleSubmitAction = async () => {
    onSubmitPreTest(testResponse);
    if (data?.testQuestions && testInstanceId && testId && sessionId) {
      const payload = getTestAnswers(testResponse, data?.testQuestions, sessionId, testInstanceId);
      try {
        await submitTest({
          learningObjectSessionId: sessionId,
          testId,
          testInstanceId,
          dateCompleted: (new Date()).toISOString(),
          answers: payload,
        }).unwrap();
      } catch (error:any) {
        console.error(error)
      }
      setSubmitDone(true)
    }
  };

  const handleFetchAnswers = () => {
    const responses: ITestQuestionResponse[] = [];
    if (data?.testQuestions) {
      data.testQuestions.forEach((question) => {
        if (question.answerId) {
          const answer = find(question.testAnswers, { testAnswerId: question.answerId })
          if (answer) {
            responses.push({
              id: String(answer.testAnswerId),
              value: answer.answerText,
            })
          }
        }
      })
      setTestResponse(responses)
      if (responses.length === data.testQuestions.length) {
        setSubmitDone(true)
        passPreTest()
      }
    }
  }

  useEffect(() => {
    if (!isLoading) {
      handleFetchAnswers();
    }
  }, [isLoading])

  return (
    <ViewFormLayout testId="my-courses-launch-pre-test">
      <ViewFormLayout.ActionBar hasborderBottom>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SUBMIT_PRE_TEST}>
          {
            isLoadingSubmitTest ? <LoadingIcon />
              : <Button disabled={submitDone || isLoadingSubmitTest || !data} variant="contained" onClick={handleSubmitAction}>{BUTTON_TEXT.SUBMIT}</Button>
          }
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        {isLoading
          ? <LoadingIcon />
          : <QuestionList questions={data?.testQuestions} setTestResponse={setTestResponse} /> }
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}

export default LaunchPreTestView
