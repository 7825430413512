/* eslint-disable max-len */
import React from 'react'
import { Stack } from '@mui/material'
import LoadingIcon from 'components/LoadingIcon'
import {
  useGetCountriesQuery,
  useGetDivisionsQuery, useGetEmploymentTypeQuery, useGetSchoolsQuery, useGetSpecialtyTypesQuery,
  useGetUserDetailQuery,
  useUpdateUserProfileMutation,
} from 'services/dataApi';
import {
  GetCountryResponse,
  GetDivisionsResponse,
  GetEmploymentTypesResponse,
  GetSchoolResponse, GetSpecialtyTypesResponse, UpdateUserProfileRequest,
} from 'services/interfaces';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import AccountInformation from '../AccountInformation'
import Licenses from '../Licenses'
import CustomFields from '../CustomFields'
import Supervisors from '../Supervisors'

export default function AboutMe() {
  const { data: countries, isLoading: isLoadingCountries } = useGetCountriesQuery();
  const { data: schools, isLoading: isLoadingSchools } = useGetSchoolsQuery();
  const { data: employmentType, isLoading: isLoadingEmployment } = useGetEmploymentTypeQuery();
  const { data: specialtyType, isLoading: isLoadingSpecialtyType } = useGetSpecialtyTypesQuery();
  const { data: divisions, isLoading: isLoadingDivision } = useGetDivisionsQuery();
  const userId = useSelector((state: IState) => state.userProfile.userId);
  const {
    data: user, isLoading: isLoadingUserData, isFetching, refetch,
  } = useGetUserDetailQuery({ userId: String(userId) });

  const [updateProfile, { isLoading: isLoadingSave }] = useUpdateUserProfileMutation();

  const handleSubmit = async (profile: UpdateUserProfileRequest) => {
    try {
      const updateProfileForm: UpdateUserProfileRequest = { ...profile, userId: Number(user?.userId) }
      await updateProfile(updateProfileForm).unwrap();
      enqueueSnackbar('Changes were saved Successfully', { variant: 'success' });
      refetch()
    } catch (error) {
      console.log({ error })
    }
  }

  const isLoadingState = isLoadingCountries || isLoadingSchools || isLoadingSpecialtyType || isLoadingEmployment || isLoadingDivision || isLoadingSave || isLoadingUserData || isFetching;

  if (isLoadingState) {
    return (
      <Stack p={2}>
        <LoadingIcon />
      </Stack>
    )
  }

  return (
    <Stack p={2}>
      <AccountInformation
        onSubmitForm={(user: UpdateUserProfileRequest) => handleSubmit(user)}
        countries={countries as GetCountryResponse}
        schools={schools as GetSchoolResponse}
        employmentType={employmentType as GetEmploymentTypesResponse}
        specialtyType={specialtyType as GetSpecialtyTypesResponse}
        divisions={divisions as GetDivisionsResponse}
      />
      <Licenses />
      <CustomFields />
      <Supervisors />
    </Stack>
  )
}
