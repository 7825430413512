import React, { useEffect, useState } from 'react'
import ViewFormLayout from 'components/ViewFormLayout'
import { Button, Tooltip } from '@mui/material'
import QuestionList from 'modules/LearnerTest/questionList';
import { ITestQuestionResponse } from 'utils/interfaces';
import { useGetPreTestByIdQuery, useSubmitTestMutation } from 'services/dataApi';
import LoadingIcon from 'components/LoadingIcon';
import { find } from 'lodash';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { getTestAnswers } from './LaunchPreTestView';

function LaunchPostTestView({
  onSubmitPostTest, testId, sessionId, passPostTest, testInstanceId,
} :
  {
    onSubmitPostTest: Function,
    testId: number|null,
    sessionId: number,
    passPostTest: Function,
    testInstanceId: number|null}) {
  const [testResponse, setTestResponse] = useState<ITestQuestionResponse[]>([]);
  const [submitDone, setSubmitDone] = useState(false);
  const skipQuery = !sessionId || !testId;
  // eslint-disable-next-line max-len
  const { data, isLoading } = useGetPreTestByIdQuery({ testId, learningObjectSessionId: sessionId }, {
    skip: skipQuery,
  });
  const [
    submitTest,
    { isLoading: isLoadingSubmitTest }] = useSubmitTestMutation();

  const handleSubmitAction = async () => {
    onSubmitPostTest(testResponse);
    if (data?.testQuestions && testInstanceId && testId) {
      const payload = getTestAnswers(testResponse, data?.testQuestions, sessionId, testInstanceId);
      try {
        await submitTest({
          learningObjectSessionId: sessionId,
          testId,
          testInstanceId,
          dateCompleted: (new Date()).toISOString(),
          answers: payload,
        }).unwrap();
      } catch (error:any) {
        console.error(error)
      }
      setSubmitDone(true)
    }
  };

  const handleFetchAnswers = () => {
    const responses: ITestQuestionResponse[] = [];
    if (data?.testQuestions) {
      data.testQuestions.forEach((question) => {
        if (question.answerId) {
          const answer = find(question.testAnswers, { testAnswerId: question.answerId })
          if (answer) {
            responses.push({
              id: String(answer.testAnswerId),
              value: answer.answerText,
            })
          }
        }
      })
      setTestResponse(responses)
      if (responses.length === data.testQuestions.length) {
        setSubmitDone(true)
        passPostTest()
      }
    }
  }

  useEffect(() => {
    if (!isLoading) {
      handleFetchAnswers();
    }
  }, [isLoading])

  return (
    <ViewFormLayout testId="my-courses-launch-post-test">
      <ViewFormLayout.ActionBar hasborderBottom>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SUBMIT_POST_TEST}>
          {
            isLoadingSubmitTest ? <LoadingIcon />
              : <Button disabled={submitDone || isLoadingSubmitTest || !data} variant="contained" onClick={handleSubmitAction}>{BUTTON_TEXT.SUBMIT}</Button>
          }
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        {isLoading
          ? <LoadingIcon />
          : <QuestionList questions={data?.testQuestions} setTestResponse={setTestResponse} /> }
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}

export default LaunchPostTestView
