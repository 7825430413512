/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { dataApi } from 'services/dataApi';
import { IContentLibrary } from './interfaces'

const DEFAULT_SEARCH_FILTERS = {
  searchText: '',
  searchType: 'All',
  advanceFilter: {},
  catalogIds: [],
}

const initialState: IContentLibrary = {
  instructors: [],
  searchFilters: DEFAULT_SEARCH_FILTERS,
};

export const userContentLibrarySlice = createSlice({
  name: 'userContentLibrary',
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.searchFilters.searchText = action.payload
    },
    setAdvanceFilterObj: (state, action) => {
      state.searchFilters.advanceFilter = action.payload
    },
    setSearchType: (state, action) => {
      state.searchFilters.searchType = action.payload
    },
    resetAllFilters: (state) => {
      state.searchFilters = DEFAULT_SEARCH_FILTERS
    },
    setCatalogIds: (state, action) => {
      state.searchFilters.catalogIds = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dataApi.endpoints.getAllInstructors.matchFulfilled,
      (state, { payload }) => {
        state.instructors = payload
      },
    );
  },
});

export const {
  setSearchText,
  setAdvanceFilterObj,
  setSearchType,
  resetAllFilters,
  setCatalogIds,
} = userContentLibrarySlice.actions;

export default userContentLibrarySlice.reducer;
