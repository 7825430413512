/* eslint-disable max-len */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import Widget from 'components/Widget';
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import ObjectTableDetail from './CertificationTableDetail';
import AuthorizerList from '../SharedComponents/AuthorizerList';

export default function CertificationsDetails({ certificationSelected }: { certificationSelected: { id: number, label: string } }) {
  const supervisorsList = useSelector((state: IState) => state.userProfile?.user?.supervisors || []);

  return (
    <Grid
      container
      spacing={1}
    >
      <Widget title="Authorizer" columns={12} collapse>
        {supervisorsList?.length > 0 ? (
          <AuthorizerList authorizers={supervisorsList} />

        ) : <Typography variant="subtitle1">No Authorizers</Typography>}
      </Widget>
      <Widget title="Certification Info" columns={12}>
        <ObjectTableDetail certificationSelected={certificationSelected} />
      </Widget>
    </Grid>
  );
}
