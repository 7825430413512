import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import '../App.scss';
import Widget from 'components/Widget';
import { Grid } from '@mui/material';
import InformationCourses from 'modules/InformationCourses';
import CalendarWidget from 'modules/CalendarWidget';
import UserStatus from 'modules/UserStatus';
import Metrics from 'modules/UserMetrics';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useGetUserStatsQuery } from 'services/dataApi';
import { IState } from 'redux/interfaces';
import { hasUserAnyLearningElement } from 'utils/helpers';
import LoadingIcon from 'components/LoadingIcon';
import UserHighlightPopup from 'modules/UserHighlightPopup';
import { UserStatsResponse } from 'services/interfaces';
import Welcome from './welcome';

function Dashboard() {
  const dispatch = useDispatch();
  const { userId } = useSelector((state: IState) => state.userProfile);
  const { data, isLoading } = useGetUserStatsQuery({ userId });

  const hasElementsEnrolled = hasUserAnyLearningElement(data as UserStatsResponse);

  useEffect(() => {
    dispatch(setNavigationPage('My Tasks'))
  }, []);

  if (isLoading) {
    return (
      <Layout>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          paddingTop={3}
        >
          <LoadingIcon />
        </Grid>
      </Layout>
    )
  }

  return (
    <Layout>
      {
      hasElementsEnrolled ? (
        <Grid container spacing={1}>
          {/* UserStatus */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Widget height="320px" columns={0}>
              <UserStatus />
            </Widget>
          </Grid>

          {/* Metrics */}
          <Grid
            item
            sx={{
              display: {
                xs: 'none', sm: 'none', md: 'none', lg: 'flex',
              },
            }}
            md={4}
            lg={6}
          >
            <Widget height="320px" columns={0}>
              <Metrics />
            </Widget>
          </Grid>

          {/* CalendarWidget */}
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
          >
            <Widget height="320px" columns={0}>
              <CalendarWidget />
            </Widget>
          </Grid>

          <Grid
            item
            sx={{
              display: {
                xs: 'flex', sm: 'flex', md: 'flex', lg: 'none',
              },
              '@media (max-width: 1300px) and (min-width: 1200px)': {
                display: 'none', // Agrega overflow entre 1200px y 1300px
              },
            }}
            xs={12}
            sm={12}
            md={12}
          >
            <Widget height="320px" columns={0}>
              <Metrics />
            </Widget>
          </Grid>

          {/* InformationCourses */}
          <Grid item xs={12}>
            <Widget columns={0}>
              <InformationCourses />
            </Widget>
          </Grid>
        </Grid>
      ) : <Welcome />
     }
      <UserHighlightPopup />
    </Layout>
  );
}

export default Dashboard;
