/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Badge, BadgeProps, Button, Collapse, styled,
} from '@mui/material';
import ViewFormLayout from 'components/ViewFormLayout';
import { UserManagementRequest } from 'services/interfaces';
import UsersTable from './UsersTable/UsersTable';
import UserFilterForm from './UserFilterForm';

const INITIAL_FILTERS: UserManagementRequest = {
  userId: null,
  firstName: null,
  lastName: null,
  middleName: null,
  userName: null,
  email: null,
  employeeId: null,
  division: null,
  active: null,
  jobCodeDescription: null,
  jobClassDescription: null,
  jobPositionDescription: null,
  PageNumber: 1,
  PageSize: 10,
  SortingBy: 'userId',
  Ascending: true,
}
export default function AdminUsersList() {
  const [showFilters, setShowFilters] = useState<boolean>();
  const [filters, setFilters] = useState<UserManagementRequest>(INITIAL_FILTERS);

  const handleToggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -10,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const filtersApplyed = (filters: UserManagementRequest): number => Object.entries(filters)
    .filter(([key, value]) => value !== null && value !== 0 && !['PageNumber', 'PageSize', 'SortingBy'].includes(key))
    .length;

  return (
    <ViewFormLayout pl={0} pr={0} testId="users-list">
      <ViewFormLayout.ActionBar>
        <Button
          variant="contained"
          onClick={handleToggleFilters}
        >
          <StyledBadge badgeContent={filtersApplyed(filters)} color="secondary">
            {showFilters ? 'Hide Filters' : 'Filters'}
          </StyledBadge>
        </Button>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <Collapse in={showFilters}>
          <UserFilterForm
            onSearch={(data: UserManagementRequest) => {
              setFilters((_data) => ({ ..._data, ...data }))
              handleToggleFilters()
            }}
            onReset={() => {
              setFilters(INITIAL_FILTERS);
              handleToggleFilters()
            }}
          />
        </Collapse>
        <UsersTable filters={filters} />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
