import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { handleErrorMessage } from 'utils/helpers';

const useHandleApiError = (isError: boolean, error: FetchBaseQueryError) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isError || !error) return;

    const message = handleErrorMessage(error)

    enqueueSnackbar(message, { variant: 'error' });
  }, [isError, error, enqueueSnackbar]);
};

export default useHandleApiError;
