import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
} from '@mui/material';

import ViewFormLayout from 'components/ViewFormLayout';
import { BUTTON_TEXT } from 'utils/constants';
import EvaluationQuestionGroupTable from './EvaluationQuestionGroupTable/EvaluationQuestionGroupTable';

export default function AdminEvaluationQuestionGroup() {
  const navigate = useNavigate();
  return (
    <ViewFormLayout testId="evaluations">
      <ViewFormLayout.ActionBar>
        <Button variant="contained" onClick={() => { navigate('new') }}>{BUTTON_TEXT.NEW_EVALUATION_QUESTION_GROUP}</Button>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <EvaluationQuestionGroupTable />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
