import { useState } from 'react';

/* eslint-disable no-param-reassign */
export default function useInternalStorage<T>(key: string, initialValue: T) {
  // Helper function to add or replace an item in the list
  const addOrReplace = (list: any[], newItem: any) => {
    const index = list.findIndex((item) => item.id === newItem.id);
    if (index !== -1) {
      list[index] = newItem;
    } else {
      list.push(newItem);
    }
    return list;
  };

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error('Error reading localStorage key', key, error);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error setting localStorage key', key, error);
    }
  };

  const addValue = (id: string | number, newItem: any) => {
    try {
      const newList = addOrReplace(storedValue as any[], { ...newItem, id }) as T;
      setStoredValue(newList);
      localStorage.setItem(key, JSON.stringify(newList));
    } catch (error) {
      console.error('Error adding item to localStorage key', key, error);
    }
  };

  const removeItem = () => {
    try {
      localStorage.removeItem(key);
      setStoredValue(initialValue);
    } catch (error) {
      console.error('Error removing localStorage key', key, error);
    }
  };

  return [storedValue, addValue, removeItem, setValue] as const;
}
