import React from 'react';
import { IUserFutureJobPosition } from 'redux/interfaces';
import ViewFormLayout from 'components/ViewFormLayout';
import CustomTable from 'components/CustomTable';
import { TRows, TColumns } from 'components/CustomTable/interface';

// eslint-disable-next-line import/extensions
import futureJobPositions from './futureJobPosition.json';

export default function FutureJobPositon() {
  const columns: TColumns[] = [
    {
      name: 'Career Step',
      column: 'CareerStep',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Future Job Position',
      column: 'FutureJobPosition',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Name',
      column: 'Name',
      width: 150,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Description',
      column: 'Description',
      width: 150,
      sortable: true,
      hidden: false,
    },
  ];

  const dataSet: TRows[] = (futureJobPositions || []).map(
    (item: IUserFutureJobPosition) => ({
      ...item,
      id: item.Id,
      isCollapsable: false,
      details: [],
      // eslint-disable-next-line comma-dangle
    })
  );

  return (
    <ViewFormLayout testId="available-future-job-position">
      <ViewFormLayout.Title>Available Future Job Position</ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <CustomTable rows={dataSet} columns={columns} collapseAllRow={false} />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
