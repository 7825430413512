/* eslint-disable max-len */
import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
} from '@mui/material';
import { TestQuestion } from 'services/interfaces';

function CheckBoxQuestion({ question, handleResponse } : {question: TestQuestion, handleResponse: Function}) {
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', pr: 4 }}>
      <FormControl component="fieldset" variant="standard" fullWidth margin="normal">
        <FormLabel component="legend">{question?.testQuestionText}</FormLabel>
        {question.answerSummary && <Typography variant="caption">{question.answerSummary}</Typography>}
        <FormGroup>
          {React.Children.toArray(question?.testAnswers.map((item) => (
            <FormControlLabel
              sx={{ pl: 3 }}
              control={(
                <Checkbox
                  name={item.answerValue!}
                  onChange={(e) => handleResponse({ name: e?.target.name, value: e?.target?.checked })}
                />
              )}
              label={item.answerValue}
            />
          )))}
        </FormGroup>
      </FormControl>
    </Box>
  );
}

export default CheckBoxQuestion;
