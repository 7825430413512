/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  Tabs, Tab, AppBar, Box,
  Button,
  Tooltip,
} from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ViewFormLayout from 'components/ViewFormLayout';
import { useGetUserProfileDetailQuery } from 'services/dataApi';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch } from 'react-redux';
import UserProfile from './UserProfile';
import UserGroups from './UserGroups';
import { useFormContext } from './FormContext';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function AdminUserProfile({ id }: { id: string }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmitForm, isLoadingSave } = useFormContext();
  const {
    data: user, isLoading: isLoadingUserData, refetch,
  } = useGetUserProfileDetailQuery({ userId: id });

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSubmit2 = async () => {
    try {
      await handleSubmitForm()
      refetch()
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    if (user?.userName) dispatch(setNavigationPage(`User Profile: ${user?.firstName} ${user?.lastName} (${user?.userName})`))
  }, [user]);

  return (
    <ViewFormLayout pl={0} pr={0} testId="users-list">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
          <Button variant="text" onClick={() => navigate(-1)}>{BUTTON_TEXT.BACK}</Button>
        </Tooltip>
        <Button
          variant="contained"
          onClick={() => handleSubmit2()}
        >
          Save
        </Button>
        <Button variant="contained" onClick={() => navigate(`/transcript-report/${id}`)}>Print transcript</Button>
        <Button variant="contained" onClick={() => navigate(`/admin/user/profile/add-new-group/${id}`)}>Add New Group</Button>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <AppBar position="static" sx={{ bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} variant="fullWidth">
              <Tab
                icon={<PersonOutlineIcon />}
                iconPosition="start"
                label="User Profile"
              />
              <Tab
                icon={<GroupsIcon />}
                iconPosition="start"
                label="User Groups"
              />
            </Tabs>
          </AppBar>
          <CustomTabPanel value={value} index={0}>
            <UserProfile
              user={user}
              isLoadingSave={isLoadingSave}
              isLoadingUserData={isLoadingUserData}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <UserGroups userGroups={user?.userGroups || []} />
          </CustomTabPanel>
        </Box>
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
