import { Box, Grid } from '@mui/material';
import React from 'react';
import Question from './Question';

export interface IQuestion{
  Group: string;
  Question: string;
  Options: string[];
}

interface IQuestionProps{
  questions: IQuestion[]
}

export default function Questions({ questions }: IQuestionProps) {
  return (
    <Box mt={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={2}>
              #
            </Grid>
            <Grid item xs={5}>
              Question
            </Grid>
            <Grid item xs={5}>
              <Box display="flex" justifyContent="space-around">
                {
                  React.Children.toArray(questions[0].Options.map(
                    (option) => (
                      <Box>{option}</Box>
                    ),
                  ))
                }
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {
          React.Children.toArray(
            questions.map(
              (question, idx) => <Question questionNumber={idx + 1} question={question} />,
            ),
          )
        }
      </Grid>
    </Box>
  )
}
