/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import LoadingIcon from 'components/LoadingIcon';
import {
  FormControl, InputLabel, MenuItem, Slider,
} from '@mui/material';
import {
  useGetAllInstructorsQuery,
  useGetAllFacilityRoomsQuery,
  useGetTopicQuery,
  useGetVendorsQuery,
  useGetAccreditationsQuery,
  useGetJobPositionQuery,
  useGetAllFacilityQuery,
} from 'services/dataApi'
import {
  TFacility, IState, Instructor, TRoom, TVendor, TTopic, TAccreditation, TJobPosition,
} from 'redux/interfaces'
import { useSelector } from 'react-redux';
import { validateArray } from 'utils/helpers';
import SelectStyled from 'components/SelectStyled';
import DateInput from 'components/DatePickerStyled';
import Acordion from './Acordion';

export default function AdvanceSearch({ onSelected, resetAll } : {onSelected: Function, resetAll: boolean}) {
  const userId = useSelector((state: IState) => state.userProfile.userId);
  const {
    data: instructorList,
    isLoading: isLoadingInstructor,
  } = useGetAllInstructorsQuery(userId);

  const {
    data: facilityRoomList,
    isLoading: isLoadingFacilityRoom,
  } = useGetAllFacilityRoomsQuery(userId);

  const {
    data: facilityList,
    isLoading: isLoadingFacility,
  } = useGetAllFacilityQuery(userId);

  const {
    data: vendorsList,
    isLoading: isLoadingVendors,
  } = useGetVendorsQuery(userId);

  const {
    data: topicList,
    isLoading: isLoadingTopic,
  } = useGetTopicQuery(userId);

  const {
    data: accreditationList,
    isLoading: isLoadingAccreditation,
  } = useGetAccreditationsQuery(userId);

  const {
    data: jobPositionList,
    isLoading: isLoadingJobPosition,
  } = useGetJobPositionQuery({ userId });

  const [selectedInstructor, setSelectedInstructor] = useState(' ');
  const [selectedFacility, setSelectedFacility] = useState(' ');
  const [selectedRoom, setSelectedRoom] = useState(' ');
  const [selectedTrainingVendor, setSelectedTrainingVendor] = useState(' ');
  const [selectedTopic, setSelectedTopic] = useState(' ');
  const [selectedCEHours, setSelectedCEHours] = useState(' ');
  const [selectedCEUnits, setSelectedCEUnits] = useState(' ');
  const [selectedAccreditation, setSelectedAccreditation] = useState(' ');
  const [selectedJobPosition, setSelectedJobPosition] = useState(' ');

  function getRooms(_facilityRoomList: TRoom[], _selectedFacility: string) {
    let rooms = _facilityRoomList;
    if (_selectedFacility) {
      rooms = validateArray(_facilityRoomList)?.filter((room: TRoom) => room?.facilityId === _selectedFacility)
    }
    return validateArray(rooms)?.map((room: TRoom) => <MenuItem key={room.roomId} value={room.roomId}>{room?.roomName}</MenuItem>)
  }

  useEffect(() => {
    if (resetAll) {
      setSelectedInstructor(' ')
      setSelectedFacility(' ')
      setSelectedRoom(' ')
      setSelectedTrainingVendor(' ')
      setSelectedTopic(' ')
      setSelectedCEHours(' ')
      setSelectedCEUnits(' ')
      setSelectedAccreditation(' ')
      setSelectedJobPosition(' ')
    }
  }, [resetAll]);
  return (
    <>
      <Acordion title="Instructor">
        {isLoadingInstructor ? <LoadingIcon /> : (
          <SelectStyled
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedInstructor}
            label="Instructor"
            onChange={(event: any) => {
              const instructor = event.target.value as string;
              setSelectedInstructor(instructor)
              onSelected('instructorUserId', instructor.toString())
            }}
          >
            <MenuItem value=" ">Select</MenuItem>
            {instructorList?.map((instructor: Instructor) => <MenuItem key={instructor.userId} value={instructor.userId}>{instructor?.fullName}</MenuItem>)}
          </SelectStyled>
        )}
      </Acordion>
      <Acordion title="Date Range">
        <DateInput title="Start Date" onSelect={(startDate: string) => onSelected('startDateTime', (startDate))} />
        <DateInput title="End Date" onSelect={(endDate: string) => onSelected('endDateTime', (endDate))} />
      </Acordion>
      <Acordion title="Facility">
        {isLoadingFacility ? <LoadingIcon /> : (
          <SelectStyled
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedFacility}
            label="facility"
            onChange={(event: any) => {
              const facilityId = event.target.value as string;
              setSelectedFacility(facilityId)
              setSelectedRoom('')
              onSelected('facilityId', facilityId.toString())
            }}
          >
            <MenuItem value=" ">Select</MenuItem>
            {validateArray(facilityList)?.map((facility: TFacility) => <MenuItem key={facility.facilityId} value={facility.facilityId}>{facility?.facilityName}</MenuItem>)}
          </SelectStyled>
        )}
      </Acordion>
      <Acordion title="Room">
        {isLoadingFacilityRoom ? <LoadingIcon /> : (
          <SelectStyled
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedRoom}
            label="Room"
            onChange={(event: any) => {
              const roomId = event.target.value as string;
              setSelectedRoom(roomId)
              onSelected('facilityRoomId', roomId.toString())
            }}
          >
            <MenuItem value=" ">Select</MenuItem>
            {getRooms(facilityRoomList, selectedFacility)}
          </SelectStyled>
        )}
      </Acordion>
      <Acordion title="Training Vendor">
        {isLoadingVendors ? <LoadingIcon /> : (
          <SelectStyled
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedTrainingVendor}
            label="Training Vendor"
            onChange={(event: any) => {
              const vendor = event.target.value as string;
              setSelectedTrainingVendor(vendor)
              onSelected('vendorId', vendor.toString())
            }}
          >
            <MenuItem value=" ">Select</MenuItem>
            {validateArray(vendorsList)?.map((vendor: TVendor) => <MenuItem key={vendor.vendorId} value={vendor.vendorId}>{vendor?.vendorName}</MenuItem>)}

          </SelectStyled>
        )}
      </Acordion>
      <Acordion title="Topic">
        {isLoadingTopic ? <LoadingIcon /> : (
          <SelectStyled
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedTopic}
            label="Topic"
            onChange={(event: any) => {
              const topic = event.target.value as string;
              setSelectedTopic(topic)
              onSelected('learningObjectTopicID', topic.toString())
            }}
          >
            <MenuItem value=" ">Select</MenuItem>
            {validateArray(topicList)?.map((topic: TTopic) => <MenuItem key={topic.topicId} value={topic.topicId}>{topic?.topicName}</MenuItem>)}
          </SelectStyled>
        )}
      </Acordion>
      <Acordion title="CE Hours">
        <InputLabel id="demo-simple-select-label">{`${selectedCEHours} Hours`}</InputLabel>
        <FormControl fullWidth>
          <Slider
            aria-label="Small steps"
            defaultValue={0.05}
            onChange={(e, val) => {
              setSelectedCEHours(val.toString())
              onSelected('creditHours', val.toString())
            }}
            step={0.01}
            min={0.01}
            max={5.00}
            valueLabelDisplay="auto"
          />
        </FormControl>
      </Acordion>
      <Acordion title="CE Units">
        <InputLabel id="demo-simple-select-label">{`${selectedCEUnits} units`}</InputLabel>
        <FormControl fullWidth>
          <Slider
            aria-label="Small steps"
            defaultValue={0.05}
            onChange={(e, val) => {
              setSelectedCEUnits(val.toString())
              onSelected('creditUnits', val.toString())
            }}
            step={0.01}
            min={0.01}
            max={5.00}
            valueLabelDisplay="auto"
          />
        </FormControl>
      </Acordion>
      <Acordion title="Accreditation">
        {isLoadingAccreditation ? <LoadingIcon /> : (
          <SelectStyled
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedAccreditation}
            label="accreditation"
            onChange={(event: any) => {
              const accreditation = event.target.value as string;
              setSelectedAccreditation(accreditation)
              onSelected('accreditation', accreditation.toString())
            }}
          >
            <MenuItem value=" ">Select</MenuItem>
            {validateArray(accreditationList)?.map((acc: TAccreditation) => <MenuItem key={acc.accreditationId} value={acc.accreditationId}>{acc?.accreditationName}</MenuItem>)}
          </SelectStyled>
        )}
      </Acordion>
      <Acordion title="Job Position">
        {isLoadingJobPosition ? <LoadingIcon /> : (
          <SelectStyled
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedJobPosition}
            label="Job Position"
            onChange={(event: any) => {
              const job = event.target.value as string;
              setSelectedJobPosition(job)
              onSelected('jobPositionId', job.toString())
            }}
          >
            <MenuItem value=" ">Select</MenuItem>
            {validateArray(jobPositionList)?.map((acc: TJobPosition) => <MenuItem key={acc?.jobPositionId} value={acc?.jobPositionId}>{acc?.jobPositionDescription}</MenuItem>)}

          </SelectStyled>
        )}
      </Acordion>
    </>
  );
}
