import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import Layout from 'components/Layout';
import {
  Grid,
  Tabs,
  Tab,
  AppBar,
  Box,
  Button,
  Tooltip,
} from '@mui/material';
import CourseInformation from 'modules/administrator/Courses/CoursesDetails/CourseInformation';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GroupsIcon from '@mui/icons-material/Groups';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Attributes from 'modules/administrator/Courses/CoursesDetails/Attributes';
import Outline from 'modules/administrator/Courses/CoursesDetails/Outline';
import Advance from 'modules/administrator/Courses/CoursesDetails/Advance';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {
    children, value, index,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function CourseDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  useEffect(() => {
    dispatch(setNavigationPage('Course Detail'))
  }, []);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSave = () => {
    if (params.new) {
      enqueueSnackbar('The New Course was created successfully', { variant: 'success' })
    } else {
      enqueueSnackbar('Course Saved Successfully', { variant: 'success' })
    }

    navigate('/admin/courses')
  }

  return (
    <Layout>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
          p={1}
          bgcolor={(theme) => theme.palette.background.paper}
          className="center-vertical"
          sx={{
            justifyContent: 'right',
            borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
            height: 'auto',
            marginBottom: 1,
          }}
        >
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
            <Button variant="contained" onClick={handleSave}>{BUTTON_TEXT.SAVE}</Button>
          </Tooltip>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => navigate('/admin/courses')}>{BUTTON_TEXT.BACK}</Button>
          </Tooltip>
        </Grid>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <AppBar position="static" sx={{ bgcolor: 'background.paper' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
            >
              <Tab icon={<PersonOutlineIcon />} iconPosition="start" label="Overview" />
              <Tab icon={<GroupsIcon />} iconPosition="start" label="Attributes" />
              <Tab icon={<HowToRegIcon />} iconPosition="start" label="Outline" />
              <Tab icon={<FormatListNumberedIcon />} iconPosition="start" label="Advanced" />
            </Tabs>
          </AppBar>
          <CustomTabPanel value={value} index={0}>
            <CourseInformation />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Attributes />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Outline />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Advance />
          </CustomTabPanel>
        </Box>
      </Grid>
    </Layout>
  );
}

export default CourseDetail;
