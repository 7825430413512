import React from 'react'
import { Box } from '@mui/material';
import FormView, { IFormItem } from 'components/FormView';
import { ITestLibraries } from 'redux/interfaces';

export default function TestLibraryForm({ testLib, onCallback }:
   {testLib?:ITestLibraries, onCallback: Function}) {
  const AccountStatusForm: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: testLib?.name,
      fieldId: 'name',
      label: 'Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: testLib?.scormTest ? 'true' : undefined,
      fieldId: 'scormTest',
      label: 'Scorm Test',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { value: 'Scorm Course 1', key: 'ScormCourse1' },
        { value: 'Scorm Course 2', key: 'ScormCourse2' },
        { value: 'Scorm Course 3', key: 'ScormCourse3' },
      ],
      fieldValue: testLib?.scormCourse,
      fieldId: 'scormCourse',
      label: 'Scorm Course',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { value: 'Aicc', key: 'Aicc' },
        { value: 'SCORM', key: 'SCORM' },
      ],
      fieldValue: testLib?.wbtType,
      fieldId: 'wbtType',
      label: 'WBT Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: testLib?.wbtURL,
      fieldId: 'wbtURL',
      label: 'WBT URL',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: testLib?.isPublic ? 'true' : undefined,
      fieldId: 'isPublic',
      label: 'IS Public',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [{ value: 'Private', key: 'Private' },
        { value: 'Any Other users', key: 'AnyOtherUsers' }],
      fieldValue: testLib?.ownership,
      fieldId: 'ownership',
      label: 'Ownership',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'multiSelect',
      options: [{ value: 'John Doe', key: 'JohnDoe' },
        { value: 'Alice Smith', key: 'AliceSmith' },
        { value: 'Emily Jones', key: 'EmilyJones' }],
      fieldValue: [],
      fieldId: 'users',
      label: 'Users',
      titleSize: 3,
    },
  ]

  const handleFormResponse = (obj: { id: string, value: any }) => {
    onCallback(obj)
  };

  return (
    <Box sx={{
      width: '100%', bgcolor: 'background.paper', pl: 2, pb: 2,
    }}
    >
      <FormView
        title="Test Library"
        style={{ paddingLeft: 1, paddingRight: 1 }}
        fields={AccountStatusForm}
        onChangeValues={(obj: { id: string, value: any }) => handleFormResponse(obj)}
      />
    </Box>
  )
}
