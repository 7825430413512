import React from 'react'
import { useSelector } from 'react-redux';
import { IState, ISupervisors } from 'redux/interfaces';
import { TRows, TColumns } from 'components/CustomTable/interface';
import ViewFormLayout from 'components/ViewFormLayout';
import CustomTable from 'components/CustomTable';

export default function Supervisors() {
  const userSupervisors = useSelector((state: IState) => state.userProfile?.user?.supervisors);

  const columns:TColumns[] = [
    {
      name: 'Supervisor ID',
      column: 'supervisorId',
      width: 200,
      sortable: true,
    },
    {
      name: 'Employee ID',
      column: 'employeeId',
      width: 200,
      sortable: true,
    },
    {
      name: 'Direct Level',
      column: 'directLevel',
      width: 250,
      sortable: true,
    },
    {
      name: 'Supervisor Name',
      column: 'name',
      width: 300,
      sortable: true,
    },
  ];

  const dataSet: TRows[] = userSupervisors?.map((sup : ISupervisors) => ({
    ...sup,
    id: sup?.supervisorId,
    isCollapsable: false,
    details: [],
  })) || [];

  return (
    <ViewFormLayout testId="current-supervisors">
      <ViewFormLayout.Title>
        Current Supervisors
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <CustomTable
          rows={dataSet}
          columns={columns}
          collapseAllRow={false}
          defaultSort="supervisorId"
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
