/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  FILTER_KEY, COURSE_STATUS_BY_DATE,
  COURSE_STATUS_BY_DATE_LABEL,
  YELLLOWFIN_COOKIE_NAME,
  YELLLOWFIN_COOKIE_DOMAIN,
} from 'utils/constants';
import { categorizeByDueDate, removeDuplicatesFromArray } from 'utils/helpers';
import { dataApi } from 'services/dataApi';
import { CourseDetails } from 'services/interfaces';

const initialState = {
  filterByOverDue: false,
  filterByDueSoon: false,
  filterByIncompleteEvaluation: false,
  filterByStatus: null,
  coursesStats: {
    [COURSE_STATUS_BY_DATE_LABEL.Overdue]: 0,
    [COURSE_STATUS_BY_DATE_LABEL.Duesoon]: 0,
    [COURSE_STATUS_BY_DATE_LABEL.Upcoming]: 0,
    [COURSE_STATUS_BY_DATE_LABEL.Future]: 0,
    [COURSE_STATUS_BY_DATE_LABEL.Flexible]: 0,
  },
  filterByDate: null,
};

export const userDashboard = createSlice({
  name: 'userDashboard',
  initialState,
  reducers: {
    resetAllFilters: () => initialState,
    filterByOverDue: (state) => {
      state.filterByOverDue = true;
    },
    filterByDueSoon: (state) => {
      state.filterByDueSoon = true;
    },
    filterByIncompleteEvaluation: (state) => {
      state.filterByIncompleteEvaluation = true;
    },
    filterByStatus: (state, action) => {
      state.filterByStatus = action.payload;
    },
    filterDate: (state, action) => {
      state.filterByDate = action.payload
    },
    removeFilters: (state, action) => {
      if (action?.payload === FILTER_KEY.RemoveDate) {
        state.filterByDate = null;
      } else if (action?.payload === FILTER_KEY.RemoveStatus) {
        state.filterByStatus = null;
      } else {
        state.filterByDate = null;
        state.filterByStatus = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dataApi.endpoints.getAllCoursesByUserId.matchFulfilled,
      (state, { payload }) => {
        const courses = removeDuplicatesFromArray(payload);
        const statusMap: Record<COURSE_STATUS_BY_DATE, number> = {
          [COURSE_STATUS_BY_DATE_LABEL.Overdue]: 0,
          [COURSE_STATUS_BY_DATE_LABEL.Duesoon]: 0,
          [COURSE_STATUS_BY_DATE_LABEL.Upcoming]: 0,
          [COURSE_STATUS_BY_DATE_LABEL.Future]: 0,
          [COURSE_STATUS_BY_DATE_LABEL.Flexible]: 0,
        };

        const count = (status: COURSE_STATUS_BY_DATE) => {
          statusMap[status] += 1;
        };

        courses.forEach((course: CourseDetails) => {
          // eslint-disable-next-line max-len
          const statusAux = categorizeByDueDate(course?.dueDate)
          if (!course.isCollapsable) {
            count(statusAux as COURSE_STATUS_BY_DATE)
          } else {
            const innerCourses = course.learningDetails || [];
            innerCourses.forEach((item: CourseDetails) => {
              const statusAux = categorizeByDueDate(item?.dueDate)
              count(statusAux as COURSE_STATUS_BY_DATE);
            })
          }
        });

        state.coursesStats = {
          [COURSE_STATUS_BY_DATE_LABEL.Overdue]: statusMap[COURSE_STATUS_BY_DATE_LABEL.Overdue],
          [COURSE_STATUS_BY_DATE_LABEL.Duesoon]: statusMap[COURSE_STATUS_BY_DATE_LABEL.Duesoon],
          [COURSE_STATUS_BY_DATE_LABEL.Upcoming]: statusMap[COURSE_STATUS_BY_DATE_LABEL.Upcoming],
          [COURSE_STATUS_BY_DATE_LABEL.Future]: statusMap[COURSE_STATUS_BY_DATE_LABEL.Future],
          [COURSE_STATUS_BY_DATE_LABEL.Flexible]: statusMap[COURSE_STATUS_BY_DATE_LABEL.Flexible],
        }
      },
    );
    builder.addMatcher(
      dataApi.endpoints.getYellowFinToken.matchFulfilled,
      (state, { payload }) => {
        const yellowfinSSOResponse = (payload);
        const { yellowfinSessionId } = yellowfinSSOResponse;
        document.cookie = `${YELLLOWFIN_COOKIE_NAME}="${yellowfinSessionId}"; domain=${YELLLOWFIN_COOKIE_DOMAIN}; path=/; SameSite=None; Secure`;
      },
    );
  },
});

export const {
  filterByOverDue,
  filterByDueSoon,
  filterByIncompleteEvaluation,
  resetAllFilters,
  filterByStatus,
  filterDate,
  removeFilters,
} = userDashboard.actions;

export default userDashboard.reducer;
