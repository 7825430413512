import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import CourseInstancesTable from './CourseInstancesTable/index';

export default function CourseInstances() {
  const navigate = useNavigate();
  const { learningObjectId } = useParams();

  const handleNewUser = () => {
    navigate('/admin/courses');
  };

  return (
    <ViewFormLayout pl={0} pr={0} testId="users-list">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
          <Button variant="text" onClick={handleNewUser}>
            {BUTTON_TEXT.BACK}
          </Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        {learningObjectId && <CourseInstancesTable learningObjectId={learningObjectId} /> }
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
