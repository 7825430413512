import React from 'react'
import {
  Box,
  Button,
  Paper,
  Tooltip,
  styled,
} from '@mui/material'
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';

function LaunchChecklistView() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center" height="100%" p={2}>
      <Item>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.LAUNCH_CHECKLIST}>
          <Button variant="contained">{BUTTON_TEXT.LAUNCH_CHECKLIST}</Button>
        </Tooltip>

      </Item>
    </Box>
  )
}

export default LaunchChecklistView
