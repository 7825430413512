/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import {
  Grid, Checkbox, Typography, Container, Box, Button, Alert,
  Tooltip,
} from '@mui/material';
import LoadingIcon from 'components/LoadingIcon';
import { ILearningObjectDetail } from 'modules/SearchLearningObject/shared';
import ObjectCardDetail from 'modules/SearchLearningObject/CertificationDetail/CertificationCardDetail';
import {
  useEnrollCoursesMutation,
  useGetCertificationCoursesQuery,
} from 'services/dataApi';
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { EnrollCoursesRequest, EnrollCoursesResponse } from 'services/interfaces';

export default function CertificationTableDetail({ certificationSelected }: { certificationSelected: { id: number, label: string } }) {
  const {
    data: rows,
    isLoading,
  } = useGetCertificationCoursesQuery({ objectId: certificationSelected?.id });
  const [enrollSelfService, { isLoading: isLoadingEnroll }] = useEnrollCoursesMutation();
  const userId = useSelector((state: IState) => state.userProfile.userId);
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState<String[]>([]);
  const [enrolledResponse, setEnrolledResponse] = useState<EnrollCoursesResponse[]>([]);

  useEffect(() => {
    setSelected(selectAll ? rows?.map((row: ILearningObjectDetail) => String(row?.learningObjectInstanceId)) : [])
  }, [selectAll]);

  const onEnrollSelf = async (_selected: String[]) => {
    if (!_selected.length) {
      return;
    }

    const selectedCertifications: ILearningObjectDetail[] = rows?.filter((obj: ILearningObjectDetail) => _selected.includes(String(obj.learningObjectInstanceId)));
    const payloadList: EnrollCoursesRequest[] = selectedCertifications?.map((course: ILearningObjectDetail) => ({
      userId,
      learningObjectInstanceId: String(course.learningObjectInstanceId),
      learningObjectId: String(course?.learningObjectId),
      attendees: String(course.createdByUserId),
      status: '2',
      isStartEnrollment: true,
      isProcessEnrollment: false,
      AssignedCertificationId: String(course?.certificationId),
    }))
    try {
      const response = await enrollSelfService(payloadList).unwrap();
      setEnrolledResponse(response)
    } catch (error) {
      console.log({ error })
    }
  };
  const onLoading = isLoadingEnroll || isLoading;
  const enrrolledError = enrolledResponse?.filter((_enrolled: EnrollCoursesResponse) => (_enrolled?.errorMsg !== ''))

  return (
    <TableContainer className="object-table" data-testid="learning-object-table">
      {enrrolledError?.length > 0 && <Alert severity="error">Some courses had an error in the process, check their status!</Alert>}
      {enrrolledError?.length === 0 && enrolledResponse?.length > 0 && <Alert severity="success">All the enrollment process were successful!</Alert>}
      <br />
      <Table sx={{ minWidth: 500, overflow: 'hidden' }} aria-label="custom pagination table">
        <TableHead>
          <TableRow sx={{ height: 40 }}>
            <TableCell
              sx={{
                padding: '8px 8px',
                color: (theme) => theme.palette.common.white,
                backgroundColor: (theme) => theme.palette.grey[400],
              }}
            >

              <Container
                data-testid="learning-object"
                maxWidth={false}
                sx={{
                  overflow: 'hidden',
                }}
              >
                <Grid container>
                  <Grid
                    md={1}
                    item
                  >
                    <Checkbox checked={selectAll} onChange={(event) => setSelectAll(event.target.checked)} />
                  </Grid>
                  <Grid item md={7} display="flex" flexDirection="row" justifyContent="space-between">
                    <Box
                      sx={{
                        padding: '4px 0px',
                      }}
                      data-testid="learning-Grid-box"
                    >
                      <div className="course-detail-container">
                        <Typography variant="h6"><strong>{`${certificationSelected.label}`}</strong></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid
                    md={4}
                    item
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.ENROLL_SELF}>
                      <Button
                        sx={{
                          textTransform: 'none',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        variant="contained"
                        disabled={onLoading || selected?.length === 0}
                        onClick={() => onEnrollSelf(selected)}
                      >
                        {BUTTON_TEXT.ENROLL_SELF}
                      </Button>
                    </Tooltip>
                    <Typography variant="subtitle1">
                      <strong>{`Selected ${selected?.length} ${selected?.length > 1 ? 'Courses' : 'Course'}`}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!onLoading
            && rows?.map((row: ILearningObjectDetail, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`${index} - prop`}>
                <TableCell sx={{ padding: '8px 8px' }} className="object-container-base">
                  <ObjectCardDetail
                    learningObjectDetail={row}
                    enrolledStatus={enrolledResponse?.find(
                      (_enrolled: EnrollCoursesResponse) => String(_enrolled?.learningObjectInstanceId) === String(row?.learningObjectInstanceId),
                    )}
                    selected={selected?.includes(String(row?.learningObjectInstanceId))}
                    onChecked={(key: string, value: boolean) => setSelected((prev) => {
                      if (value) {
                        return [...prev, key];
                      }
                      return prev?.filter((item) => item !== key);
                    })}
                  />
                </TableCell>
              </TableRow>
            ))}
          {!onLoading && rows.length === 0 && (
            <TableRow style={{ height: 53 }}>
              <TableCell colSpan={1}> No records found.</TableCell>
            </TableRow>
          )}
          {onLoading && (
            <TableRow style={{ height: 53 }}>
              <TableCell colSpan={1}>
                <LoadingIcon />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
