import React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { InputLabel, Typography } from '@mui/material';

function TextArea({ question }: {question: string}) {
  return (
    <Paper elevation={1} sx={{ p: 2 }}>
      <InputLabel>
        <Typography variant="body1">{question}</Typography>
      </InputLabel>
      <TextField variant="outlined" multiline rows={6} fullWidth autoFocus />
    </Paper>
  );
}

export default TextArea;
