/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import {
  Button,
  Stack, Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  IconButton,
  Typography,
  Paper,
  TextField,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function CheckboxList({
  question, rightAnswer, onCallback, items,
}:
{question: string, rightAnswer: string, onCallback: Function, items: string[]}) {
  // const [items, setItems] = useState<string[]>([]);
  const textRef = useRef<HTMLInputElement>(null);

  const handleAddItem = () => {
    const text = textRef.current?.value as string;
        textRef.current!.value = '';
        onCallback([...items, text])
  };

  const handleDeleteItem = (index: number) => {
    const updatedItems = items.filter((_, i) => i !== index);
    onCallback(updatedItems)
  };

  const isNotRightOne = !items.includes(rightAnswer);
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', pr: 4 }}>
      <Typography variant="h6"> Preview </Typography>
      <Stack flexDirection="row" gap={2} mt={1}>
        <TextField
          fullWidth
          size="small"
          inputRef={textRef}
          placeholder="Create a new answer"
          type="text"
        />
        <Button
          sx={{ width: '190px', height: '40px' }}
          variant="contained"
          onClick={() => handleAddItem()}
        >
          Add new answer
        </Button>
      </Stack>
      {isNotRightOne && (
      <Stack mt={1}>
        <Typography variant="caption" color="error">Do not forget the right answer!</Typography>
      </Stack>
      )}
      <br />
      {items?.length > 0 && (
        <Paper elevation={1} sx={{ p: 2 }}>
          <FormControl component="fieldset" variant="standard" fullWidth>
            <FormLabel component="legend">{question}</FormLabel>
            <FormGroup>
              {React.Children.toArray(items.map((item: string, indx: number) => (
                <Stack flexDirection="row" alignItems="center" justifyContent="space-between" flexGrow={2}>
                  <FormControlLabel
                    sx={{ pl: 3 }}
                    control={
                      <Checkbox name={item} />
            }
                    label={item}
                  />
                  <IconButton onClick={() => handleDeleteItem(indx)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Stack>
              )))}
            </FormGroup>
          </FormControl>
        </Paper>
      )}
    </Box>
  );
}

export default CheckboxList;
