import React from 'react';
import { IUserAvailableDevelopmentPlan } from 'redux/interfaces';
import ViewFormLayout from 'components/ViewFormLayout';
import CustomTable from 'components/CustomTable';
import { TRows, TColumns } from 'components/CustomTable/interface';

import { IconButton, Tooltip } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { PROFILE_TEXT } from 'utils/constants';
// eslint-disable-next-line import/extensions
import availableDevelopmentPlan from './availableDevelopmentPlan.json';

export default function AvailableDevelopmentPlan() {
  const columns: TColumns[] = [
    {
      name: 'Entity Type',
      column: 'EntityType',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Goal Category',
      column: 'GoalCategory',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Goal',
      column: 'Goal',
      width: 150,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Metric',
      column: 'Metric',
      width: 120,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Assigned Date',
      column: 'AssignedDate',
      width: 340,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Due Date',
      column: 'DueDate',
      width: 120,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Complete',
      column: 'Complete',
      width: 120,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Performance',
      column: 'Performance',
      width: 120,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Edit',
      column: 'Edit',
      width: 120,
      sortable: false,
      hidden: false,
    },
  ];

  function getEditButton() {
    return (
      <Tooltip title="edit">
        <IconButton onClick={() => {}}>
          <ModeEditOutlineOutlinedIcon
            sx={{ color: (theme) => theme.palette.primary.main }}
          />
        </IconButton>
      </Tooltip>
    );
  }

  const dataSet: TRows[] = (availableDevelopmentPlan || []).map(
    (item: IUserAvailableDevelopmentPlan) => ({
      ...item,
      id: item?.Id,
      isCollapsable: false,
      details: [],
      Edit: getEditButton(),
    }),
  )

  return (
    <ViewFormLayout testId="available-development-plan">
      <ViewFormLayout.Title>{PROFILE_TEXT.AVAILABLE_DEVELOPMENT_PLAN}</ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <CustomTable rows={dataSet} columns={columns} collapseAllRow={false} />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
