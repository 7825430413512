import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { useGetCompanyDetailsQuery, useGetLoginUserMutation } from 'services/authApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isStringEmptyOrInvalid } from 'utils/helpers';
import { IState } from 'redux/interfaces';
import LoadingBox from 'components/LoadingBox';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { webApiClientId } = useParams();

  const {
    companyImageLocation,
    companyName,
    primaryColor,
    companyBackgroundImageURL,
  } = useSelector((state: IState) => state.userProfile);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorLogin, setErrorLogin] = useState(false);
  const [GetNewLogin, { isLoading }] = useGetLoginUserMutation();
  const {
    isLoading: isLoadingCompanyDetails,
  } = useGetCompanyDetailsQuery(webApiClientId)

  const isLoadingState = isLoading;

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    try {
      const payload = await GetNewLogin({ clientId: webApiClientId, userId: username, password })

      const userInfo: any = payload;
      if (userInfo.data.accessToken) {
        navigate('/');
      } else {
        setErrorLogin(true);
      }
    } catch (error) {
      setErrorLogin(true);
    }
  };

  if (isLoadingCompanyDetails) {
    return (
      <Container
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingBox />
      </Container>
    )
  }
  return (
    <Container
      maxWidth={false}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: 0,
        backgroundImage: `url(${companyBackgroundImageURL})`,
      }}
    >
      <Box bgcolor={primaryColor}>
        <Container>
          <Box display="inline-block" px={2} py={1}>
            <img style={{ height: '25px' }} src={companyImageLocation} alt="" />
          </Box>
        </Container>
      </Box>
      <Container style={{
        flexGrow: '2',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          height="100%"
        >
          <Box>
            <p className="p-hello">Hello,</p>
            <p className="p-welcome">Welcome to</p>
            <p className="p-welcome">
              {isLoadingState ? 'Loading' : companyName}
            </p>
          </Box>
          <Box sx={{
            borderRadius: 2,
          }}
          >
            <div className="login-wrapper">
              <p className="p-login">Log into the system using your credentials</p>
              <h2>Welcome</h2>
              {isLoadingState ? (
                <LoadingBox />
              ) : (
                <Box component="form" noValidate>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="userName"
                    data-testid="userName"
                    label={t('userName')}
                    name="userName"
                    autoComplete="userName"
                    onChange={(event) => setUsername(event.target.value)}
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('password')}
                    type="password"
                    id="password"
                    data-testid="password"
                    autoComplete="current-password"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  {errorLogin && (
                    <Typography variant="body1" color="error">
                      {t('invalid-user-password')}
                    </Typography>
                  )}
                  {isStringEmptyOrInvalid(webApiClientId) && (
                    <Typography variant="body1" color="error">
                      {t('invalid-user-company-name')}
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={(event) => handleSubmit(event)}
                    sx={{ mt: 3, mb: 2 }}
                    role="button"
                    data-testid="sign-in"
                    aria-label="Sign In"
                    disabled={
                      !username || !password
                      || isStringEmptyOrInvalid(webApiClientId) || isLoadingState
                    }
                  >
                    Sign In
                  </Button>
                  <div className="help">
                    <p>
                      If you are not an existing User and wish to set up an account,
                      <a href="#login">Click here</a>
                    </p>
                    <p>
                      If you forgot your password,
                      <a href="#login">Click here</a>
                      , we can send it to you automatically
                    </p>
                  </div>
                </Box>
              )}
            </div>

          </Box>
        </Box>
      </Container>
      <Box bgcolor={primaryColor}>
        <Container>
          <Box px={2} py={0}>
            <p style={{ textAlign: 'center' }}>
              &copy;
              {` ${(new Date()).getFullYear()}  Learnsoft Technology Group. All rights reserved.`}
            </p>
          </Box>
        </Container>
      </Box>
    </Container>
  );
}

export default Login;
