import React from 'react'
import { TColumns, TRows } from 'components/CustomTable/interface';
import CustomTable from 'components/CustomTable'
import ViewFormLayout from 'components/ViewFormLayout'
import { IInstances } from 'redux/interfaces';

export default function Instances(
  { instances }: { instances: IInstances[]},
) {
  const columns: TColumns[] = [
    {
      name: 'Id',
      column: 'id',
      sortable: false,
      hidden: false,
      width: 120,
    },
    {
      name: 'Instance Name',
      column: 'instanceName',
      sortable: false,
      hidden: false,
      width: 600,
    }, {
      name: 'Passing Score',
      column: 'passingScore',
      sortable: false,
      hidden: false,
      width: 120,
    },
    {
      name: 'Randomize',
      column: 'randomize',
      sortable: false,
      hidden: false,
      width: 120,
    },
  ];

  const dataSet: TRows[] = instances?.map(
    (inst: IInstances, idx: number) => ({
      ...inst,
      id: idx + 1,
      randomize: inst?.randomize ? 'Yes' : 'No',
      isCollapsable: false,
      details: [],
    }),
  );

  return (
    <ViewFormLayout testId="instance-table" height="50vh">
      <ViewFormLayout.Body>
        <CustomTable
          rows={dataSet}
          columns={columns}
          collapseAllRow={false}
          showColumnsControl={false}
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
