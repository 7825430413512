import React from 'react';
import ViewFormLayout from 'components/ViewFormLayout';
import FormView, { IFormItem } from 'components/FormView';
import CustomTable from 'components/CustomTable';
import { TColumns, TRows } from 'components/CustomTable/interface';

// eslint-disable-next-line import/extensions
import evaluation from './evaluation.json';
// eslint-disable-next-line import/extensions
import availableEvaluationQuestion from './availableEvaluationQuestion.json';

export default function EvaluationDetails() {
  const evaluationInfo: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: evaluation?.EntityType,
      fieldId: 'EntityType',
      label: 'Entity Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: evaluation?.EvaluationName,
      fieldId: 'EvaluationName',
      label: 'Evaluation Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: evaluation?.Header,
      fieldId: 'Header',
      label: 'Header',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: evaluation?.ShowHeader ? 'true' : undefined,
      fieldId: 'ShowHeader',
      label: 'Show Header',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: evaluation?.ShowGeneralComments ? 'true' : undefined,
      fieldId: 'ShowGeneralComments',
      label: 'Show General Comments',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'checkbox',
      options: undefined,
      fieldValue: evaluation?.ShowExerciseComments ? 'true' : undefined,
      fieldId: 'ShowExerciseComments',
      label: 'Show Exercise Comments',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'checkbox',
      options: undefined,
      fieldValue: evaluation?.ShowInstructorComments ? 'true' : undefined,
      fieldId: 'ShowInstructorComments',
      label: 'Show Instructor Comments',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'checkbox',
      options: undefined,
      fieldValue: evaluation?.UserEvaluation ? 'true' : undefined,
      fieldId: 'UserEvaluation',
      label: 'User Evaluation',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'checkbox',
      options: undefined,
      fieldValue: evaluation?.ManagerEvaluation ? 'true' : undefined,
      fieldId: 'ManagerEvaluation',
      label: 'Manager Evaluation',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'checkbox',
      options: undefined,
      fieldValue: evaluation?.SubordinateEvaluation ? 'true' : undefined,
      fieldId: 'SubordinateEvaluation',
      label: 'Subordinate Evaluation',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'checkbox',
      options: undefined,
      fieldValue: evaluation?.PeerEvaluation ? 'true' : undefined,
      fieldId: 'PeerEvaluation',
      label: 'Peer Evaluation',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: evaluation?.Compensation,
      fieldId: 'Compensation',
      label: 'Compensation',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'checkbox',
      options: undefined,
      fieldValue: evaluation?.UseTemplate ? 'true' : undefined,
      fieldId: 'UseTemplate',
      label: 'Use Template',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'multiSelect',
      options: [
        { key: 'poor', value: 'Poor' },
        { key: 'fair', value: 'Fair' },
        { key: 'average', value: 'average' },
        { key: 'good', value: 'Good' },
        { key: 'excellent', value: 'Excellent' },
      ],
      fieldValue: evaluation?.CourseRating,
      fieldId: 'CourseRating',
      label: 'Course Rating',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'multiSelect',
      options: [
        { key: 'poor', value: 'Poor' },
        { key: 'fair', value: 'Fair' },
        { key: 'average', value: 'average' },
        { key: 'good', value: 'Good' },
        { key: 'excellent', value: 'Excellent' },
      ],
      fieldValue: [],
      fieldId: 'Instructor Rating',
      label: 'Instructor Rating',
      titleSize: 3,
    },
  ]

  const templateInformation: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: evaluation?.Template,
      fieldId: 'Template',
      label: 'Template',
      titleSize: 3,
    },
  ];

  const internalInformation: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: evaluation?.CreatedBy,
      fieldId: 'CreatedBy',
      label: 'Created By',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: evaluation?.CreatedDate,
      fieldId: 'CreatedDate',
      label: 'Created Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: evaluation?.UpdatedBy,
      fieldId: 'UpdatedBy',
      label: 'Updated By',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: evaluation?.UpdatedDate,
      fieldId: 'UpdatedDate',
      label: 'Updated Date',
      titleSize: 3,
    },
  ]

  const columns: TColumns[] = [
    {
      name: 'ID',
      column: 'ID',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Name',
      column: 'Name',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Group',
      column: 'Group',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Display Order',
      column: 'DisplayOrder',
      width: 150,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Type',
      column: 'Type',
      width: 120,
      sortable: false,
      hidden: false,
    },
  ];

  const dataSet: TRows[] = (availableEvaluationQuestion || []).map(
    (evaluation: any) => ({
      ...evaluation,
      id: evaluation.Id,
      isCollapsable: false,
      details: [],
    }),
  );

  return (
    <>
      <ViewFormLayout testId="student-information">
        <ViewFormLayout.Title>
          Evaluation
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={evaluationInfo}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>

      <ViewFormLayout testId="available-evaluation">
        <ViewFormLayout.Title>Available Evaluation Question</ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <CustomTable rows={dataSet} columns={columns} collapseAllRow={false} />
        </ViewFormLayout.Body>
      </ViewFormLayout>

      <ViewFormLayout testId="internal-information">
        <ViewFormLayout.Title>
          Template
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={templateInformation}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>

      <ViewFormLayout testId="internal-information">
        <ViewFormLayout.Title>
          Internal Information
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={internalInformation}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
    </>
  )
}
