/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Box, Button, Grid, InputAdornment, Stack, TextField, Tooltip,
  Typography,
} from '@mui/material';
import FormView, { customSelectOption, IFormItem } from 'components/FormView';
import {
  useCreateNewUserGroupMutation,
  useGetDivisionsTreeItemsQuery, useGetEmploymentTypeQuery, useGetGroupsByFilterMutation, useGetUserProfileDetailQuery,
} from 'services/dataApi';
import {
  DivisionTreeItemsResponse, EmploymentType, GetGroupResponse, GetGroupsRequest, GroupResponse,
  UserGroupBody, JobPositionResponse,
} from 'services/interfaces';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { enqueueSnackbar } from 'notistack';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Folder } from '@mui/icons-material';
import { handleErrorMessage } from 'utils/helpers';
import { IFormValue } from 'utils/interfaces';
import { setNavigationPage } from 'redux/userProfileSlice';
import { TreeNode } from 'primereact/treenode';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JobPositionsCatalog from 'modules/SearchableCatalogs/JobPositionsCatalog';
import LoadingIcon from 'components/LoadingIcon';
import SearchIcon from '@mui/icons-material/Search';
import ViewFormLayout from 'components/ViewFormLayout';

function transformItemsToOptionsDivisions(items: DivisionTreeItemsResponse[]): TreeNode[] {
  return items.map((item) => ({
    key: item.divisionId,
    label: item.divisionName,
    icon: <Folder color="primary" />,
    children: item.divisions ? transformItemsToOptionsDivisions(item.divisions) : [],
  }))
}

function getOptionsDivisions(data: DivisionTreeItemsResponse | undefined) {
  if (data === undefined) return []
  return transformItemsToOptionsDivisions([data])
}
interface IFormResponse {
  id: string;
  value: any;
}

function extractValues(
  informationArray: IFormResponse[],
): any {
  const initialUserDetails: { [key: string]: string | number } = {}

  informationArray.forEach((curr) => {
    initialUserDetails[curr?.id] = curr?.value
  });
  const finalUserDetails: any = {
    ...initialUserDetails,
  };

  return finalUserDetails;
}

interface UserNewGroupProps {
  userId: string;
}

export default function UserAddNewGroup({ userId }: UserNewGroupProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: user, isLoading: isLoadingUserData,
  } = useGetUserProfileDetailQuery({ userId });
  const { data: dataDivisions, isLoading: isLoadingDivision } = useGetDivisionsTreeItemsQuery();
  const { data: employmentType, isLoading: isLoadingEmployment } = useGetEmploymentTypeQuery();
  const [getGroupsByFilter, { isLoading: isLoadingGroups }] = useGetGroupsByFilterMutation();
  const [createNewUserGroup, { isLoading: isLoadingNewGroup }] = useCreateNewUserGroupMutation();

  const optionsDivisions = getOptionsDivisions(dataDivisions)
  const [treeIdsDivision, setTreeIdsDivision] = useState<number[]>([])
  const [groups, setGroups] = useState<customSelectOption[]>([])

  const [formValue, setFormValue] = useState<IFormValue[]>([]);
  const [jobPosition, setJobPosition] = useState<JobPositionResponse[]>([]);
  const [open, setOpen] = useState(false);

  const isLoadingState = isLoadingUserData || isLoadingDivision || isLoadingGroups || isLoadingEmployment || isLoadingNewGroup;

  const handleSubmit = async () => {
    try {
      const values = extractValues(formValue);
      // validate mandatories
      const errorList: string[] = [];

      if (!values?.groupId) {
        errorList.push('The field Group is required!')
      }
      if (!treeIdsDivision.length) {
        errorList.push('The field Division is required!')
      }

      const body: UserGroupBody = {
        userId,
        groupId: values?.groupId,
        divisionId: treeIdsDivision[0],
        recursive: values?.recursive,
        active: values?.active,
        isPrimaryDivision: values?.isPrimaryJobPosition,
        isPrimaryJobPosition: values?.isPrimaryJobPosition, // busisness rule has tu be the same
        jobPositionId: jobPosition[0].jobPositionId,
        employmentTypeId: values?.employmentTypeId,
        dateHired: values?.dateHired,
      }
      if (errorList.length === 0) {
        await createNewUserGroup(body).unwrap();
        enqueueSnackbar('Group Saved Successfully', { variant: 'success' });
        navigate(-1)
      } else {
        errorList?.forEach((error) => enqueueSnackbar(error, { variant: 'error' }))
      }
    } catch (error: any) {
      const message = handleErrorMessage(error as FetchBaseQueryError)
      enqueueSnackbar(message, { variant: 'error' });
    }
  }

  const handleFormResponse = (obj: { id: string; value: any }) => {
    setFormValue((_values) => {
      const existingIndex = _values?.findIndex((item) => item.id === obj.id);
      return existingIndex !== -1
        ? _values?.map((item, index) => (index === existingIndex ? obj : item))
        : [..._values, obj];
    })
  };

  const fetchGroupData = async () => {
    try {
      const body: GetGroupsRequest = {
        groupId: null,
        name: null,
        PageNumber: 1,
        PageSize: 999,
        SortingBy: 'groupId',
        Ascending: true,
      }
      const response: GetGroupResponse = await getGroupsByFilter(body).unwrap();
      const group = response?.data?.map(({ groupId, description, name }: GroupResponse) => ({
        value: `${name} - ${description}`,
        key: groupId,
      }))
      setGroups(group)
    } catch (error) {
      console.log({ error })
    }
  };

  const NewUserGroupFields: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'select',
      options: groups,
      fieldValue: '',
      fieldId: 'groupId',
      label: 'Group',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: undefined,
      fieldId: 'active',
      label: 'Active',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: employmentType?.map((emT: EmploymentType) => ({ key: emT.id, value: emT.name })),
      fieldValue: '',
      fieldId: 'employmentTypeId',
      label: 'Employment Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: undefined,
      fieldId: 'isPrimaryJobPosition',
      label: 'Primary Position/Division',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'dateHired',
      label: 'Hire Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'treeSelect',
      treeOptions: optionsDivisions,
      handleMultiSelectOptions: setTreeIdsDivision,
      label: 'Divisions',
      titleSize: 3,
      fieldValue: '',
      fieldId: 'divisionId',
    },
  ];

  useEffect(() => {
    fetchGroupData();
  }, []);

  useEffect(() => {
    if (user?.userName) dispatch(setNavigationPage(`Add New Group to ${user?.firstName} ${user?.lastName} (${user?.userName})`))
  }, [user]);

  if (isLoadingState) {
    return (
      <Stack p={2}>
        <LoadingIcon />
      </Stack>
    )
  }

  return (
    <>
      <ViewFormLayout pl={0} pr={0} testId="users-list">
        <ViewFormLayout.ActionBar>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => navigate(-1)}>
              {BUTTON_TEXT.BACK}
            </Button>
          </Tooltip>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
            <Button variant="contained" onClick={handleSubmit}>
              {BUTTON_TEXT.SAVE}
            </Button>
          </Tooltip>
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Body>
          <Box
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              pl: 2,
              pb: 2,
            }}
          >
            <FormView
              title="New User Group"
              style={{ paddingLeft: 1, paddingRight: 1 }}
              fields={NewUserGroupFields}
              onChangeValues={(obj: { id: string; value: any }) => handleFormResponse(obj)}
            />
            <Grid container>
              <Grid
                item
                xs={3}
                bgcolor={(theme) => theme.palette.grey[100]}
                className="center-vertical"
                sx={{
                  justifyContent: 'right', borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`, pl: 1, pr: 1,
                }}
              >
                <Typography>Job Position</Typography>
              </Grid>
              <Grid item xs={9} p={1} className="center-vertical">
                <TextField
                  fullWidth
                  id="input-with-icon-textfield"
                  value={jobPosition.map((job) => job?.jobPositionDescription).join('; ')}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </Box>
        </ViewFormLayout.Body>
      </ViewFormLayout>
      <JobPositionsCatalog
        open={open}
        onClose={() => setOpen(false)}
        onCallback={(selected: any) => {
          setJobPosition(selected)
          setOpen(false)
        }}
      />
    </>

  );
}
