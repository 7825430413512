import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import Layout from 'components/Layout';
import 'App.scss';
import { Button, Tooltip } from '@mui/material';
import Widget from 'components/Widget';
import YellowfinWidget from 'modules/YellowfinWidget';
import { useNavigate, useParams } from 'react-router-dom';
import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';

function Report() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    dispatch(setNavigationPage(`Report - ${params.reportName}`))
  }, []);

  return (
    <Layout>
      <ViewFormLayout testId="report">
        <ViewFormLayout.ActionBar>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => navigate(-1)}>{BUTTON_TEXT.BACK}</Button>
          </Tooltip>
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Body>
          <Widget columns={12} onMdColumns={5} height="1650px">
            <YellowfinWidget />
          </Widget>
        </ViewFormLayout.Body>
      </ViewFormLayout>
    </Layout>

  );
}

export default Report;
