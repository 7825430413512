import React, { useRef, useState } from 'react';
import {
  Box, TextField, Button, Container, Typography, Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

function CourseFilterForm({ onSearch, onReset }: { onSearch: Function, onReset: Function }) {
  const [isActive, setIsActive] = useState(true);

  const formRef = useRef<HTMLFormElement | null>(null);
  const learningObjectId = useRef<HTMLInputElement | null>(null);
  const courseNumber = useRef<HTMLInputElement | null>(null);
  const learningObjectName = useRef<HTMLInputElement | null>(null);
  const learningObjectDescription = useRef<HTMLInputElement | null>(null);
  const wbtTypeId = useRef<HTMLInputElement | null>(null);
  const ownerUserId = useRef<HTMLInputElement | null>(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = {
      LearningObjectId: learningObjectId?.current?.value || null,
      IsCourseActive: isActive,
      CourseNumber: courseNumber?.current?.value || null,
      LearningObjectName: learningObjectName?.current?.value || null,
      LearningObjectDescription: learningObjectDescription?.current?.value || null,
      WBTTypeId: wbtTypeId?.current?.value || null,
      OwnerUserId: ownerUserId?.current?.value || null,
    };
    scrollToTop()
    onSearch(formData);
  };

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    setIsActive(true);
    scrollToTop()
    onReset();
  };

  return (
    <Container maxWidth="lg" sx={{ p: 2 }}>
      <Box component="form" onSubmit={handleSubmit} ref={formRef}>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Course Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Learning Object ID" name="LearningObjectId" inputRef={learningObjectId} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Course Name" name="CourseNumber" inputRef={courseNumber} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Learning Object Name" name="LearningObjectName" inputRef={learningObjectName} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Learning Object Description" name="LearningObjectDescription" inputRef={learningObjectDescription} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="WBT Type ID" name="WBTTypeId" inputRef={wbtTypeId} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Owner User ID" name="OwnerUserId" inputRef={ownerUserId} />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Course Status
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  name="active"
                />
              )}
              label="Active"
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }} display="flex" flexDirection="row-reverse" gap={1}>
          <Button type="submit" variant="contained" color="primary">
            Search
          </Button>
          <Button onClick={handleReset}>
            Reset
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default CourseFilterForm;
