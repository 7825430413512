import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface BadgeInfoProps {
    size?: number;
    color?: string;
    value: number;
  }

export default function BadgeInfo({ size = 22, color, value }: BadgeInfoProps) {
  const theme = useTheme();
  const bgColor = color || theme.palette.primary.main
  return (
    <Box
      sx={{
        width: size,
        height: size,
        ml: 1,
        p: 1,
        borderRadius: '50%',
        backgroundColor: bgColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
      }}
    >
      <Typography color="white" variant="caption" sx={{ fontWeight: 600, lineHeight: 0 }}>{value}</Typography>
    </Box>
  );
}
