/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {
  BaseQueryApi,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { CourseDetailsResponse, IState } from 'redux/interfaces';
import {

} from 'utils/constants'
import {
  ChangePasswordRequest,
  CourseDetailByIdRequest,
  EnrollCoursesRequest,
  EnrollCoursesResponse,
  GetCountryResponse,
  GetSchoolResponse,
  GetAllCoursesByUserIdRequest,
  GetAllCoursesByUserIdResponse,
  GroupCoursesDetailsResponse,
  UnenrollCoursesRequest,
  UnenrollCoursesResponse,
  UpdateStatusLearningObject,
  UserDetailRequest,
  UserDetailResponse,
  UserStatsResponse,
  GetEmploymentTypesResponse,
  GetSpecialtyTypesResponse,
  GetDivisionsResponse,
  TestRequest,
  TestResponse,
  EvaluationResponse,
  EvaluationRequest,
  SubmitEvaluationRequest,
  UpdateUserProfileRequest,
  SubmitTestRequest,
  CatalogTreeItemsResponse,
  ScormTestRequest,
  ScormRegisterUserRequest,
  DivisionTreeItemsResponse,
  UserManagementRequest,
  UserManagementResponse,
  GroupsManagementResponse,
  GroupsManagementRequest,
  CourseManagementRequest,
  CourseManagementResponse,
  YellowFinReportsResponse,
  CourseInstancesRequest,
  CourseInstance,
  DivisionTreeView,
  GetGroupResponse,
  GetGroupsRequest,
  GetJobPositionResponse,
  GetJobPositionRequest,
  UserGroupBody,
} from './interfaces';
import handleUnauthorizedResponse from './serviceHelper';

type CoursesFilter = {
  userId: string,
  loSearchType: string,
  learningObjectName: string,
  learningObjectTreeIds?: number[]
};

const baseUrlApi = process.env.REACT_APP_NEXTGEN_BASE_URL;

export const dataApi = createApi({
  reducerPath: 'dataJsonServerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlApi,
    prepareHeaders: (headers: Headers, api: Pick<
      BaseQueryApi,
      'getState' | 'extra' | 'endpoint' | 'type' | 'forced'
    >) => {
      const getState = api?.getState() as IState;
      const { accessToken } = getState.userProfile || null;

      // If we have a token set in state, let's assume that we should be passing it.
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
    validateStatus: (response: Response, body: any): boolean => {
      if (response.status === 401) {
        return handleUnauthorizedResponse()
      }
      return true;
    },
  }),
  endpoints: (builder) => ({
    getLOSDetails: builder.query({
      query: (userId) => ({
        url: 'api/LOSessions/GetLOSDetails',
        method: 'POST',
        body: {
          userId,
          EmployeeID: null,
          UserName: null,
          learningObjectId: null,
          learningObjectInstanceId: null,
          LearningObjectSessionId: null,
          learningObjectName: null,
          LearningObjectTypeName: null,
          LOSType: 'AllEnrolled',
          ManageruserId: null,
        },
      }),
    }),

    getCountries: builder.query<GetCountryResponse, void>({
      query: () => ({
        url: '/api/catalog/countries',
        method: 'GET',
      }),
    }),

    getSchools: builder.query<GetSchoolResponse, void>({
      query: () => ({
        url: '/api/catalog/schools',
        method: 'GET',
      }),
    }),

    getEmploymentType: builder.query<GetEmploymentTypesResponse, void>({
      query: () => ({
        url: '/api/catalog/employment-types',
        method: 'GET',
      }),
    }),

    getSpecialtyTypes: builder.query<GetSpecialtyTypesResponse, void>({
      query: () => ({
        url: '/api/catalog/specialty-types',
        method: 'GET',
      }),
    }),

    getDivisions: builder.query<GetDivisionsResponse, void>({
      query: () => ({
        url: '/api/catalog/divisions',
        method: 'GET',
      }),
    }),

    getAllCoursesByUserId: builder.mutation<GetAllCoursesByUserIdResponse, GetAllCoursesByUserIdRequest>({
      query: ({ userId, learningProcessStatus = [] }) => ({
        url: 'api/dashboard/courses',
        method: 'POST',
        body: {
          userId,
          learningProcessStatus,
        },
      }),
    }),

    getUserDetail: builder.query<UserDetailResponse, UserDetailRequest>({
      query: ({ userId }) => ({
        url: `api/users/${userId}/details`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),

    getUserProfileDetail: builder.query<UserDetailResponse, UserDetailRequest>({
      query: ({ userId }) => ({
        url: `api/users/${userId}/details`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),

    getUserStats: builder.query<UserStatsResponse, UserDetailRequest>({
      query: ({ userId }) => ({
        url: `api/users/${userId}/stats`,
        method: 'GET',
      }),
    }),

    getYellowFinToken: builder.query({
      query: (clientOrgRef: string) => ({
        url: 'api/integrations/yellowfin/reporting-token',
        method: 'GET',
        params: {
          clientOrgRef,
        },
      }),
      keepUnusedDataFor: 0.01,
    }),

    getUserAssignments: builder.query({
      query: (userId: string) => ({
        url: 'api/LOSessions/GetUserAssignments',
        method: 'GET',
        params: {
          id: userId,
        },
      }),
    }),

    getUserCertifications: builder.query({
      query: (userId: string) => ({
        url: 'api/LOSessions/GetUserCertifications',
        method: 'GET',
        params: {
          id: userId,
        },
      }),
    }),

    searchCourses: builder.mutation({
      query: (args: CoursesFilter) => ({
        url: 'api/learning-object/courses/search',
        method: 'POST',
        body: {
          ...args,
        },
      }),
    }),

    getAllInstructors: builder.query({
      query: () => ({
        url: 'api/catalog/instructors',
        method: 'GET',
      }),
    }),

    getAllFacility: builder.query({
      query: () => ({
        url: 'api/catalog/facilities',
        method: 'GET',
      }),
    }),

    getAllFacilityRooms: builder.query({
      query: () => ({
        url: 'api/catalog/facility-rooms',
        method: 'GET',
      }),
    }),

    getVendors: builder.query({
      query: () => ({
        url: 'api/catalog/vendors',
        method: 'GET',
      }),
    }),
    getTopic: builder.query({
      query: () => ({
        url: 'api/catalog/active-topics',
        method: 'GET',
      }),
    }),
    getAccreditations: builder.query({
      query: () => ({
        url: 'api/catalog/accreditations',
        method: 'GET',
      }),
    }),
    getJobPosition: builder.query({
      query: () => ({
        url: 'api/users/job-position',
        method: 'GET',
      }),
    }),

    getCertificationCourses: builder.query({
      query: ({ objectId }) => ({
        url: `api/learning-object/certifications/${objectId}/courses`,
        method: 'GET',
      }),
    }),

    enrollCertificationCourse: builder.mutation({
      query: (options) => ({
        url: '/api/LOSessions/ProcessCertificationEnrollment',
        method: 'POST',
        body: {
          UserId: options.getUserId,
          ...options,
        },
      }),
    }),
    enrollCourses: builder.mutation<EnrollCoursesResponse[], EnrollCoursesRequest[]>({
      query: (options) => ({
        url: '/api/learning-object/courses/self-enrollment',
        method: 'POST',
        body: options,
      }),
    }),

    unenrollCourse: builder.mutation<UnenrollCoursesResponse, UnenrollCoursesRequest>({
      query: (options) => ({
        url: '/api/learning-object/courses/self-unenrollment',
        method: 'POST',
        body: options,
      }),
    }),

    getCoursesById: builder.query<CourseDetailsResponse, CourseDetailByIdRequest>({
      query: ({ userId, learningInstanceId }) => ({
        url: `/api/dashboard/${userId}/course-details/${learningInstanceId}`,
        method: 'GET',
        responseHandler: async (response: Response) => {
          if (response?.status === 204) {
            return []
          }
          const data = await response.json();
          return data;
        },
      }),
      keepUnusedDataFor: 0.01,
    }),

    getCoursesGroupById: builder.query<GroupCoursesDetailsResponse, CourseDetailByIdRequest>({
      query: ({ userId, learningInstanceId }) => ({
        url: `/api/dashboard/${userId}/course-group-items/${learningInstanceId}`,
        method: 'GET',
      }),
    }),

    changePassword: builder.mutation<void, ChangePasswordRequest>({
      query: ({ userId, newPassword }) => ({
        url: '/api/users/change-password',
        method: 'POST',
        body: {
          userId,
          newPassword,
        },
      }),
    }),

    updateStatusLearningObject: builder.mutation<void, UpdateStatusLearningObject>({
      query: (args) => ({
        url: 'api/learning-object/instance/update-status',
        method: 'POST',
        body: {
          ...args,
        },
      }),
    }),

    getPreTestById: builder.query<TestResponse, TestRequest>({
      query: (args) => ({
        url: `api/test-library/${args?.testId}/session/${args?.learningObjectSessionId}/details`,
        method: 'GET',
      }),
    }),

    submitTest: builder.mutation<void, SubmitTestRequest>({
      query: (args) => ({
        url: 'api/learning-object/session/test/finish',
        method: 'POST',
        body: JSON.stringify(args),
        headers: {
          'Content-Type': 'application/json', // Set content type to JSON
        },
      }),
    }),

    getEvaluations: builder.query<EvaluationResponse, EvaluationRequest>({
      query: (args) => ({
        url: `api/learning-object/session/${args?.sessionId}/evaluation/${args?.evaluationId}/questions`,
        method: 'GET',
      }),
    }),

    submitEvaluation: builder.mutation<void, SubmitEvaluationRequest>({
      query: (args) => ({
        url: `api/learning-object/session/${args?.sessionId}/evaluations`,
        method: 'POST',
        body: JSON.stringify(args?.answers),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),

    updateUserProfile: builder.mutation<void, UpdateUserProfileRequest>({
      query: (args) => ({
        url: 'api/users/my-profile/update',
        method: 'POST',
        body: {
          ...args,
        },
      }),
    }),

    getCatalogTreeItems: builder.query<CatalogTreeItemsResponse, void>({
      query: () => ({
        url: 'api/catalog/tree-items',
        method: 'GET',
      }),
    }),

    getDivisionsTreeItems: builder.query<DivisionTreeItemsResponse, void>({
      query: () => ({
        url: 'api/catalog/divisions/tree',
        method: 'GET',
      }),
    }),

    getDivisionsTreeView: builder.query<DivisionTreeView[], void>({
      query: () => ({
        url: 'api/catalog/divisions/tree-view',
        method: 'GET',
      }),
    }),

    scormRegisterUser: builder.mutation<void, ScormRegisterUserRequest>({
      query: ({ scormId, userId }) => ({
        url: `api/scorm/${scormId}/user/${userId}`,
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),
    }),

    getScormLink: builder.query<string, {registrationId: string}>({
      query: ({ registrationId }) => ({
        url: `api/scorm/registration/${registrationId}/launch-link?redirectionUrl=learnsoft.com/`,
        method: 'GET',
        responseHandler: (response) => response.text(),
      }),
    }),

    createScormCourse: builder.mutation<void, ScormTestRequest>({
      query: ({
        Name,
        CreateCourse,
        WbtType,
        GradingType,
        PassingScore,
        MaxStudents,
        IsCourseActive,
        ScormFile,
        DivisionIds,
        CatalogIds,
        Ownerships,
      }) => {
        const formData = new FormData();
        formData.append('scormFile', ScormFile);
        return ({
          url: 'api/scorm',
          method: 'POST',
          params: {
            Name,
            CreateCourse,
            WbtType,
            GradingType,
            PassingScore,
            MaxStudents,
            IsCourseActive,
            DivisionIds,
            CatalogIds,
            Ownerships,
          },
          body: formData,
          formData: true,
        })
      },
    }),

    getUserManagement: builder.mutation<UserManagementResponse, UserManagementRequest>({
      query: ({
        PageNumber, PageSize, SortingBy, ...args
      }) => ({
        url: `api/users-management/users?PageNumber=${PageNumber}&PageSize=${PageSize}&SortingBy=${SortingBy}`,
        method: 'POST',
        body: {
          ...args,
        },
      }),
    }),

    getGroupsManagement: builder.mutation<GroupsManagementResponse, GroupsManagementRequest>({
      query: (args) => ({
        url: `api/groups/search?PageNumber=${args.PageNumber}&PageSize=${args.PageSize}&SortingBy=${args.SortingBy}`,
        method: 'POST',
        body: {
          groupId: args.groupId,
          name: args.name,
        },
      }),
    }),

    getCourseManagement: builder.mutation<CourseManagementResponse[], CourseManagementRequest>({
      query: (args: CourseManagementRequest) => ({
        url: 'api/course-management/search',
        method: 'GET',
        params: {
          ...args,
        },
      }),
    }),

    getYellowFinReports: builder.query<YellowFinReportsResponse, void>({
      query: () => ({
        url: 'api/integrations/yellowfin/reports',
        method: 'GET',
      }),
    }),

    getInstancesFromCourse: builder.query<CourseInstance[], CourseInstancesRequest>({
      query: (params) => ({
        url: `api/course-management/${params.learningObjectId}/instances`,
        method: 'GET',
      }),
    }),

    getGroupsByFilter: builder.mutation<GetGroupResponse, GetGroupsRequest>({
      query: ({
        groupId = null, name = null, PageNumber = 1, PageSize = 10, SortingBy, Ascending = true,
      }) => ({
        url: `/api/groups/search?PageNumber=${PageNumber}&PageSize=${PageSize}&SortingBy=${SortingBy}&Ascending=${Ascending}`,
        method: 'POST',
        body: {
          groupId,
          name,
        },
      }),
    }),

    getJobPositionsByFilter: builder.mutation<GetJobPositionResponse, GetJobPositionRequest>({
      query: ({
        jobPositionId = null, jobPosition = null, jobPositionDescription = null, PageNumber = 1, PageSize = 10, SortingBy, Ascending = true,
      }) => ({
        url: `/api/users-management/job-positions?PageNumber=${PageNumber}&PageSize=${PageSize}&SortingBy=${SortingBy}&Ascending=${Ascending}`,
        method: 'POST',
        body: {
          jobPositionId,
          jobPosition,
          jobPositionDescription,
        },
      }),
    }),

    createNewUserGroup: builder.mutation<void, UserGroupBody>({
      query: (args) => ({
        url: '/api/groups',
        method: 'POST',
        body: args,
      }),
    }),
  }),
});

export const {
  useGetSchoolsQuery,
  useGetCountriesQuery,
  useGetEmploymentTypeQuery,
  useGetSpecialtyTypesQuery,
  useGetDivisionsQuery,
  useGetLOSDetailsQuery,
  useGetUserAssignmentsQuery,
  useGetUserCertificationsQuery,
  useSearchCoursesMutation,
  useGetAllInstructorsQuery,
  useGetAllFacilityQuery,
  useGetAllFacilityRoomsQuery,
  useGetVendorsQuery,
  useGetTopicQuery,
  useGetAccreditationsQuery,
  useGetJobPositionQuery,
  useGetCertificationCoursesQuery,
  useEnrollCertificationCourseMutation,
  useEnrollCoursesMutation,
  useUnenrollCourseMutation,
  useGetAllCoursesByUserIdMutation,
  useGetYellowFinTokenQuery,
  useGetUserDetailQuery,
  useGetUserStatsQuery,
  useChangePasswordMutation,
  useGetCoursesByIdQuery,
  useGetCoursesGroupByIdQuery,
  usePrefetch,
  useUpdateStatusLearningObjectMutation,
  useGetPreTestByIdQuery,
  useGetEvaluationsQuery,
  useSubmitEvaluationMutation,
  useUpdateUserProfileMutation,
  useSubmitTestMutation,
  useGetCatalogTreeItemsQuery,
  useCreateScormCourseMutation,
  useScormRegisterUserMutation,
  useGetScormLinkQuery,
  useGetDivisionsTreeItemsQuery,
  useGetDivisionsTreeViewQuery,
  useGetUserManagementMutation,
  useGetUserProfileDetailQuery,
  useGetGroupsManagementMutation,
  useGetCourseManagementMutation,
  useGetYellowFinReportsQuery,
  useGetInstancesFromCourseQuery,
  useGetGroupsByFilterMutation,
  useGetJobPositionsByFilterMutation,
  useCreateNewUserGroupMutation,
} = dataApi;
