import React from 'react';
import TextField from '@mui/material/TextField';
import { InputLabel, Stack, Typography } from '@mui/material';
import { TestQuestion } from 'services/interfaces';

function TextAreaQuestion({ question, handleResponse }
  : {question: TestQuestion, handleResponse: Function}) {
  return (
    <Stack>
      <InputLabel>
        <Typography variant="body1">{question?.testQuestionText}</Typography>
        <Typography variant="caption">{question.answerSummary}</Typography>
      </InputLabel>
      <TextField
        autoFocus
        fullWidth
        multiline
        id={String(question.testQuestionId)}
        rows={6}
        margin="normal"
        onChange={(e) => handleResponse({ id: e?.target.id, value: e?.target?.value })}
        variant="outlined"
      />
    </Stack>
  );
}

export default TextAreaQuestion;
