import React, { useRef } from 'react';
import {
  Box, TextField, Button, Container, Typography, Grid,
} from '@mui/material';

function JobPositionsFilterForm({ onSearch, onReset }: { onSearch: Function, onReset: Function }) {
  const formRef = useRef<HTMLFormElement | null>(null);
  const jobPositionIdRef = useRef<HTMLFormElement | null>(null);
  const jobPositionRef = useRef<HTMLFormElement | null>(null);
  const jobPositionDescriptionRef = useRef<HTMLFormElement | null>(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = {
      jobPositionId: jobPositionIdRef?.current?.value || null,
      jobPosition: jobPositionRef?.current?.value || null,
      jobPositionDescription: jobPositionDescriptionRef?.current?.value || null,
    };
    scrollToTop()
    onSearch(formData);
  };

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    scrollToTop()
    onReset();
  };

  return (
    <Container maxWidth="lg" sx={{ p: 2 }}>
      <Box component="form" onSubmit={handleSubmit} ref={formRef}>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Job Position Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Job Position ID"
              name="jobPositionId"
              inputRef={jobPositionIdRef}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Job Position"
              name="jobPosition"
              inputRef={jobPositionRef}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Job Position Description"
              name="jobPositionDescription"
              inputRef={jobPositionDescriptionRef}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }} display="flex" flexDirection="row-reverse" gap={1}>
          <Button type="submit" variant="contained" color="primary">
            Search
          </Button>
          <Button onClick={handleReset}>
            Reset
          </Button>
        </Box>
      </Box>
    </Container>

  );
}

export default JobPositionsFilterForm;
