import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material';
import FormView, { IFormItem } from 'components/FormView';
import { IQuestion } from 'redux/interfaces';
import RadioButtonList from './RadioButtonList';
import CheckboxList from './CheckboxList';
import TextBox from './TextBox';
import TextArea from './TextArea';

interface IAnswerForm {
  question?: IQuestion,
  onCallback: Function
}
export default function AnswerForm({ onCallback, question }: IAnswerForm) {
  const [showAnswerForm, setShowAnswerForm] = useState<string>(question?.answerType || '');
  const [rightAnswer, setRightAnswer] = useState<string>(question?.rightAnswer || '');
  const [items, setItems] = useState<string[]>(question?.answerList || []);

  useEffect(() => {
    onCallback({ id: 'answerList', value: items })
  }, [items]);

  const handleFormResponse = (obj: { id: string, value: any }) => {
    if (obj?.id === 'answerType') {
      setShowAnswerForm(obj?.value);
    }
    if (obj?.id === 'rightAnswer') {
      setRightAnswer(obj?.value);
    }
    onCallback(obj)
  };

  const AnswerFormConf: IFormItem[] = [

    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: 'checkbox', value: 'Check Box' },
        { key: 'radioButton', value: 'Radio Button' },
        { key: 'textArea', value: 'Text Area' },
        { key: 'textBox', value: 'Text Box' },
      ],
      fieldValue: question?.answerType,
      fieldId: 'answerType',
      label: 'Answer Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: question?.rightAnswer,
      fieldId: 'rightAnswer',
      label: 'Right answer',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: question?.answerValue,
      fieldId: 'answerValue',
      label: 'Answer Value',
      titleSize: 3,
    },
  ];

  const AnswerForm = {
    radioButton: <RadioButtonList question={question?.question || ''} rightAnswer={rightAnswer} items={items} onCallback={(items: string[]) => setItems(items)} />,
    checkbox: <CheckboxList question={question?.question || ''} rightAnswer={rightAnswer} items={items} onCallback={(items: string[]) => setItems(items)} />,
    textArea: <TextArea question={question?.question || ''} />,
    textBox: <TextBox question={question?.question || ''} />,
  }
  return (
    <Box sx={{
      width: '100%', bgcolor: 'background.paper', pl: 2, pb: 2,
    }}
    >
      <FormView
        title="Awnser"
        style={{ paddingLeft: 1, paddingRight: 1 }}
        fields={AnswerFormConf}
        onChangeValues={(obj: any) => handleFormResponse(obj)}
      />
      <Stack mt={2} gap={2}>
        {AnswerForm[showAnswerForm]}
      </Stack>
    </Box>
  )
}
