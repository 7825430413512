import React from 'react';
import {
  Button,
  Tooltip,
} from '@mui/material';

import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import ReportsTable from './ReportsTable';

export default function Reports() {
  return (
    <ViewFormLayout testId="reports">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.NEW_REPORT}>
          <Button variant="contained">{BUTTON_TEXT.NEW_REPORT}</Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <ReportsTable />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
