import React, { useRef, useState } from 'react';
import {
  Menu,
  MenuItem,
  Avatar,
  Divider,
  Card,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
  Person as ProfileIcon,
  Logout,
  CleaningServices,
  Password as PasswordIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import './TopDropDownMenu.scss';
import { logout } from 'redux/userProfileSlice';
import useInternalStorage from 'hooks/useInternalStorage';
import { removeQuestionLibrary } from 'utils/helpers';

export default function TopDropDownMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [, , removeTestLibraryList] = useInternalStorage('testLibraryList', []);
  const [, , removeQuestionPool] = useInternalStorage('questionPool', []);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { user, admin: isAdmin } = useSelector((state: IState) => state.userProfile);
  const navigateToPage = (page: any) => {
    setAnchorEl(null);
    if (page) {
      navigate(page);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    dispatch(logout())
  };
  const anchorRef = useRef<HTMLDivElement>(null);
  const handleMenu = () => {
    setAnchorEl(anchorRef.current);
  };
  const nameInitials = `${user?.firstName.substring(0, 1).toUpperCase()}${user?.lastName.substring(0, 1).toUpperCase()}`;
  const avatarStyle = {
    width: 30,
    height: 30,
    fontSize: '1rem',
    cursor: 'pointer',
  };
  return (
    <div ref={anchorRef}>
      <Avatar
        onClick={handleMenu}
        className="menu-avatar"
        sx={avatarStyle}
        role="button"
        aria-label="Account"
      >
        {nameInitials}
      </Avatar>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <IconButton
          onClick={handleClose}
          sx={{ marginLeft: '80%' }}
          className="menu-icon"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="subtitle2" sx={{ marginLeft: 2 }}>
          ACCOUNT
        </Typography>
        <Card sx={{ border: 0, boxShadow: 'none' }}>
          <CardHeader
            avatar={(
              <Avatar
                aria-label="Account"
                className="menu-avatar"
                sx={{ ...avatarStyle, background: '#232323' }}
              >
                {nameInitials}
              </Avatar>
            )}
            title={`${user?.firstName} ${user?.middleName || ''} ${user?.lastName}`}
            subheader={user?.email}
          />
        </Card>
        <MenuItem
          onClick={() => {
            navigateToPage('/profile');
          }}
          className="menu-item-color"
          role="button"
          aria-label="My Profile"
        >
          <ProfileIcon
            sx={{ color: '#232323' }}
            className="menu-icon"
          />
          My Profile
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            navigateToPage('/change-password');
          }}
          className="menu-item-color"
          role="button"
          aria-label="Change Password"
        >
          <PasswordIcon
            sx={{ color: '#232323' }}
            className="menu-icon"
          />
          Change Password
        </MenuItem>
        {isAdmin && (
        <MenuItem
          onClick={() => {
            removeQuestionPool();
            removeTestLibraryList();
            removeQuestionLibrary();
            setAnchorEl(null);
          }}
          className="menu-item-color"
        >
          <CleaningServices
            sx={{ color: '#232323' }}
            className="menu-icon"
          />
          Reset Storage
        </MenuItem>
        )}
        <MenuItem
          onClick={handleLogOut}
          className="menu-item-color"
        >
          <Logout
            sx={{ color: '#232323' }}
            className="menu-icon"
          />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
