import React from 'react';
import Layout from 'components/Layout';
import { useParams } from 'react-router-dom';
import UserAddNewGroup from 'modules/administrator/AdminUserProfile/UserAddNewGroup';
import { Typography } from '@mui/material';

function AdminUserAddGroupPage() {
  const { userId } = useParams();

  if (!userId) {
    return (
      <Layout>
        <Typography>No user selected!</Typography>
      </Layout>
    )
  }
  return (
    <Layout>
      <UserAddNewGroup userId={userId} />
    </Layout>
  );
}

export default AdminUserAddGroupPage;
