/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { COURSE_TYPE_ID, ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import { formatDate } from 'utils/helpers';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { useNavigate } from 'react-router-dom';
import CustomTable from 'components/CustomTable';
import getType from 'components/TypeIcon';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { CourseDetails, GetAllCoursesByUserIdResponse } from 'services/interfaces';

export default function MyPastCourses({ dataCourses } : {dataCourses: GetAllCoursesByUserIdResponse}) {
  const navigate = useNavigate();
  const [dataSet, setDataSet] = useState<TRows[] | []>([]);

  function getMoreInfoButton(learningInstanceId: number, url: string) {
    return (
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.MORE_INFO}>
        <IconButton onClick={() => navigate(`${url}/${learningInstanceId}`)}>
          <InfoIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    )
  }

  function createBasicData(item: CourseDetails): TRows {
    let detailsURL = ''
    if (item.learningTypeName === 'Certification') {
      detailsURL = '/dashboard/certification/detail'
    } else if (item.learningTypeName === 'Assignment') {
      detailsURL = '/dashboard/assignment/detail'
    }

    return {
      id: item?.learningInstanceId,
      courseTypeIcon: getType({ type: item.learningTypeId }),
      moreInfoIcon: getMoreInfoButton(item?.learningInstanceId, detailsURL),
      courseType: COURSE_TYPE_ID[item.learningTypeId],
      courseName: item.learningName,
      enrollmentType: item.learningProcessStatusTypeName,
      status: item.learningProcessStatusTypeName,
      dueDate: item.dueDate ? formatDate(String(item.dueDate)) : '',
      startDate: item.startDate ? formatDate(String(item.startDate)) : '',
      moreInfo: getMoreInfoButton(item?.learningInstanceId, '/dashboard/course/detail'),
      isCollapsable: item.isCollapsable,
      details: [],
    };
  }

  function getValidTimestamps(dates: (string | undefined)[]) {
    return dates
      .filter((date) => date !== undefined)
      .map((date) => new Date(date!).getTime());
  }

  function createData(item: CourseDetails): any {
    if (item.isCollapsable) {
      const basicData = createBasicData(item);
      const details = [...item.learningDetails || []];

      const validStartDates = getValidTimestamps(details.map((item) => item.startDate));
      const validDueDates = getValidTimestamps(details.map((item) => item.dueDate));

      const minStartDate = validStartDates.length ? Math.min(...validStartDates) : null;
      const maxDueDate = validDueDates.length ? Math.max(...validDueDates) : null;
      const detail = details?.map((_item: any) => createBasicData(_item)) || [];
      const startDate = minStartDate ? formatDate(String(new Date(minStartDate))) : null;
      const dueDate = maxDueDate ? formatDate(String(new Date(maxDueDate))) : null;

      let name = item.learningName;
      if (startDate && dueDate) {
        name = `${item.learningName} (${startDate} to ${dueDate})`;
      }

      return {
        ...basicData,
        courseName: name,
        startDate,
        dueDate,
        details: [
          ...detail,
        ],
      }
    }
    return createBasicData(item);
  }

  useEffect(() => {
    if (dataCourses?.length > 0) {
      const tableDataByType = dataCourses;

      const tableData = tableDataByType.length > 0
        ? tableDataByType?.map((item: CourseDetails) => createData(item)) : [];
      setDataSet(tableData)
    }
  }, [dataCourses])

  const columns: TColumns[] = [
    {
      name: 'Course Type',
      column: 'courseTypeIcon',
      width: 10,
      alignTitle: 'left',
      sortable: false,
      hidden: false,
      searchable: true,
      filter: true,
      filterLabel: 'Course Type',
    },
    {
      name: 'Course Name',
      column: 'courseName',
      width: 300,
      alignTitle: 'left',
      sortable: true,
      hidden: false,
      searchable: true,
    },
    {
      name: 'Status',
      column: 'status',
      width: 100,
      align: 'left',
      alignTitle: 'left',
      sortable: false,
      hidden: false,
      filter: true,
      filterLabel: 'Status',
    },
    {
      name: 'Start Date',
      column: 'startDate',
      width: 100,
      align: 'left',
      alignTitle: 'left',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Due Date',
      column: 'dueDate',
      width: 100,
      align: 'left',
      alignTitle: 'left',
      sortable: true,
      hidden: false,
    },

    {
      name: 'More Info',
      column: 'moreInfo',
      width: 50,
      alignTitle: 'left',
      sortable: false,
      hidden: false,
    },
    {
      name: 'Actions',
      column: 'actions',
      width: 100,
      alignTitle: 'left',
      sortable: false,
      hidden: false,
    },
  ];

  return (
    <CustomTable
      collapseAllRow={false}
      colSpanGroupTitle={4}
      columns={columns}
      defaultSort="dueDate"
      rows={dataSet}
      showColumnsControl={false}
      showSearchBar
    />
  )
}
