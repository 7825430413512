import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import AdminTestLibraries from 'modules/administrator/AdminTestLibraries';
import Layout from 'components/Layout';

export default function AdminTestLibrariesPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Test libraries list'))
  }, []);

  return (
    <Layout>
      <AdminTestLibraries />
    </Layout>
  );
}
