import React from 'react';
import { Box, FormControlLabel, Radio } from '@mui/material';

interface IOptionsProps{
  option: string;
}

export default function Option({ option }: IOptionsProps) {
  return (
    <Box>
      <FormControlLabel
        value={option}
        control={<Radio size="small" />}
        label=""
      />
    </Box>
  )
}
