import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import '../App.scss';
import {
  Grid,
  Tabs,
  Tab,
  AppBar,
  Box,
  Typography,
} from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import TrendingUp from '@mui/icons-material/TrendingUp';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {
    children, value, index,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ minHeight: 700 }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function SuccessionPlanning() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setNavigationPage('Succession Planning'))
  }, []);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Grid
        container
        spacing={1}
      >
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <AppBar position="static" sx={{ bgcolor: 'background.paper' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
            >
              <Tab icon={<InsightsIcon />} iconPosition="start" label="Competency Analysis" />
              <Tab icon={<TrendingUp />} iconPosition="start" label="Succession" />
            </Tabs>
          </AppBar>
          <CustomTabPanel value={value} index={0}>
            Competency Analysis
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            Succession
          </CustomTabPanel>
        </Box>
      </Grid>
    </Layout>
  );
}

export default SuccessionPlanning;
