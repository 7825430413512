import React, { useState } from 'react';
import { IUserAvailableDevelopmentPlan } from 'redux/interfaces';
import ViewFormLayout from 'components/ViewFormLayout';
import CustomTable from 'components/CustomTable';
import { TRows, TColumns } from 'components/CustomTable/interface';

import { IconButton, Tooltip } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
// eslint-disable-next-line import/extensions
import developmentPlan from './developmentPlan.json';

export default function DevelopmentPlan() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const columns: TColumns[] = [
    {
      name: 'Delete',
      column: 'Delete',
      width: 120,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Entity Type',
      column: 'EntityType',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Goal Category',
      column: 'GoalCategory',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Goal',
      column: 'Goal',
      width: 150,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Metric',
      column: 'Metric',
      width: 120,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Assigned Date',
      column: 'AssignedDate',
      width: 340,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Due Date',
      column: 'DueDate',
      width: 120,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Complete',
      column: 'Complete',
      width: 120,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Performance',
      column: 'Performance',
      width: 120,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Edit',
      column: 'Edit',
      width: 120,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Cascade',
      column: 'Cascade',
      width: 120,
      sortable: false,
      hidden: false,
    },
  ];

  function getEditButton() {
    return (
      <Tooltip title="edit">
        <IconButton onClick={() => {}}>
          <ModeEditOutlineOutlinedIcon
            sx={{ color: (theme) => theme.palette.primary.main }}
          />
        </IconButton>
      </Tooltip>
    );
  }

  function getDeleteButton() {
    return (
      <Tooltip title="delete">
        <IconButton onClick={() => handleClickOpen()}>
          <DeleteForeverIcon
            sx={{ color: (theme) => theme.palette.primary.main }}
          />
        </IconButton>
      </Tooltip>
    );
  }

  function getCascadeButton() {
    return (
      <Tooltip title="cascade">
        <IconButton onClick={() => {}}>
          <PersonAddIcon
            sx={{ color: (theme) => theme.palette.primary.main }}
          />
        </IconButton>
      </Tooltip>
    );
  }

  const dataSet: TRows[] = (developmentPlan || []).map(
    (item: IUserAvailableDevelopmentPlan) => ({
      ...item,
      id: item?.Id,
      isCollapsable: false,
      details: [],
      Edit: getEditButton(),
      Delete: getDeleteButton(),
      Cascade: getCascadeButton(),
    }),
  )

  return (
    <>
      <ViewFormLayout testId="available-development-plan">
        <ViewFormLayout.Title>Available Development Plan</ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <CustomTable rows={dataSet} columns={columns} collapseAllRow={false} />
        </ViewFormLayout.Body>
      </ViewFormLayout>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.DISAGREE}>
            <Button onClick={handleClose}>{BUTTON_TEXT.DISAGREE}</Button>
          </Tooltip>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.AGREE}>
            <Button onClick={handleClose} autoFocus>
              {BUTTON_TEXT.AGREE}
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
}
