/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TColumns, TRows } from 'components/CustomTable/interface';
import CustomTable from 'components/CustomTable';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import { Typography } from '@mui/material';
import { useGetYellowFinReportsQuery } from 'services/dataApi';
import LoadingBox from 'components/LoadingBox';
import { ReportItem } from 'services/interfaces';

function getViewButton(id: string | number, name: string, _navigate: Function) {
  return (
    <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.MORE_INFO}>
      <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => _navigate(`${id}/${encodeURIComponent(name)}`)}>
        <VisibilityIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        <Typography color={(theme) => theme.palette.primary.main}>View</Typography>
      </IconButton>
    </Tooltip>
  )
}

function createData(items: ReportItem[], _navigate: Function): TRows[] {
  return items?.map((item: ReportItem) => ({
    ...item,
    view: getViewButton(item?.reportId, item.name, _navigate),
    details: [],
    id: Number(item.reportId),
    isCollapsable: false,
  }));
}

export default function ReportsTable() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetYellowFinReportsQuery();

  const [dataSet, setDataSet] = useState<TRows[] | []>([]);

  const columns: TColumns[] = [
    {
      name: 'Name',
      column: 'name',
      width: 200,
      sortable: true,
    },
    {
      name: 'Description',
      column: 'description',
      width: 200,
      sortable: true,
    },
    {
      name: 'Actions',
      column: 'view',
      width: 120,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (data) {
      setDataSet(createData(data.items, navigate))
    }
  }, [data])

  if (isLoading) {
    return <LoadingBox />
  }

  return (

    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      showSelectCheckbox={false}
    />
  )
}
