/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Box } from '@mui/material/';
import { IState } from 'redux/interfaces';
import { useGetUserStatsQuery } from 'services/dataApi';
import { useSelector } from 'react-redux';
import LoadingIcon from 'components/LoadingIcon';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import useHandleApiError from 'hooks/useHandleApiError';
import { splitMetrics } from './shared';
import MetricsCard from './MetricsCard';

export default function Metrics() {
  const userId = useSelector((state: IState) => state.userProfile.userId);

  const {
    isLoading, isFetching, isError, data, error,
  } = useGetUserStatsQuery({ userId });
  useHandleApiError(isError, error as FetchBaseQueryError);

  if (isLoading || isFetching) {
    return <LoadingIcon />;
  }

  const dataSet = JSON.stringify(data);
  const dataMetrics = (splitMetrics(JSON.parse(dataSet)));
  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(2, 1fr)',
        xl: 'repeat(4, 1fr)',
      },
      gap: 1,
      gridAutoRows: '150px',
    }}
    >
      { React.Children.toArray(dataMetrics?.map((metric) => <MetricsCard {...metric} />))}
    </Box>
  );
}
