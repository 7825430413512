import * as React from 'react';
import Box from '@mui/material/Box';

export default function SquareIcon({ color, size } : { color: string, size: number}) {
  return (
    <Box
      height={size}
      width={size}
      bgcolor={color}
    />
  );
}
