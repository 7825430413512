/* eslint-disable max-len */
import React, { useState } from 'react';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { ITestLibraries } from 'redux/interfaces';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { useNavigate } from 'react-router-dom';
import CustomTable from 'components/CustomTable';
import useInternalStorage from 'hooks/useInternalStorage';

function getViewButton(id: string | number, _navigate: Function) {
  return (
    <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.MORE_INFO}>
      <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => _navigate(`detail/${id}`)}>
        <Edit sx={{ color: (theme) => theme.palette.primary.main }} />
      </IconButton>
    </Tooltip>
  )
}
function createData(items: ITestLibraries[], navigate: Function): TRows[] {
  return items?.map((item: ITestLibraries) => ({
    ...item,
    Name: item.name,
    view: getViewButton(item?.id, navigate),
    questions: item?.questions?.length || 0,
    instances: item?.instances?.length || 0,
    details: [],
    isCollapsable: false,
  } as TRows));
}
export default function TestLibrariesTable() {
  const navigate = useNavigate();
  const [testLibrariesList] = useInternalStorage<any[]>('testLibraryList', []);
  const [dataSet] = useState<TRows[] | []>(createData(testLibrariesList, navigate));

  const columns: TColumns[] = [
    {
      name: 'ID',
      column: 'id',
      width: 200,
      sortable: true,
    },
    {
      name: 'Name',
      column: 'name',
      width: 200,
      sortable: true,
    },
    {
      name: 'Questions',
      column: 'questions',
      align: 'center',
      alignTitle: 'center',
      width: 120,
      sortable: false,
    },
    {
      name: 'Instance',
      alignTitle: 'center',
      column: 'instances',
      align: 'center',
      width: 120,
      sortable: false,
    },
    {
      name: 'Actions',
      column: 'view',
      align: 'right',
      width: 100,
      sortable: false,
    },
  ];

  return (
    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      showSelectCheckbox={false}
      showColumnsControl={false}
    />
  )
}
