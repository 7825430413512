import React from 'react';
import {
  Container, Box, Typography, Button,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import BackgroundImage from '../images/background_image.jpg';

export default function ErrorLogin() {
  const navigate = useNavigate();
  const webApiClientId = localStorage.getItem('webApiClientId')

  return (
    <Container
      maxWidth={false}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography className="secondary-text" variant="h6">
          Unable to login with Single Sign On, try to login again.
        </Typography>
        <Typography variant="subtitle1">
          Contact your administrator or your company&apos;s support team.
        </Typography>
        <br />
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.GO_LOGIN}>
          <Button variant="contained" onClick={() => navigate(`/${webApiClientId}/login`)}>{BUTTON_TEXT.GO_LOGIN}</Button>
        </Tooltip>
      </Box>
    </Container>
  );
}
