// eslint-disable-next-line import/prefer-default-export
export const SCORM_TYPE = {
  NONE: 'none',
  CREATE_LEARNING_OBJECT: 'create-learning-object',
}

export interface IFormResponse {
  id: string;
  value: any;
}
