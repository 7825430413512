import React from 'react';
import {
  Box, Button, Grid, Stack, Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import EvaluationChecklist from 'modules/administrator/AdminEvaluations/EvaluationPreview/EvaluationChecklist';
import { CHECKLIST_FOR_DEMO_ID } from 'modules/administrator/AdminEvaluations/EvaluationPreview';
import Section from './SectionEvaluation';

// eslint-disable-next-line import/extensions
import evaluation from './evaluation.json'

export interface EvaluationQuestion{
  content: string;
  alternatives: string[];
}

export interface EvaluationSection{
  title: string;
  questions: EvaluationQuestion[]
}

interface JsonEvaluation{
  sections: EvaluationSection[]
}

interface IEvaluation{
  evaluationId?: number;
  onClose: ()=> void;
  onSave: () => void;
}

export default function Evaluation({ onSave, onClose, evaluationId }: IEvaluation) {
  const data = evaluation as JsonEvaluation;
  return (
    <div>
      <Stack spacing={2}>
        <ViewFormLayout testId="user-evaluations">
          <ViewFormLayout.ActionBar>
            <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
              <Box>
                <Tooltip title="Print Evaluation">
                  <IconButton sx={{ color: (theme) => theme.palette.primary.main }} aria-label="Print Evaluation">
                    <LocalPrintshopIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box display="flex" gap={2}>
                <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
                  <Button onClick={onSave} variant="contained">{BUTTON_TEXT.SAVE}</Button>
                </Tooltip>
                <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.CANCEL}>
                  <Button onClick={onClose}>{BUTTON_TEXT.CANCEL}</Button>
                </Tooltip>
              </Box>
            </Box>
          </ViewFormLayout.ActionBar>
          <ViewFormLayout.Body>
            {
              evaluationId === CHECKLIST_FOR_DEMO_ID ? <EvaluationChecklist /> : (
                <>
                  <Box>
                    {
              React.Children.toArray(data.sections.map((section) => <Section section={section} />))
                    }
                  </Box>
                  <Box px={3} py={3}>
                    <Typography variant="subtitle1" gutterBottom>
                      User comments:
                    </Typography>
                    <Box>
                      <Grid container>
                        <Grid item xs={1} />
                        <Grid item xs={11} md={11} lg={7}><textarea name="" id="" style={{ width: '100%', padding: '1em' }} /></Grid>
                      </Grid>
                    </Box>
                  </Box>
                </>
              )
            }

          </ViewFormLayout.Body>
        </ViewFormLayout>
      </Stack>

    </div>
  )
}
