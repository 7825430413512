import React from 'react'
import { ACTION_ITEM_TOOLTIP_TEXT, ACTIVE_STATUS } from 'utils/constants';
import { Box, IconButton, Tooltip } from '@mui/material';
import { TRows, TColumns } from 'components/CustomTable/interface';
import { useNavigate } from 'react-router-dom';
import { UserGroup } from 'services/interfaces';
import CustomTable from 'components/CustomTable';
import EditIcon from '@mui/icons-material/Edit';
import ViewFormLayout from 'components/ViewFormLayout';

export default function UserGroups({ userGroups }: {userGroups: UserGroup[]}) {
  const navigate = useNavigate();

  function getActionButtons(group: UserGroup) {
    return (
      <Box display="flex">
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.EDIT}>
          <IconButton
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            onClick={() => navigate(`/admin/user/profile/edit-group/${group?.groupId}`, {
              state: group,
            })}
          >
            <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  const columns: TColumns[] = [
    {
      name: 'Group Name',
      column: 'groupName',
      width: 250,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Primary Division',
      column: 'isPrimaryDivision',
      width: 150,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Division',
      column: 'departmentName',
      width: 340,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Primary Job Position',
      column: 'jobPosition',
      width: 120,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Position',
      column: 'jobPositionDescription',
      width: 180,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Active',
      column: 'userGroupActive',
      width: 80,
      sortable: false,
      hidden: false,
    },
    {
      name: '',
      column: 'actions',
      width: 80,
      sortable: false,
      hidden: false,
    },
  ];

  const dataSet: TRows[] = (userGroups || []).map((userGroup: UserGroup) => ({
    ...userGroup,
    id: userGroup?.userGroupId,
    isCollapsable: false,
    details: [],
    userGroupActive: userGroup?.userGroupActive ? ACTIVE_STATUS.YES : ACTIVE_STATUS.NO,
    actions: getActionButtons(userGroup),
  }))

  return (
    <ViewFormLayout testId="groups-belong-to">
      <ViewFormLayout.Title>
        Groups I belong to
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <CustomTable
          rows={dataSet}
          columns={columns}
          collapseAllRow={false}
          defaultSort="userGroupActive"
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
