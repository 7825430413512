import React, { useEffect } from 'react';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch } from 'react-redux';
import New from 'modules/administrator/AdminTestLibraries/Questions/New';
import Layout from 'components/Layout';

export default function QuestionNewPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('New Question'));
  }, []);
  return (
    <Layout>
      <New />
    </Layout>
  );
}
