import React, { useState } from 'react'
import FormView, { IFormItem } from 'components/FormView';
import { useParams } from 'react-router-dom';
import ViewFormLayout from 'components/ViewFormLayout';
// eslint-disable-next-line import/extensions
import courses from '../courses.json'

export default function Attributes() {
  const { courseId } = useParams();
  const [data] = useState(courses?.find((co) => String(co?.id) === String(courseId)));

  const AccountStatusForm: IFormItem[] = [
    {
      editable: true,
      mandatory: false,
      type: 'multiSelect',
      options: [
        { key: 'adobe-acrobat-reader', value: 'Adobe Acrobat Reader' },
        { key: 'macromedia-flash', value: 'Macromedia Flash' },
        { key: 'monograph', value: 'Monograph' },
        { key: 'mp3', value: 'MP3' },
        { key: 'streaming-media', value: 'Streaming Media' },
        { key: 'windows-media-player', value: 'Windows Media Player' },
        { key: 'desktop-only', value: 'Desktop Only' },
        { key: 'mobile-only', value: 'Mobile Only' },
        { key: 'desktop-mobile-compatible', value: 'Desktop & Mobile Compatible' },
      ],
      fieldValue: data?.technology,
      fieldId: 'technology',
      label: 'Technology',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'multiSelect',
      options: [
        { key: 'bimark', value: 'Bimark' },
        { key: 'metropolitan-chicago-healthcare-council', value: 'Metropolitan Chicago Healthcare Council' },
      ],
      fieldValue: data?.sponsors,
      fieldId: 'sponsors',
      label: 'Sponsors',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.ceBrokerPublishing),
      fieldId: 'ce-broker-publishing',
      label: 'CE Broker Publishing',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.ceBrokerReporting),
      fieldId: 'ce-broker-reporting',
      label: 'CE Broker Reporting',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'multiSelect',
      options: [
        { key: 'ce-broker', value: 'CE Broker' },
        { key: 'general-pharmacist', value: 'General Pharmacist' },
        { key: 'consultant-pharmacist', value: 'Consultant Pharmacist' },
        { key: 'nuclear-pharmacist', value: 'Nuclear Pharmacist' },
      ],
      fieldValue: data?.license,
      fieldId: 'license',
      label: 'CE Broker License',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.category),
      fieldId: 'category',
      label: 'Category',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'multiSelect',
      options: [
        { key: 'class-packets', value: 'Class packets' },
        { key: 'confirm-faculty', value: 'Confirm Faculty' },
        { key: 'laptop-l987569', value: 'Laptop-L987569' },
        { key: 'print-cert-standard', value: 'Print cert standard' },
        { key: 'send-study-packets', value: 'Send study packets' },
      ],
      fieldValue: data?.tasks,
      fieldId: 'tasks',
      label: 'Tasks',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'multiSelect',
      options: [
        { key: 'application-based', value: 'Application-Based' },
        { key: 'knowledge-based', value: 'Knowledge-Based' },
        { key: 'practice-based', value: 'Practice-Based' },
      ],
      fieldValue: data?.attributeType,
      fieldId: 'attributeType',
      label: 'Type',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'multiSelect',
      options: [
        { key: 'acls-updates', value: 'ACLS Updates' },
        { key: 'allergies', value: 'Allergies' },
        { key: 'asthma', value: 'Asthma' },
        { key: 'asthma-educators', value: 'Asthma Educators' },
        { key: 'atopic-dermatitis', value: 'Atopic Dermatitis (Eczema)' },
        { key: 'autoimmune-diseases', value: 'Autoimmune Diseases' },
      ],
      fieldValue: data?.topics,
      fieldId: 'topics',
      label: 'Topics',
      titleSize: 3,
    },
  ];

  return (
    <ViewFormLayout testId="attibutes">
      <ViewFormLayout.Title>
        Attributes
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <FormView
          style={{ paddingLeft: 1, paddingRight: 1 }}
          fields={AccountStatusForm}
          onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
