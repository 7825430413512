import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import SearchLearningObject from 'modules/SearchLearningObject';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';

export default function ContentLibrary() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Content Library'))
  }, []);

  return (
    <Layout>
      <SearchLearningObject />
    </Layout>
  );
}
