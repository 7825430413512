import React from 'react';
import ViewFormLayout from 'components/ViewFormLayout';
import FormView, { IFormItem } from 'components/FormView';

export default function NewRatingTypeForm() {
  const evaluationRatingForm: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'EvaluationRatingName',
      label: 'Evaluation Rating Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'EntityType',
      label: 'Entity Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'Score',
      label: 'Score',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'ExcludeFromCalculation',
      label: 'Exclude From Calculation',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'ForceComments',
      label: 'Force Comments',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'ColorCode',
      label: 'Color Code',
      titleSize: 3,
    },
  ]
  const internalInformationForm: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'CreatedBy',
      label: 'Created By',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'CreatedDate',
      label: 'Created Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'Updated By',
      label: 'Updated By',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'UpdatedDate',
      label: 'Updated Date',
      titleSize: 3,
    },
  ]
  return (
    <>
      <ViewFormLayout testId="personal-information">
        <ViewFormLayout.Title>
          Personal Information
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={evaluationRatingForm}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
      <ViewFormLayout testId="employee-information">
        <ViewFormLayout.Title>
          Employee Information
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={internalInformationForm}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
    </>
  )
}
