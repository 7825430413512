import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import CourseUsers from 'modules/administrator/CourseUsers';

function CourseUsersPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Users'))
  }, []);

  return (
    <Layout>
      <CourseUsers />
    </Layout>
  );
}

export default CourseUsersPage;
