import React, { useState } from 'react'
import { Button } from '@mui/material'
import { IQuestion } from 'redux/interfaces';
import { useNavigate } from 'react-router-dom';
import useInternalStorage from 'hooks/useInternalStorage';
import ViewFormLayout from 'components/ViewFormLayout'
import QuestionList from './List';

export default function Questions() {
  const navigate = useNavigate();
  const [questionPoolList] = useInternalStorage<any[]>('questionPool', []);
  const [questions] = useState<IQuestion[]>(questionPoolList);
  return (
    <ViewFormLayout testId="administrator-questions-pool">
      <ViewFormLayout.ActionBar>
        <Button variant="contained" onClick={() => navigate('new')}>New question</Button>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <QuestionList questions={questions} />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
