import React, { useState } from 'react'
import FormView, { IFormItem } from 'components/FormView';
import { useParams } from 'react-router-dom';
import ViewFormLayout from 'components/ViewFormLayout';
// eslint-disable-next-line import/extensions
import courses from '../courses.json'

export default function Advance() {
  const { courseId } = useParams();
  const [data] = useState(courses?.find((co) => String(co?.id) === String(courseId)));

  const AccountStatusForm: IFormItem[] = [
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.test),
      fieldId: 'test',
      label: 'Test',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.preTest),
      fieldId: 'pre-test',
      label: 'PreTest',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.sameAs),
      fieldId: 'same-as',
      label: 'Same As',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.ownership),
      fieldId: 'ownership',
      label: 'Ownership',
      titleSize: 3,
    },
  ]

  return (
    <ViewFormLayout testId="advance">
      <ViewFormLayout.Title>
        Advanced
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <FormView
          style={{ paddingLeft: 1, paddingRight: 1 }}
          fields={AccountStatusForm}
          onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>

  )
}
