import React, { useEffect } from 'react';
import {
  Container,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSSOAccessToken } from 'redux/userProfileSlice';
import BackgroundImage from '../images/background_image.jpg';

export default function Sso() {
  const { accessToken, userId, webApiClientId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSSOAccessToken({ accessToken, userId, webApiClientId }));
    navigate('/dashboard');
  }, []);

  const loading = (
    <Box sx={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <CircularProgress />
      <Typography> Login you in, plase wait.</Typography>
    </Box>
  );

  return (
    <Container
      maxWidth={false}
      style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {loading}
      </Box>
    </Container>
  );
}
