import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ViewFormLayout from 'components/ViewFormLayout';
import { Button, Tooltip } from '@mui/material';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import NewRatingGroupForm from './NewRatingGroupForm';

export default function NewRatingType() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const handleBack = () => {
    navigate('/admin/rating-types');
  };
  const handleSave = () => {
    navigate('/admin/rating-types');
    enqueueSnackbar('New Rating Type Save Successfully', { variant: 'success' });
  };
  return (
    <ViewFormLayout pl={0} pr={0} testId="new-rating-type">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
          <Button variant="text" onClick={handleBack}>
            {BUTTON_TEXT.BACK}
          </Button>
        </Tooltip>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
          <Button variant="contained" onClick={handleSave}>
            {BUTTON_TEXT.SAVE}
          </Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <NewRatingGroupForm />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
