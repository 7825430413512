import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CustomTable from 'components/CustomTable';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { IRatingType } from 'redux/interfaces';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
// eslint-disable-next-line import/extensions
import ratingList from '../ratingList.json';

function getActionButtons(navigate: Function) {
  return (
    <Box display="flex">
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.EDIT_RATING_TYPE}>
        <IconButton onClick={() => navigate('/admin/rating-types/new')} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.VIEW_RATING_TYPE}>
        <IconButton onClick={() => navigate('/admin/rating-types/new')} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <VisibilityIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

function createData(items: IRatingType[], navigate: Function): TRows[] {
  return items?.map((item: IRatingType) => ({
    ...item,
    details: [],
    id: Number(item.ID),
    isCollapsable: false,
    Actions: getActionButtons(navigate),
  }));
}

export default function UsersTable() {
  const navigate = useNavigate();
  const [dataSet] = useState<TRows[] | []>(createData(ratingList, navigate));

  const columns: TColumns[] = [
    {
      name: 'ID',
      column: 'ID',
      width: 200,
      sortable: true,
    },
    {
      name: 'Name',
      column: 'Name',
      width: 120,
      sortable: true,
    },
    {
      name: 'Entity Name',
      column: 'EntityName',
      width: 120,
      sortable: true,
    },
    {
      name: 'Score',
      column: 'Score',
      width: 120,
      sortable: true,
    },
    {
      name: 'Actions',
      column: 'Actions',
      width: 120,
      sortable: true,
    },
  ];
  return (

    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      showSelectCheckbox={false}
    />
  )
}
