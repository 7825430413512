import React from 'react';
import Layout from 'components/Layout';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { UserGroup } from 'services/interfaces';
import UserEditGroup from 'modules/administrator/AdminUserProfile/UserEditGroup';

function AdminUserEditGroupPage() {
  const location = useLocation();
  const groupDetail: UserGroup = location?.state
  if (!groupDetail?.groupId) {
    return (
      <Layout>
        <Typography>No user selected!</Typography>
      </Layout>
    )
  }
  console.log({ groupDetail })
  return (
    <Layout>
      <UserEditGroup userGroup={groupDetail} />
    </Layout>
  );
}

export default AdminUserEditGroupPage;
