import React from 'react';
import ViewFormLayout from 'components/ViewFormLayout';
import FormView, { IFormItem } from 'components/FormView';

export default function NewUserForm() {
  const personalInformationForm: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'UserName',
      label: 'User Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'FirstName',
      label: 'First Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'MiddleName',
      label: 'Middle Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'LastName',
      label: 'Last Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'Email',
      label: 'Email',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'City',
      label: 'City',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: [],
      fieldValue: '',
      fieldId: 'state',
      label: 'State',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'Zip',
      label: 'ZIP',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'select',
      options: [
        { key: 'TBD', value: 'TBD' },
        { key: 'USA', value: 'United States Of America' },
        { key: 'CAN', value: 'Canada' },
        { key: 'MEX', value: 'Mexico' },
      ],
      fieldValue: '',
      fieldId: 'country',
      label: 'Country',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'BirthDate',
      label: 'Birth Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'Gender',
      label: 'Gender',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'WorkPhoneNumber',
      label: 'Work Phone',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'mobilePhone',
      label: 'Mobile Phone',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'select',
      options: [{ key: 'TBD', value: 'TBD' }],
      fieldValue: 'TBD',
      fieldId: 'school',
      label: 'School',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'password',
      label: 'Password',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'newPassword',
      label: 'New Password',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'checkbox',
      options: undefined,
      fieldValue: '',
      fieldId: 'active',
      label: 'Active',
      titleSize: 3,
    },

  ];

  const employeeInformationForm: IFormItem[] = [
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'EmployeeID',
      label: 'Employee ID',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'EmployeeBadgeNumber',
      label: 'Badge ID',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'select',
      options: [{ key: 'TBD', value: 'TBD' }],
      fieldValue: '',
      fieldId: 'employmentTypeId',
      label: 'Employment Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'divisionId',
      label: 'Division',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      fieldValue: '',
      fieldId: 'DateHired',
      label: 'Hire Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'PromotionDate',
      label: 'Promotion Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'TerminationDate',
      label: 'Termination Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'RehireDate',
      label: 'Rehired Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'position',
      label: 'Position',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      fieldValue: '',
      fieldId: 'SupervisorLevel',
      label: 'Supervisor Level',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'select',
      options: [{ key: 'TBD', value: 'TBD' }],
      fieldValue: '',
      fieldId: 'specialtyId',
      label: 'Specialty Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      fieldValue: '',
      fieldId: 'CredentialName',
      label: 'Credential Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      fieldValue: '',
      fieldId: 'subStatus',
      label: 'Employment Sub Type',
      titleSize: 3,
    },

    {
      editable: true,
      mandatory: true,
      type: 'input',
      fieldValue: '',
      fieldId: 'SalaryGrade',
      label: 'Salary Grade',
      titleSize: 3,
    },
  ];

  return (
    <>
      <ViewFormLayout testId="personal-information">
        <ViewFormLayout.Title>
          Personal Information
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={personalInformationForm}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
      <ViewFormLayout testId="employee-information">
        <ViewFormLayout.Title>
          Employee Information
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={employeeInformationForm}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
    </>
  )
}
