/* eslint-disable max-len */
import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import './progressbar.scss';

interface IProgressBar {
  steps: string[];
  activeStep: string;
}

export default function ProgressBar(props: IProgressBar) {
  const { steps, activeStep } = props;

  const indexActiveStep = steps.findIndex(
    // eslint-disable-next-line comma-dangle
    (step) => step.toLowerCase() === activeStep.toLowerCase()
  );

  return (
    <Box sx={{ width: '100%' }} paddingTop={3} paddingBottom={2}>
      <Stepper activeStep={indexActiveStep} alternativeLabel>
        {React.Children.toArray(steps.map((label) => (
          <Step>
            <StepLabel>{label}</StepLabel>
          </Step>
        )))}
      </Stepper>
    </Box>
  );
}
