/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IUserProfile } from 'redux/interfaces';
import { authApi } from 'services/authApi';
import { dataApi } from 'services/dataApi';
import { UserDetailResponse } from 'services/interfaces';
import { tokenApi } from 'services/tokenApi';
import { DEFAULT_APP_THEME_PRIMARY_COLOR, YELLLOWFIN_COOKIE_NAME, YELLLOWFIN_COOKIE_DOMAIN } from 'utils/constants';
import { isUserAnAdmin } from 'utils/helpers';

const userInitialState: UserDetailResponse = {
  userId: 0,
  firstName: '',
  lastName: '',
  middleName: '',
  employeeId: '',
  ssn: '',
  dateHired: '',
  active: 0,
  email: '',
  userName: '',
  homeAddress: '',
  city: '',
  state: '',
  zip: '',
  homePhoneNumber: '',
  workPhoneNumber: '',
  supervisors: [],
  birthDate: '',
  mergeFacilityCode: '',
  mergeDate: '',
  leadershipAssessmentLevelName: '',
  distributionCode: '',
  distributionName: '',
  terminationDate: '',
  rehireDate: '',
  employeeBadgeNumber: '',
  company: '',
  specialtyName: '',
  specialtyId: 0,
  credentialName: '',
  subStatus: '',
  userLicenses: [],
  promotionDate: '',
  salaryGrade: '',
  supervisorLevel: '',
  safetySensitive: '',
  gender: '',
  ethnicityType: '',
  learnerEthnicity: '',
  cdlClassificationTypeId: 0,
  cdlExpirationDate: '',
  customUserFields: [],
  photoPath: '',
  userGroups: [],
  ahaurl: '',
  policy: [],
  lastLogin: '',
  loginAsLink: null,
  countryCode: '',
  ethnicityTypeId: null,
  cdlClassificationType: '',
  schoolId: null,
  employmentTypeId: 0,
  divisionId: 0,
  credentialId: 0,
  salaryGradeTypeId: 0,
  supervisorLevelTypeId: null,
  subStatusTypeId: null,
  position: '',
};

const welcomeVideoInitial = {
  welcomeMessageTitle: '',
  welcomeMessageBodyText: '',
  welcomeMessageImageURL: '',
  welcomeMessageVideoURL: '',
  welcomeMessageFooter: '',
}

const yellowFinInitial = {
  myTranscriptReportId: '',
  yellowfinClientOrgRef: '',
}

const initialState: IUserProfile = {
  authenticated: localStorage.getItem('authenticated') === 'true',
  accessToken: localStorage.getItem('accessToken'),
  userId: localStorage.getItem('userId') || '',
  companyName: localStorage.getItem('companyName'),
  webApiClientId: localStorage.getItem('webApiClientId'),
  companyImageLocation: localStorage.getItem('companyImageLocation') || '',
  userNavigationPage: 'My Tasks',
  goBackTo: null,
  primaryColor: localStorage.getItem('primaryColor') || DEFAULT_APP_THEME_PRIMARY_COLOR,
  user: userInitialState,
  admin: false,
  companyIcon: localStorage.getItem('companyIcon') || '',
  companyBackgroundImageURL: localStorage.getItem('companyBackgroundImageURL') || '',
  welcomeVideo: JSON.parse(localStorage.getItem('welcomeVideo')!) || welcomeVideoInitial,
  yellowFin: JSON.parse(localStorage.getItem('yellowFin')!) || yellowFinInitial,
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    login: (state) => {
      state.authenticated = true;
    },
    setSSOAccessToken: (state, action) => {
      localStorage.setItem('accessToken', action.payload.accessToken)
      localStorage.setItem('authenticated', 'true')
      localStorage.setItem('userId', action.payload.userId)
      localStorage.setItem('companyName', action.payload.companyName)
      localStorage.setItem('companyIcon', action.payload.companyIconURL)
      localStorage.setItem('companyBackgroundImageURL', action.payload.companyBackgroundImageURL)
      state.accessToken = action.payload.accessToken;
      state.userId = action.payload.userId;
      state.authenticated = true;
      state.companyBackgroundImageURL = action.payload.companyBackgroundImageURL
    },
    logout: (state) => {
      state.authenticated = false;
      state.user = userInitialState;
      localStorage.setItem('authenticated', 'false')
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userId');
      localStorage.removeItem('primaryColor');
      localStorage.removeItem('companyImageLocation');
      localStorage.removeItem('companyIcon');
      localStorage.removeItem('companyBackgroundImageURL');
      localStorage.removeItem('webApiClientId');
      localStorage.removeItem('companyName');
      document.cookie = `${YELLLOWFIN_COOKIE_NAME}=; domain=${YELLLOWFIN_COOKIE_DOMAIN}; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    },
    setNavigationPage: (state, action) => {
      state.userNavigationPage = action.payload;
    },
    setGoBackTo: (state, action) => {
      state.goBackTo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getLoginUser.matchFulfilled,
      (state, action) => {
        const payload = action?.payload;
        if (payload?.accessToken) {
          localStorage.setItem('accessToken', payload.accessToken);
          localStorage.setItem('userId', action.payload.userData.userId);
          localStorage.setItem('authenticated', 'true');
          state.userId = action.payload.userData.userId;
          state.accessToken = payload.accessToken;
          state.authenticated = true;
        } else {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('userId');
          localStorage.removeItem('authenticated');
          state.userId = '';
          state.accessToken = null;
          state.authenticated = false;
          document.cookie = `${YELLLOWFIN_COOKIE_NAME}=; domain=${YELLLOWFIN_COOKIE_DOMAIN}; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        }
      },
    );
    builder.addMatcher(
      authApi.endpoints.getCompanyDetails.matchFulfilled,
      (state, action) => {
        const payload = action?.payload;
        if (payload?.companyIconURL) {
          localStorage.setItem('webApiClientId', payload.webApiClientId);
          localStorage.setItem('companyName', payload.companyName);
          localStorage.setItem('primaryColor', payload.themePrimaryColor);
          localStorage.setItem('companyImageLocation', payload.companyImageLocation);
          localStorage.setItem('companyIcon', payload.companyIconURL);
          localStorage.setItem('companyBackgroundImageURL', action.payload.companyBackgroundImageURL)
          state.primaryColor = payload.themePrimaryColor;
          state.webApiClientId = payload.webApiClientId;
          state.companyName = payload.companyName;
          state.companyImageLocation = payload.companyImageLocation;
          state.companyIcon = payload.companyIconURL;
          state.companyBackgroundImageURL = payload.companyBackgroundImageURL

          const welcomeVideo = {
            welcomeMessageTitle: payload.welcomeMessageTitle,
            welcomeMessageBodyText: payload.welcomeMessageBodyText,
            welcomeMessageImageURL: payload.welcomeMessageImageURL,
            welcomeMessageVideoURL: payload.welcomeMessageVideoURL,
            welcomeMessageFooter: payload.welcomeMessageFooter,
          }
          state.welcomeVideo = welcomeVideo;
          localStorage.setItem('welcomeVideo', JSON.stringify(welcomeVideo));

          const yellowFin = {
            myTranscriptReportId: payload.myTranscriptReportId,
            yellowfinClientOrgRef: payload.yellowfinClientOrgRef,
          }
          state.yellowFin = yellowFin;
          localStorage.setItem('yellowFin', JSON.stringify(yellowFin))
        } else {
          localStorage.removeItem('companyName');
          localStorage.removeItem('webApiClientId');
          localStorage.removeItem('primaryColor');
          localStorage.removeItem('companyImageLocation');
          localStorage.removeItem('companyIcon');
          localStorage.removeItem('welcomeVideo');
          localStorage.removeItem('yellowFin');
          localStorage.removeItem('companyBackgroundImageURL');
        }
      },
    );
    builder.addMatcher(
      dataApi.endpoints.getUserDetail.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        state.admin = isUserAnAdmin(payload?.userGroups)
      },
    );
    builder.addMatcher(
      tokenApi.endpoints.validateToken.matchRejected,
      (state, { payload }) => {
        if (payload?.status === 'FETCH_ERROR') {
          state.authenticated = false;
          state.user = userInitialState;
          localStorage.setItem('authenticated', 'false')
          localStorage.removeItem('accessToken');
          localStorage.removeItem('userId');
          localStorage.removeItem('primaryColor');
          localStorage.removeItem('companyImageLocation');
          localStorage.removeItem('companyName');
          localStorage.removeItem('companyIcon');
          document.cookie = 'NextGenUserId=; domain=.dev.learnsoft.com; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
      },
    );
  },
});

export const {
  login, logout, setSSOAccessToken, setNavigationPage, setGoBackTo,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
