/* eslint-disable max-len */
import React from 'react'
import { useSelector } from 'react-redux';
import { IState, IUserCustomField } from 'redux/interfaces';
import { TRows, TColumns } from 'components/CustomTable/interface';
import ViewFormLayout from 'components/ViewFormLayout';
import CustomTable from 'components/CustomTable';

export default function UserCustomFields() {
  const userCustomFields = useSelector((state: IState) => state.userProfile?.user?.customUserFields);

  const columns:TColumns[] = [
    {
      name: 'User Field ID',
      column: 'userFieldId',
      width: 120,
      sortable: true,
    },
    {
      name: 'User Field Type Name',
      column: 'userFieldTypeName',
      width: 200,
      sortable: true,
    },
    {
      name: 'Description',
      column: 'userFieldDescription',
      width: 200,
      sortable: true,
    },
  ];

  const dataSet: TRows[] = userCustomFields?.map((lic : IUserCustomField) => ({
    ...lic,
    id: lic?.userFieldId,
    isCollapsable: false,
    details: [],
  })) || [];

  return (
    <ViewFormLayout testId="Current Custom User Field">
      <ViewFormLayout.Title>
        Current Custom User Field
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <CustomTable
          rows={dataSet}
          columns={columns}
          collapseAllRow={false}
          defaultSort="userFieldId"
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
