import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import RatingTypesTable from './RatingTypesTable/RatingTypesTable';

export default function RatingTypes() {
  const navigate = useNavigate();

  const handleNewUser = () => {
    navigate('/admin/rating-types/new');
  };

  return (
    <ViewFormLayout pl={0} pr={0} testId="users-list">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.NEW_RATING_TYPE}>
          <Button variant="contained" onClick={handleNewUser}>
            {BUTTON_TEXT.NEW_RATING_TYPE}
          </Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <RatingTypesTable />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
