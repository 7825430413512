import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import { IState } from 'redux/interfaces';

function ProtectedAdminRoutes() {
  // here we have to define a function to check if the user is logged or not

  const isAdmin = useSelector((state: IState) => state.userProfile.admin);

  if (!isAdmin) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}

export default ProtectedAdminRoutes;
