import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Tooltip,
} from '@mui/material';

import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import EvaluationsTable from './EvaluationsTable';

export default function AdminEvaluations() {
  const navigate = useNavigate();
  return (
    <ViewFormLayout testId="evaluations">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.NEW_EVALUATION}>
          <Button variant="contained" onClick={() => { navigate('details/11') }}>{BUTTON_TEXT.NEW_EVALUATION}</Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <EvaluationsTable />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
