import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import FormView, { IFormItem } from 'components/FormView';
import ViewFormLayout from 'components/ViewFormLayout';
// eslint-disable-next-line import/extensions
import courses from '../courses.json'

export default function Outline() {
  const { courseId } = useParams();
  const [data] = useState(courses?.find((co) => String(co?.id) === String(courseId)));
  const AccountStatusForm: IFormItem[] = [
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.outline),
      fieldId: 'outline',
      label: 'Outline',
      titleSize: 3,
    },
  ]
  return (
    <ViewFormLayout testId="outline">
      <ViewFormLayout.Title>
        Outline
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <FormView
          style={{ paddingLeft: 1, paddingRight: 1 }}
          fields={AccountStatusForm}
          onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
