import React, { useRef, useState } from 'react';
import {
  Box, TextField, Button, Container, Typography, Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

function UserFilterForm({ onSearch, onReset }: { onSearch: Function, onReset: Function }) {
  const [isActive, setIsActive] = useState(true);

  const formRef = useRef<HTMLFormElement | null>(null);
  const userIdRef = useRef<HTMLInputElement | null>(null);
  const employeeIdRef = useRef<HTMLInputElement | null>(null);
  const userNameRef = useRef<HTMLInputElement | null>(null);
  const firstNameRef = useRef<HTMLInputElement | null>(null);
  const middleNameRef = useRef<HTMLInputElement | null>(null);
  const lastNameRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const jobCodeDescriptionRef = useRef<HTMLInputElement | null>(null);
  const jobClassDescriptionRef = useRef<HTMLInputElement | null>(null);
  const jobPositionDescriptionRef = useRef<HTMLInputElement | null>(null);
  const divisionRef = useRef<HTMLInputElement | null>(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = {
      userId: userIdRef?.current?.value || null,
      employeeId: employeeIdRef?.current?.value || null,
      userName: userNameRef?.current?.value || null,
      firstName: firstNameRef?.current?.value || null,
      middleName: middleNameRef?.current?.value || null,
      lastName: lastNameRef?.current?.value || null,
      email: emailRef?.current?.value || null,
      jobCodeDescription: jobCodeDescriptionRef?.current?.value || null,
      jobClassDescription: jobClassDescriptionRef?.current?.value || null,
      jobPositionDescription: jobPositionDescriptionRef?.current?.value || null,
      division: divisionRef?.current?.value || null,
      active: isActive,
    };
    scrollToTop()
    onSearch(formData);
  };

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    setIsActive(true);
    scrollToTop()
    onReset();
  };

  return (
    <Container maxWidth="lg" sx={{ p: 2 }}>
      <Box component="form" onSubmit={handleSubmit} ref={formRef}>
        <Typography variant="h6" sx={{ mt: 2 }}>
          User Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="User ID" name="userId" inputRef={userIdRef} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Employee ID" name="employeeId" inputRef={employeeIdRef} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="User Name" name="userName" inputRef={userNameRef} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="First Name" name="firstName" inputRef={firstNameRef} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Middle Name" name="middleName" inputRef={middleNameRef} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Last Name" name="lastName" inputRef={lastNameRef} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Email" name="email" inputRef={emailRef} />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mt: 4 }}>
          User Status
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  name="active"
                />
              )}
              label="Active"
            />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Job Position Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Job Code Description" name="jobCodeDescription" inputRef={jobCodeDescriptionRef} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Job Class Description" name="jobClassDescription" inputRef={jobClassDescriptionRef} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Job Position Description" name="jobPositionDescription" inputRef={jobPositionDescriptionRef} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Division" name="division" inputRef={divisionRef} />
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }} display="flex" flexDirection="row-reverse" gap={1}>
          <Button type="submit" variant="contained" color="primary">
            Search
          </Button>
          <Button onClick={handleReset}>
            Reset
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default UserFilterForm;
