import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import CourseDetails from 'modules/SearchLearningObject/CourseDetails';
import { LearningObject } from 'modules/SearchLearningObject/shared';

export default function ContentLibraryDetailPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const courseDetail: LearningObject = location?.state
  useEffect(() => {
    dispatch(setNavigationPage(`Content Library detail: ${courseDetail?.learningObjectName}`))
  }, []);

  return (
    <Layout>
      <CourseDetails courseDetail={courseDetail} goBack={() => navigate('/content-library')} />
    </Layout>
  );
}
