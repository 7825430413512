/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { dataApi } from 'services/dataApi';

const initialState = {
  vendors: [],
};

export const userConfigurationSlice = createSlice({
  name: 'userConfiguration',
  initialState,
  reducers: {
    resetAllFilters: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dataApi.endpoints.getVendors.matchFulfilled,
      (state, { payload }) => {
        state.vendors = payload
      },
    );
  },
});

export const {
  resetAllFilters,
} = userConfigurationSlice.actions;

export default userConfigurationSlice.reducer;
