import React from 'react';
import { Box, CircularProgress } from '@mui/material';

function LoadingBox() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" data-testid="loading-box">
      <CircularProgress data-testid="loading-icon" />
    </Box>
  );
}

export default LoadingBox;
