/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const TextFieldStyleded = styled(TextField)({
  '.MuiInput-root:before':
  {
    border: 'none',
  },
})
function TextFieldStyled({ ...props }) {
  return (
    <TextFieldStyleded
      sx={{
        width: '100%',
        borderRadius: 'none',
        paddingRight: '5px',
      }}
      InputLabelProps={{ shrink: false }}
      InputProps={{
        sx: {
          '&.Mui-disabled::before': {
            content: '""',
            border: 'none',
          },
        },
      }}
      variant="standard"
      size="small"
      {...props}
    />
  )
}

export default TextFieldStyled
