/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Checkbox, Stack } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import BadgeInfo from 'components/Badge';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LabelValues } from './interface';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
interface selectFilterProps {
  filtro: LabelValues, selected: string[], onChange: Function
}

export default function SelectFilter({ filtro, selected, onChange }: selectFilterProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: any) => {
    const { target: { checked, id } } = event;
    if (checked) {
      onChange([...selected, id]);
    } else {
      const index = selected.indexOf(id);
      if (index > -1) {
        selected.splice(index, 1);
      }
      onChange(selected)
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      handleClose();
    }, 3000);
  };

  return (
    <Stack>
      <Button
        id="demo-customized-button"
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ textTransform: 'none' }}
      >
        {filtro?.label}
        {selected?.length > 0 && <BadgeInfo value={selected?.length} />}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          React.Children.toArray(
            filtro?.values?.map((val) => {
              const isChecked = selected.findIndex((item) => item === val) >= 0;
              return (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation()
                    handleChange({ target: { checked: !isChecked, id: val } })
                  }}
                >
                  <Checkbox
                    checked={isChecked}
                    id={val}
                  />
                  {val}
                </MenuItem>
              )
            }),
          )
        }
        {
          filtro.values.length === 0 && (
            <MenuItem onChange={handleChange}>
              No options
            </MenuItem>
          )
        }
      </StyledMenu>
    </Stack>
  );
}
