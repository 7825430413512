import React from 'react';
import { Box, Typography } from '@mui/material';
import ViewFormLayout from 'components/ViewFormLayout';
import FormView, { IFormItem } from 'components/FormView';
import Questions from './Questions';

// eslint-disable-next-line import/extensions
import evaluationPreview from './evaluationChecklistPreview.json'

export default function Evaluation() {
  const realDate = new Date();
  const EvaluationForm: IFormItem[] = [
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: evaluationPreview.CourseName,
      fieldId: 'CourseName',
      label: 'Course Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: realDate.toISOString(),
      fieldId: 'EvaluationDate',
      label: 'Evaluation Date',
      titleSize: 3,
    },
  ];

  const CommentsForm: IFormItem[] = [
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: evaluationPreview.GeneralComments,
      fieldId: 'GeneralComments',
      label: 'General Comments',
      titleSize: 3,
    },
  ];

  return (
    <>
      <Box>
        <ViewFormLayout testId="evaluation">
          <ViewFormLayout.Title>
            Evaluation
          </ViewFormLayout.Title>
          <ViewFormLayout.Body>
            <FormView
              style={{ paddingLeft: 1, paddingRight: 1 }}
              fields={EvaluationForm}
              onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
            />
          </ViewFormLayout.Body>
        </ViewFormLayout>
      </Box>

      <Box mt={5}>
        <ViewFormLayout testId="evaluation">
          <ViewFormLayout.Title>
            <Typography>
              Setup
            </Typography>
          </ViewFormLayout.Title>
          <Typography>
            Indicate if the user is able to successfully demonstrate the skills required to setup.
          </Typography>
          <Questions questions={evaluationPreview.Questions} />
        </ViewFormLayout>
      </Box>

      <Box mt={0}>
        <ViewFormLayout testId="evaluation">
          <Questions questions={evaluationPreview.Questions2} />
        </ViewFormLayout>
      </Box>

      <Box mt={5}>
        <ViewFormLayout testId="evaluation">
          <ViewFormLayout.Title>
            Race
          </ViewFormLayout.Title>
          <Typography>
            Indicate if the user is able to successfully demonstrate the skills required to race.
          </Typography>
          <Questions questions={evaluationPreview.Questions3} />
        </ViewFormLayout>
      </Box>

      <Box mt={5}>
        <ViewFormLayout testId="evaluation">
          <ViewFormLayout.Title>
            Comments
          </ViewFormLayout.Title>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={CommentsForm}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout>
      </Box>
    </>
  )
}
