/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import { IEvaluationQuestionGroup } from 'redux/interfaces';
import { TColumns, TRows } from 'components/CustomTable/interface';
import CustomTable from 'components/CustomTable';
// eslint-disable-next-line import/extensions
import evaluationQuestionGroupList from '../evaluationQuestionGroupList.json'

type EditEvaluationFunction = () => void;

function getActionButtons(editEvaluationFn: EditEvaluationFunction) {
  return (
    <Box display="flex">
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.EDIT_EVALUATION_QUESTION_GROUP}>
        <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={editEvaluationFn}>
          <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

function createData(items: IEvaluationQuestionGroup[], editEvaluationFn: EditEvaluationFunction): TRows[] {
  return items?.map((item: IEvaluationQuestionGroup) => ({
    ...item,
    preview: getActionButtons(editEvaluationFn),
    details: [],
    id: item.Id,
    isCollapsable: false,
  }));
}

export default function EvaluationQuestionGroupTable() {
  const navigate = useNavigate();
  const handleEditEvaluation = () => navigate('/admin/evaluation-question-group-list/new')
  const [dataSet] = useState<TRows[] | []>(createData(evaluationQuestionGroupList, handleEditEvaluation));

  const columns: TColumns[] = [
    {
      name: 'ID',
      column: 'Id',
      width: 200,
      sortable: true,
    },
    {
      name: 'Name',
      column: 'Name',
      width: 200,
      sortable: true,
    },
    {
      name: 'Entity Name',
      column: 'EntityName',
      width: 200,
      sortable: true,
    },
    {
      name: 'LearningObjects',
      column: 'Questions',
      width: 200,
      sortable: true,
    },
    {
      name: 'Actions',
      column: 'preview',
      width: 120,
      sortable: false,
    },
  ];

  return (

    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      showSelectCheckbox={false}
    />

  )
}
