import React, { useState } from 'react'
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  Alert, Box, Button, Tooltip,
} from '@mui/material';
import FormView, { IFormItem } from 'components/FormView';
import ViewFormLayout from 'components/ViewFormLayout';
import {
  useCreateScormCourseMutation, useGetCatalogTreeItemsQuery, useGetDivisionsTreeViewQuery,
} from 'services/dataApi';
import {
  CatalogTreeItemsResponse, DivisionTreeView, ScormTestRequest, TreeItem,
} from 'services/interfaces';
import LoadingBox from 'components/LoadingBox';
import { TreeNode } from 'primereact/treenode';
import { Folder } from '@mui/icons-material';
import { handleErrorMessage } from 'utils/helpers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import ScormCourses from './ScormCourses';
import { IFormResponse, SCORM_TYPE } from './constants';

export function updateArrayState<T extends { id: string }>(
  prevState: T[],
  newResponse: T,
): T[] {
  const existingIndex = prevState.findIndex((item) => item.id === newResponse.id);

  if (existingIndex !== -1) {
    const updatedState = [...prevState];
    updatedState[existingIndex] = newResponse;
    return updatedState;
  }
  return [...prevState, newResponse];
}

function extractValues(
  informationArray: IFormResponse[],
): any {
  const initialUserDetails: { [key: string]: string | number } = {}

  informationArray.forEach((curr) => {
    initialUserDetails[curr?.id] = curr?.value
  });
  const finalUserDetails: any = {
    ...initialUserDetails,
  };

  return finalUserDetails;
}

const transformItemsToOptionsCatalog = (items: TreeItem[]): TreeNode[] => items.map((item) => ({
  key: item.id,
  label: item.name,
  icon: <Folder color="primary" />,
  children: item.children ? transformItemsToOptionsCatalog(item.children) : [],
}));

// eslint-disable-next-line max-len
const transformItemsToOptionsDivisions = (items: DivisionTreeView[]): TreeNode[] => items.map((item) => ({
  key: item.divisionId,
  label: item.divisionName,
  icon: <Folder color="primary" />,
  children: item.divisions ? transformItemsToOptionsDivisions(item.divisions) : [],
}));

function getOptionsCatalog(data: CatalogTreeItemsResponse| undefined) {
  if (data === undefined) return []
  return transformItemsToOptionsCatalog(data)
}

function getOptionsDivisions(data: DivisionTreeView[]| undefined) {
  if (data === undefined) return []
  return transformItemsToOptionsDivisions(data)
}

export default function ScormAddNew() {
  const navigate = useNavigate();
  const defaultValues = [{
    id: 'IsCourseActive', value: true,
  }]
  const [accountStatus, setAccountStatus] = useState<IFormResponse[]>(defaultValues)
  const [createScormCourse, { isLoading }] = useCreateScormCourseMutation();
  const [treeIds, setTreeids] = useState<number[]>([])
  const [treeIdsDivision, setTreeIdsDivision] = useState<number[]>([])

  const {
    data: dataCatalog,
  } = useGetCatalogTreeItemsQuery();
  const optionsCatalog = getOptionsCatalog(dataCatalog)
  const { data: dataDivisions } = useGetDivisionsTreeViewQuery();
  const optionsDivisions = getOptionsDivisions(dataDivisions)

  const handleAccountStatusChange = (newResponse: IFormResponse) => {
    setAccountStatus((prevState) => updateArrayState(prevState, newResponse));
  };

  const handleSubmitScorm = async (formValues: any) => {
    const scorm: ScormTestRequest = {
      Name: formValues?.Name,
      CreateCourse: true,
      WbtType: formValues?.WbtType,
      GradingType: formValues?.GradingType,
      PassingScore: formValues?.PassingScore,
      MaxStudents: formValues?.MaxStudents,
      IsCourseActive: formValues?.IsCourseActive,
      DivisionIds: treeIdsDivision,
      CatalogIds: treeIds,
      Ownerships: formValues?.Ownerships,
      ScormFile: formValues?.ScormFile,
    }
    try {
      await createScormCourse(scorm).unwrap();
      navigate('/content-library')
      enqueueSnackbar('SCORM Course Created Successfully', { variant: 'success' });
    } catch (err) {
      const message = handleErrorMessage(err as FetchBaseQueryError)
      enqueueSnackbar(message, { variant: 'error' });
    }
  }

  const AccountStatusForm: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: null,
      fieldId: 'Name',
      label: 'Title',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'checkbox',
      options: undefined,
      fieldValue: 'True',
      fieldId: 'IsCourseActive',
      label: 'Active',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'file',
      options: undefined,
      fieldValue: null,
      fieldId: 'ScormFile',
      label: 'ZIP',
      titleSize: 3,
    },
  ]

  const scormFormLearning: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'select',
      options: [
        { key: 6, value: 'SingleSCORM' },
      ],
      fieldValue: null,
      fieldId: 'WbtType',
      label: 'WBT Type',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: true,
      type: 'select',
      options: [
        { key: '1', value: 'Not a graded course' },
      ],
      fieldValue: null,
      fieldId: 'GradingType',
      label: 'Grading',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: null,
      fieldId: 'PassingScore',
      label: 'Passing Score',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: null,
      fieldId: 'MaxStudents',
      label: 'Max Student',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'treeSelect',
      treeOptions: optionsCatalog,
      handleMultiSelectOptions: setTreeids,
      label: 'Availability',
      titleSize: 3,
      fieldValue: '',
      fieldId: '',
    },
    {
      editable: true,
      mandatory: true,
      type: 'treeSelect',
      treeOptions: optionsDivisions,
      handleMultiSelectOptions: setTreeIdsDivision,
      label: 'Divisions',
      titleSize: 3,
      fieldValue: '',
      fieldId: '',
    },
  ];
  const handleSave = () => {
    const formValues = extractValues(accountStatus);
    // eslint-disable-next-line prefer-const
    let errorMessage: string[] = [];
    AccountStatusForm.forEach((field) => {
      if (!formValues[field?.fieldId] && field.mandatory) {
        errorMessage.push(`${field?.label} is required.`)
      }
    })

    if (formValues?.scormType === SCORM_TYPE.CREATE_LEARNING_OBJECT) {
      scormFormLearning.forEach((field) => {
        if (!formValues[field?.fieldId] && field.mandatory) {
          errorMessage.push(`${field?.label} is required.`)
        }
      })
    }

    if (errorMessage?.length) {
      errorMessage.forEach((error) => enqueueSnackbar(`${error}`, { variant: 'error' }))
    } else {
      handleSubmitScorm(formValues)
    }
  };

  return (
    <ViewFormLayout testId="advance">
      <ViewFormLayout.ActionBar>
        <Box>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
            <Button variant="contained" disabled={isLoading} onClick={handleSave}>{BUTTON_TEXT.SAVE}</Button>
          </Tooltip>
        </Box>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        {isLoading ? (
          <>
            <Alert severity="info">Wait here until we confirm your upload is complete.</Alert>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', p: 2 }}>
              <LoadingBox />
            </Box>
          </>

        )
          : (
            <>
              <Box sx={{ width: '100%', bgcolor: 'background.paper', pl: 2 }}>
                <FormView
                  title="Scorm course"
                  style={{ paddingLeft: 1, paddingRight: 1 }}
                  fields={AccountStatusForm}
                  onChangeValues={(obj: IFormResponse) => handleAccountStatusChange(obj)}
                />
              </Box>
              <ScormCourses
                scormFormLearning={scormFormLearning}
                onChangeValues={(obj: IFormResponse) => handleAccountStatusChange(obj)}
              />
            </>
          )}
      </ViewFormLayout.Body>
    </ViewFormLayout>

  )
}
