/* eslint-disable max-len */
import React from 'react';
import {
  Box, FormControl, RadioGroup,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { grey } from '@mui/material/colors';
import { isEven } from 'utils/helpers';
import { EvaluationQuestion } from '.';
import RadioButton from './RadioButton';

interface IQuestion{
  question: EvaluationQuestion;
  questionNum: number;
}

export default function Question({ questionNum, question }: IQuestion) {
  return (
    <Grid container sx={{ backgroundColor: isEven(questionNum) ? 'white' : grey[200], padding: '0 0 1em 0' }}>
      <Grid item xs={12} md={1} lg={1}>
        <Box sx={{
          height: '100%', display: 'flex', alignItems: 'center', padding: '0 1em',
        }}
        >
          <strong>{questionNum}</strong>
        </Box>
      </Grid>
      <Grid item xs={12} md={5} lg={7} sx={{ fontSize: '0.85em' }}>
        <Box>
          <div dangerouslySetInnerHTML={{ __html: question.content }} />
        </Box>
        <Box sx={{ fontSize: '0.85em' }}>
          <textarea
            name=""
            id=""
            style={{
              width: '100%', padding: '1em', color: grey[900], fontFamily: 'inherit',
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box sx={{
          height: '100%', display: 'flex', alignItems: 'center', padding: '0 1em',
        }}
        >
          <FormControl sx={{ width: '100%' }}>
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <Grid container spacing={0} sx={{ width: '100%' }}>
                {
                  React.Children.toArray(question.alternatives.map((alternative) => <RadioButton name={alternative} />))
                }
              </Grid>
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  )
}
