import React, { useState } from 'react'
import { Box, Button } from '@mui/material';
import DialogModal from 'components/DialogModal';
import FormView, { IFormItem } from 'components/FormView';
import { IInstances } from 'redux/interfaces';

interface IFormValue {
    id: string, value: any
  }

export default function InstanceNew({
  openModal, setOpenModal, onCallback,
}: {openModal: boolean, setOpenModal: Function, onCallback: Function }) {
  const [formValue, setFormValue] = useState<IFormValue[]>([]);

  const formItems: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'instanceName',
      label: 'Instance Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: '',
      fieldId: 'randomize',
      label: 'Randomize',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: null,
      fieldId: 'passingScore',
      label: 'Passing Score',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: '',
      fieldId: 'useAllQuestions',
      label: 'Use All Questions',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: '',
      fieldId: 'enforceAttemptLogic',
      label: 'Enforce Attempt Logic',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: 'Unlimited Attempts', value: 'Unlimited Attempts' },
        { key: 'None', value: 'None' },
        { key: '1', value: '1' },
        { key: '2', value: '2' },
        { key: '3', value: '3' },
        { key: '4', value: '4' },
        { key: '5', value: '5' },
        { key: '6', value: '6' },
        { key: '7', value: '7' },
        { key: '8', value: '8' },
        { key: '9', value: '9' },
        { key: '10', value: '10' },
      ],
      fieldValue: 'Unlimited Attempts',
      fieldId: 'attempts',
      label: 'Attempts',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: '0', value: 'No time restriction' },
        { key: '1', value: '1 day' },
        { key: '2', value: '2 days' },
        { key: '3', value: '3 days' },
        { key: '4', value: '4 days' },
        { key: '5', value: '5 days' },
        { key: '6', value: '6 days' },
        { key: '7', value: '7 days' },
        { key: '8', value: '8 days' },
        { key: '9', value: '9 days' },
        { key: '10', value: '10 days' },
      ],
      fieldValue: null,
      fieldId: 'daysBeforeRetake',
      label: 'Days Before Retake',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: '',
      fieldId: 'reviewOnCompletion',
      label: 'Review On Completion',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: '',
      fieldId: 'permitReview',
      label: 'Permit Review',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: '',
      fieldId: 'useAllQuestions',
      label: 'Use All Questions',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: null,
      fieldId: 'numberOfQuestions',
      label: 'Number of Questions',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: '',
      fieldId: 'showFeedback',
      label: 'Show Feedback',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: '0', value: 'Auto submit test with current selections' },
        { key: '1', value: 'User receives no credit (zero) for the attemp' },
        { key: '2', value: 'No penalty, user can continue until attempt is submitted.' },
      ],
      fieldValue: null,
      fieldId: 'timeAllowed',
      label: 'Time Allowed (Minutes)',
      titleSize: 3,
    },
  ];

  const handleFormResponse = (obj: { id: string, value: any }) => {
    setFormValue((_values) => {
      const existingIndex = _values.findIndex((item) => item.id === obj.id);
      return existingIndex !== -1
        ? _values.map((item, index) => (index === existingIndex ? obj : item))
        : [..._values, obj];
    })
  };

  return (
    <DialogModal open={openModal} onClose={() => setOpenModal(false)}>
      <DialogModal.Title>
        New intance
      </DialogModal.Title>
      <DialogModal.Content backgroundGrey>
        <Box sx={{ width: '100%', bgcolor: 'background.paper', pl: 2 }}>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={formItems}
            onChangeValues={(obj: any) => handleFormResponse(obj)}
          />
        </Box>
      </DialogModal.Content>
      <DialogModal.Actions>
        <Button
          variant="contained"
          onClick={() => {
            const transformedObject = formValue.reduce((acc, curr) => {
              acc[curr.id] = curr.value;
              return acc;
            }, {}) as IInstances;
            const id = Math.floor(Math.random() * 999999) + 1;
            onCallback({ ...transformedObject, id })
          }}
        >
          Save Instance
        </Button>
      </DialogModal.Actions>
    </DialogModal>
  )
}
