/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Box, Button, Stack, Tooltip,
} from '@mui/material';
import FormView, { customSelectOption, IFormItem } from 'components/FormView';
import {
  useGetDivisionsTreeItemsQuery, useGetEmploymentTypeQuery, useGetGroupsByFilterMutation,
} from 'services/dataApi';
import {
  DivisionTreeItemsResponse, EmploymentType, GetGroupResponse, GetGroupsRequest, GroupResponse,
  UserGroup,
} from 'services/interfaces';
import { Folder } from '@mui/icons-material';
import { TreeNode } from 'primereact/treenode';
import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import LoadingIcon from 'components/LoadingIcon';

function transformItemsToOptionsDivisions(items: DivisionTreeItemsResponse[]): TreeNode[] {
  return items.map((item) => ({
    key: item.divisionId,
    label: item.divisionName,
    icon: <Folder color="primary" />,
    children: item.divisions ? transformItemsToOptionsDivisions(item.divisions) : [],
  }))
}

function getOptionsDivisions(data: DivisionTreeItemsResponse | undefined) {
  if (data === undefined) return []
  return transformItemsToOptionsDivisions([data])
}

  interface UserEditGroupProps {
    userGroup: UserGroup;
  }

export default function UserEditGroup({ userGroup }: UserEditGroupProps) {
  const navigate = useNavigate();

  const { data: dataDivisions, isLoading: isLoadingDivision } = useGetDivisionsTreeItemsQuery();
  const { data: employmentType, isLoading: isLoadingEmployment } = useGetEmploymentTypeQuery();
  const [getGroupsByFilter, { isLoading: isLoadingGroups }] = useGetGroupsByFilterMutation();

  const optionsDivisions = getOptionsDivisions(dataDivisions)
  const [treeIdsDivision, setTreeIdsDivision] = useState<number[]>([])
  const [groups, setGroups] = useState<customSelectOption[]>([])

  const isLoadingState = isLoadingDivision || isLoadingGroups || isLoadingEmployment;

  const handleSubmit = async () => {
    try {
      console.log({ userGroup, treeIdsDivision })
    } catch (error) {
      console.log({ error })
    }
  }

  const handleFormResponse = (obj: { id: string; value: any }) => {
    console.log(obj);
  };

  const fetchGroupData = async () => {
    try {
      const body: GetGroupsRequest = {
        groupId: null,
        name: null,
        PageNumber: 1,
        PageSize: 999,
        SortingBy: 'groupId',
        Ascending: true,
      }
      const response: GetGroupResponse = await getGroupsByFilter(body).unwrap();
      const group = response?.data?.map(({ groupId, description, name }: GroupResponse) => ({
        value: `${name} - ${description}`,
        key: groupId,
      }))
      setGroups(group)
    } catch (error) {
      console.log({ error })
    }
  };

  const NewUserGroupFields: IFormItem[] = [
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: groups,
      fieldValue: userGroup?.groupId,
      fieldId: 'groupId',
      label: 'Group Id',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: groups,
      fieldValue: userGroup?.groupName,
      fieldId: 'groupName',
      label: 'Group Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: userGroup?.userGroupActive ? 'true' : undefined,
      fieldId: 'userGroupActive',
      label: 'Active',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'select',
      options: employmentType?.map((emT: EmploymentType) => ({ key: emT.id, value: emT.name })),
      fieldValue: '',
      fieldId: 'employmentType',
      label: 'Employment Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: [
        { value: 'Manager', key: 'Manager' },
        { value: 'Developer', key: 'Developer' },
        { value: 'Analyst', key: 'Analyst' },
      ],
      fieldValue: userGroup?.jobPosition,
      fieldId: 'position',
      label: 'Position',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: userGroup?.isPrimaryDivision === 'True' ? 'true' : undefined,
      fieldId: 'isPrimaryDivision',
      label: 'Is Primary Position',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'hireDate',
      label: 'Hire Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'treeSelect',
      treeOptions: optionsDivisions,
      handleMultiSelectOptions: setTreeIdsDivision,
      label: 'Divisions',
      titleSize: 3,
      fieldValue: 'division',
      fieldId: '',
    },
  ];

  useEffect(() => {
    fetchGroupData();
  }, []);

  if (isLoadingState) {
    return (
      <Stack p={2}>
        <LoadingIcon />
      </Stack>
    )
  }

  return (

    <ViewFormLayout pl={0} pr={0} testId="users-list">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
          <Button variant="text" onClick={() => navigate(-1)}>
            {BUTTON_TEXT.BACK}
          </Button>
        </Tooltip>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
          <Button variant="contained" onClick={handleSubmit}>
            {BUTTON_TEXT.SAVE}
          </Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            pl: 2,
            pb: 2,
          }}
        >
          <FormView
            title="Edit User Group"
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={NewUserGroupFields}
            onChangeValues={(obj: { id: string; value: any }) => handleFormResponse(obj)}
          />
        </Box>
      </ViewFormLayout.Body>
    </ViewFormLayout>

  );
}
