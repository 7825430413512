import React, { useState } from 'react';
import CustomTable from 'components/CustomTable';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { IUserInList } from 'redux/interfaces';
import { ACTIVE_STATUS } from 'utils/constants';
// eslint-disable-next-line import/extensions
import users from '../userList.json';

function createData(items: IUserInList[]): TRows[] {
  return items?.map((item: IUserInList) => ({
    ...item,
    details: [],
    id: Number(item.UserId),
    Active: item.Active ? ACTIVE_STATUS.YES : ACTIVE_STATUS.NO,
    isCollapsable: false,
  }));
}

export default function CourseUsersTable() {
  const [dataSet] = useState<TRows[] | []>(createData(users));

  const columns: TColumns[] = [
    {
      name: 'UserId',
      column: 'UserId',
      width: 200,
      sortable: true,
    },
    {
      name: 'Name',
      column: 'Name',
      width: 120,
      sortable: true,
    },
    {
      name: 'User Name',
      column: 'UserName',
      width: 120,
      sortable: true,
    },
    {
      name: 'Employee ID',
      column: 'EmployeeId',
      width: 120,
      sortable: true,
    },
  ];
  return (

    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      showSelectCheckbox={false}
    />
  )
}
