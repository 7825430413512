/* eslint-disable max-len */
import { useState, useEffect } from 'react';

interface IDataSet<T> {
    data: T[];
    totalRows: number;
    currentPage: number;
    currentPageSize: number;
  }

interface UseRemoteTableProps<T, R> {
    fetchData: (request: R) => Promise<{ data: T[]; totalRows: number; currentPage: number; currentPageSize: number }>;
    filters: R;
    sortDefault: string;
  }

export default function useRemoteTable<T, R>({
  fetchData,
  filters,
  sortDefault,
}: UseRemoteTableProps<T, R>) {
  const [dataSet, setDataSet] = useState<IDataSet<T>>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(sortDefault);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [isLoading, setIsLoading] = useState(false);

  const fetchTableData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchData({
        ...filters,
        PageNumber: page + 1,
        PageSize: rowsPerPage,
        SortingBy: sort,
      });
      setDataSet({
        data: response.data,
        totalRows: response.totalRows,
        currentPage: response.currentPage,
        currentPageSize: response.currentPageSize,
      });
    } catch (error) {
      setDataSet({
        data: [],
        totalRows: 0,
        currentPage: 0,
        currentPageSize: 0,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [filters, page, rowsPerPage, sort, sortDirection]);

  return {
    dataSet,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sort,
    setSort,
    sortDirection,
    setSortDirection,
    isLoading,
  };
}
