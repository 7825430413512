import React from 'react'
import { Box } from '@mui/material';
import FormView, { IFormItem } from 'components/FormView';
import { IQuestion } from 'redux/interfaces';

interface IQuestionForm {
    question?: IQuestion,
    onCallback: Function
}
export default function QuestionForm({ question, onCallback }: IQuestionForm) {
  const handleFormResponse = (obj: { id: string, value: any }) => {
    onCallback(obj)
  };

  const QuestionForm: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'textArea',
      options: undefined,
      fieldValue: question?.question,
      fieldId: 'question',
      label: 'Question text',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'textArea',
      options: undefined,
      fieldValue: question?.comments,
      fieldId: 'comments',
      label: 'Comments',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: question?.randomize ? 'True' : undefined,
      fieldId: 'randomize',
      label: 'Randomize',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: question?.displayOrder,
      fieldId: 'displayOrder',
      label: 'Display Order',
      titleSize: 3,
    },
  ];

  return (
    <Box sx={{
      width: '100%', bgcolor: 'background.paper', pl: 2, pb: 2,
    }}
    >
      <FormView
        title="Question"
        style={{ paddingLeft: 1, paddingRight: 1 }}
        fields={QuestionForm}
        onChangeValues={(obj: any) => handleFormResponse(obj)}
      />
    </Box>
  )
}
