/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';

interface IDateInput {
  title?: string,
  onSelect: Function,
  defaultValue?: string,
}
export default function DateInput({
  title, onSelect, defaultValue,
}: IDateInput) {
  const DatePickerStyleded = styled(DatePicker)({
    '.MuiInput-root:before':
    {
      border: 'none',
    },
    '.Mui-error::before': {
      'border-bottom-color': ' rgba(0, 0, 0, 0.42)',
    },
  })
  const [value, setValue] = useState<Dayjs | null>(dayjs(defaultValue));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {title && <Typography data-testid="date-picker-label">{title}</Typography>}
      <DatePickerStyleded
        data-testid="date-picker-input"
        value={value}
        onChange={(x: any) => {
          onSelect(x);
          setValue(x);
        }}
        slotProps={{ textField: { variant: 'standard' } }}
      />
    </LocalizationProvider>
  );
}
