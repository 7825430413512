/* eslint-disable max-len */
import React from 'react'
import { Stack } from '@mui/material'
import LoadingIcon from 'components/LoadingIcon'
import {
  useGetCountriesQuery,
  useGetDivisionsQuery, useGetEmploymentTypeQuery, useGetSchoolsQuery, useGetSpecialtyTypesQuery,
} from 'services/dataApi';
import {
  GetCountryResponse,
  GetDivisionsResponse,
  GetEmploymentTypesResponse,
  GetSchoolResponse, GetSpecialtyTypesResponse,
} from 'services/interfaces';
import AccountInformation from './UserAccountInformation'
import Licenses from './UserLicenses'
import CustomFields from './UserCustomFields'

export default function UserProfile({
  user, isLoadingSave, isLoadingUserData,
}: {user: any, isLoadingSave: boolean, isLoadingUserData: boolean}) {
  const { data: countries, isLoading: isLoadingCountries } = useGetCountriesQuery();
  const { data: schools, isLoading: isLoadingSchools } = useGetSchoolsQuery();
  const { data: employmentType, isLoading: isLoadingEmployment } = useGetEmploymentTypeQuery();
  const { data: specialtyType, isLoading: isLoadingSpecialtyType } = useGetSpecialtyTypesQuery();
  const { data: divisions, isLoading: isLoadingDivision } = useGetDivisionsQuery();

  const isLoadingState = isLoadingCountries || isLoadingSchools || isLoadingSpecialtyType || isLoadingEmployment || isLoadingDivision || isLoadingSave || isLoadingUserData;

  if (isLoadingState) {
    return (
      <Stack p={2}>
        <LoadingIcon />
      </Stack>
    )
  }

  return (
    <Stack p={2}>
      <AccountInformation
        user={user}
        countries={countries as GetCountryResponse}
        schools={schools as GetSchoolResponse}
        employmentType={employmentType as GetEmploymentTypesResponse}
        specialtyType={specialtyType as GetSpecialtyTypesResponse}
        divisions={divisions as GetDivisionsResponse}
      />
      <Licenses />
      <CustomFields />
    </Stack>
  )
}
