import React, { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material';
import FormView, { IFormItem } from 'components/FormView';
import { IFormResponse, SCORM_TYPE } from './constants';
import ScormTypeGroup from './ScormTypeGroup';

function ScormCourses({ scormFormLearning, onChangeValues }:
  {scormFormLearning: IFormItem[], onChangeValues: Function;}) {
  const [scormType, setScormType] = useState<string>(SCORM_TYPE.CREATE_LEARNING_OBJECT);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', p: 2 }}>
      <Grid container>
        <Grid
          item
          xs={3}
          bgcolor={(theme) => theme.palette.grey[100]}
          className="center-vertical"
          sx={{
            justifyContent: 'right', borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`, pl: 1, pr: 1,
          }}
        >
          <Typography>Create Course</Typography>
        </Grid>
        <Grid item xs={9} p={1} className="center-vertical">
          <ScormTypeGroup
            scormType={SCORM_TYPE}
            onChange={(val: string) => {
              setScormType(val)
              onChangeValues({
                id: 'scormType',
                value: val,
              })
            }}
            value={scormType}
          />
        </Grid>
      </Grid>
      {scormType === SCORM_TYPE.CREATE_LEARNING_OBJECT && (
      <Box sx={{ width: '100%', bgcolor: 'background.paper', p: 2 }}>
        <FormView
          title="Create Course"
          style={{ paddingLeft: 1, paddingRight: 1 }}
          fields={scormFormLearning}
          onChangeValues={(obj: IFormResponse) => onChangeValues(obj)}
        />
      </Box>
      )}
    </Box>
  )
}

export default ScormCourses;
