/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react'
import {
  Grid,
  Typography,
} from '@mui/material';

export interface IViewFormLayout {
  testId: string;
  children: React.ReactNode | any,
  [key: string]: string | number | React.ReactNode,
}

export default function ViewFormLayout(
  {
    testId,
    children,
    ...props
  }: IViewFormLayout,
) {
  return (
    <Grid
      container
      key={testId}
      data-testid={testId}
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      gap={1}
      mt={1}
      {...props}
    >
      {children}
    </Grid>
  )
}

ViewFormLayout.ActionBar = function title({ children, hasborderBottom = false }: { children: any, hasborderBottom?: boolean }) {
  return (
    <Grid
      bgcolor={(theme) => theme.palette.background.paper}
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%',
        p: 1,
        gap: 1,
        borderBottom: hasborderBottom ? (theme) => `1px solid ${theme.palette.grey[200]}` : 'none',
      }}
    >
      {children}
    </Grid>
  )
}

ViewFormLayout.FilterBar = function title({ children, hasborderBottom = false }: { children: any, hasborderBottom?: boolean }) {
  return (
    <Grid
      bgcolor={(theme) => theme.palette.background.paper}
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%',
        p: 1,
        borderBottom: hasborderBottom ? (theme) => `1px solid ${theme.palette.grey[200]}` : 'none',
      }}
    >
      {children}
    </Grid>
  )
}

ViewFormLayout.Title = function title({ children, hasborderBottom = false }: { children: any, hasborderBottom?: boolean }) {
  return (
    <Grid
      item
      xs={12}
      p={1}
      bgcolor={(theme) => theme.palette.background.paper}
      className="center-vertical"
      sx={{
        justifyContent: 'left',
        borderBottom: hasborderBottom ? (theme) => `1px solid ${theme.palette.grey[200]}` : 'none',
        height: 'auto',
      }}
    >
      <Typography variant="h6">
        {children}
      </Typography>
    </Grid>
  )
}

ViewFormLayout.Body = function description({ children }: { children: any }) {
  return (
    <Grid
      item
      bgcolor={(theme) => theme.palette.background.paper}
    >
      {children}
    </Grid>
  )
}
