import { useCallback, useEffect, useSyncExternalStore } from 'react';

const dispatchStorageEvent = (key: string, newValue: any) => {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }))
}

const setItem = <T>(key: string, value: T) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue)
  dispatchStorageEvent(key, value)
}

const removeItem = (key: string) => {
  window.localStorage.removeItem(key);
}

const getItem = (key: string) => window.localStorage.getItem(key)

const subscribe = (callback: any) => {
  window.addEventListener('storage', callback);
  return () => window.removeEventListener('storage', callback);
};

const getServerSnapshot = () => {
  throw Error('useLocalstorage is only client-side')
}

export default function useLocalstorage<T>(key: string, initialValue: T) {
  const getSnapshot = () => getItem(key)

  const store = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot)!

  const setState = useCallback((v: Function) => {
    try {
      const nextstate = typeof v === 'function' ? v(JSON.parse(store)) : v;
      if (nextstate === undefined || nextstate === null) {
        removeItem(key)
      } else {
        setItem(key, nextstate);
      }
    } catch (error) {
      console.warn(error)
    }
  }, [key, store])

  useEffect(() => {
    if (getItem(key) === null && typeof initialValue !== 'undefined') {
      setItem(key, initialValue)
    }
  }, [key, initialValue])

  return [store ? JSON.parse(store) : initialValue, setState]
}
