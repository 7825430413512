/* eslint-disable max-len */
import React from 'react';

import { ArcElement, ChartOptions } from 'chart.js';
import {
  Box,
  Stack,
  Button,
  Tooltip,
} from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { filterByStatus } from 'redux/userDashboardSlice';
import { IState } from 'redux/interfaces';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import Chart from 'chart.js/auto';
import SquareIcon from '@mui/icons-material/Square';
import Typography from '@mui/material/Typography';
import {
  COURSE_STATUS_BY_DATE_COLORS, COURSE_STATUS_BY_DATE, COURSE_STATUS_BY_DATE_LABEL, BUTTON_TEXT, COURSE_STATUS_TOOLTIP_TEXT,
} from 'utils/constants';

Chart.register(ArcElement);

function UserStatus() {
  const coursersStats = useSelector((state: IState) => state.userDashboard.coursesStats);

  const hasStats = coursersStats[COURSE_STATUS_BY_DATE_LABEL.Overdue] > 0
    || coursersStats[COURSE_STATUS_BY_DATE_LABEL.Duesoon] > 0
    || coursersStats[COURSE_STATUS_BY_DATE_LABEL.Upcoming] > 0
    || coursersStats[COURSE_STATUS_BY_DATE_LABEL.Future] > 0
    || coursersStats[COURSE_STATUS_BY_DATE_LABEL.Flexible] > 0

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const chartData = {
    labels: [
      COURSE_STATUS_BY_DATE_LABEL.Overdue,
      COURSE_STATUS_BY_DATE_LABEL.Duesoon,
      COURSE_STATUS_BY_DATE_LABEL.Upcoming,
      COURSE_STATUS_BY_DATE_LABEL.Future,
      COURSE_STATUS_BY_DATE_LABEL.Flexible,
    ],
    datasets: [
      {
        data: [
          coursersStats[COURSE_STATUS_BY_DATE_LABEL.Overdue],
          coursersStats[COURSE_STATUS_BY_DATE_LABEL.Duesoon],
          coursersStats[COURSE_STATUS_BY_DATE_LABEL.Upcoming],
          coursersStats[COURSE_STATUS_BY_DATE_LABEL.Future],
          coursersStats[COURSE_STATUS_BY_DATE_LABEL.Flexible],
        ],
        backgroundColor: [
          COURSE_STATUS_BY_DATE_COLORS.Overdue,
          COURSE_STATUS_BY_DATE_COLORS.Duesoon,
          COURSE_STATUS_BY_DATE_COLORS.Upcoming,
          COURSE_STATUS_BY_DATE_COLORS.Future,
          COURSE_STATUS_BY_DATE_COLORS.Flexible,
        ],
      },
    ],
  };

  const handleSliceClick = (elements: any) => {
    if (elements && elements[0]) {
      const element = chartData.labels[elements[0].index];
      dispatch(filterByStatus(element));
    }
  };

  const handleLegendClick = (status: COURSE_STATUS_BY_DATE) => {
    dispatch(filterByStatus(status));
  }

  const chartOptions: ChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    onClick: (event, elements) => handleSliceClick(elements),
  };

  const getStatusIcon = (status: COURSE_STATUS_BY_DATE, value: number) => {
    switch (status) {
      case COURSE_STATUS_BY_DATE_LABEL.Overdue:
        return (
          <Tooltip title={COURSE_STATUS_TOOLTIP_TEXT.OVERDUE}>
            <Box display="flex" alignItems="center" onClick={() => handleLegendClick(COURSE_STATUS_BY_DATE_LABEL.Overdue)} sx={{ cursor: 'pointer' }}>
              <SquareIcon
                style={{
                  color: COURSE_STATUS_BY_DATE_COLORS.Overdue,
                  marginRight: '0.2em',
                  marginTop: '-0.2em',
                }}
              />

              <Typography variant="body1" gutterBottom>
                {`${value} `}
                {status}
              </Typography>
            </Box>
          </Tooltip>
        );
      case COURSE_STATUS_BY_DATE_LABEL.Duesoon:
        return (
          <Tooltip title={COURSE_STATUS_TOOLTIP_TEXT.DUESOON}>
            <Box display="flex" alignItems="center" onClick={() => handleLegendClick(COURSE_STATUS_BY_DATE_LABEL.Duesoon)} sx={{ cursor: 'pointer' }}>
              <SquareIcon
                style={{
                  color: COURSE_STATUS_BY_DATE_COLORS.Duesoon,
                  marginRight: '0.2em',
                  marginTop: '-0.2em',
                }}
              />
              <Typography variant="body1" gutterBottom>
                {`${value} `}
                {status}
              </Typography>
            </Box>
          </Tooltip>
        );

      case COURSE_STATUS_BY_DATE_LABEL.Upcoming:
        return (
          <Tooltip title={COURSE_STATUS_TOOLTIP_TEXT.UPCOMING}>
            <Box display="flex" alignItems="center" onClick={() => handleLegendClick(COURSE_STATUS_BY_DATE_LABEL.Upcoming)} sx={{ cursor: 'pointer' }}>
              <SquareIcon
                style={{
                  color: COURSE_STATUS_BY_DATE_COLORS.Upcoming,
                  marginRight: '0.2em',
                  marginTop: '-0.2em',
                }}
              />
              <Typography variant="body1" gutterBottom>
                {`${value} `}
                {status}
              </Typography>
            </Box>
          </Tooltip>
        );
      case COURSE_STATUS_BY_DATE_LABEL.Future:
        return (
          <Tooltip title={COURSE_STATUS_TOOLTIP_TEXT.FUTURE}>
            <Box display="flex" alignItems="center" onClick={() => handleLegendClick(COURSE_STATUS_BY_DATE_LABEL.Future)} sx={{ cursor: 'pointer' }}>
              <SquareIcon
                style={{
                  color: COURSE_STATUS_BY_DATE_COLORS.Future,
                  marginRight: '0.2em',
                  marginTop: '-0.2em',
                }}
              />
              <Typography variant="body1" gutterBottom>
                {`${value} `}
                {status}
              </Typography>
            </Box>
          </Tooltip>
        );
      case COURSE_STATUS_BY_DATE_LABEL.Flexible:
        return (
          <Tooltip title={COURSE_STATUS_TOOLTIP_TEXT.FLEXIBLE}>
            <Box display="flex" alignItems="center" onClick={() => handleLegendClick(COURSE_STATUS_BY_DATE_LABEL.Flexible)} sx={{ cursor: 'pointer' }}>
              <SquareIcon
                style={{
                  color: COURSE_STATUS_BY_DATE_COLORS.Flexible,
                  marginRight: '0.2em',
                  marginTop: '-0.2em',
                }}
              />
              <Typography variant="body1" gutterBottom>
                {`${value} `}
                {status}
              </Typography>
            </Box>
          </Tooltip>
        );
      default:
        return (
          <Box display="flex" alignItems="center">
            <Typography variant="body1" gutterBottom>
              {`${value} `}
              {status}
            </Typography>
          </Box>
        );
    }
  };
  const handleNavigateContentLibrary = () => {
    navigate('/content-library')
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasStats ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
          <Box width="60%" minHeight={200}>
            <Doughnut data={chartData} options={chartOptions} data-testid="user-courses-stats" />
          </Box>
          <Stack flexDirection="row" flexWrap="wrap" gap={1} justifyContent="center" alignItems="center">
            {React.Children.toArray(
              chartData.datasets[0].data.map((value, index) => {
                if (value <= 0) return null;
                return (
                  <Stack flexDirection="row" flexWrap="wrap" spacing={2}>
                    {getStatusIcon(chartData.labels[index] as COURSE_STATUS_BY_DATE, value)}
                  </Stack>
                );
              }).filter(Boolean),
            )}
          </Stack>
        </Box>
      ) : (
        <Box height="100%" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <Typography variant="body2">You can enroll in new courses now</Typography>
          <Box mt={2}>
            <Button onClick={handleNavigateContentLibrary} variant="contained">{BUTTON_TEXT.FIND_COURSES}</Button>
          </Box>
        </Box>
      )}

    </>
  );
}

export default UserStatus;
