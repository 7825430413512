import React from 'react'
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import ViewFormLayout from 'components/ViewFormLayout';
import CustomTable from 'components/CustomTable';
import { TRows, TColumns } from 'components/CustomTable/interface';
import { ACTIVE_STATUS } from 'utils/constants';
import { UserGroup } from 'services/interfaces';

export default function Groups() {
  const userGroups = useSelector((state: IState) => state.userProfile?.user?.userGroups);

  const columns: TColumns[] = [
    {
      name: 'Group Name',
      column: 'groupName',
      width: 250,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Primary Division',
      column: 'isPrimaryDivision',
      width: 150,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Division',
      column: 'departmentName',
      width: 340,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Primary Job Position',
      column: 'jobPosition',
      width: 120,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Position',
      column: 'jobPositionDescription',
      width: 180,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Active',
      column: 'userGroupActive',
      width: 80,
      sortable: false,
      hidden: false,
    },
  ];

  const dataSet: TRows[] = (userGroups || []).map((userGroup: UserGroup) => ({
    ...userGroup,
    id: userGroup?.userGroupId,
    isCollapsable: false,
    details: [],
    userGroupActive: userGroup?.userGroupActive ? ACTIVE_STATUS.YES : ACTIVE_STATUS.NO,
  }))

  return (
    <ViewFormLayout testId="groups-belong-to">
      <ViewFormLayout.Title>
        Groups I belong to
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <CustomTable
          rows={dataSet}
          columns={columns}
          collapseAllRow={false}
          defaultSort="userGroupActive"
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
