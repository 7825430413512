/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import DialogModal from 'components/DialogModal';
import CustomTable from 'components/CustomTable';
import { Box, Button, Tooltip } from '@mui/material';
import { IQuestion } from 'redux/interfaces';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import ViewFormLayout from 'components/ViewFormLayout';
import useInternalStorage from 'hooks/useInternalStorage';
import { BUTTON_TEXT } from 'utils/constants';

export default function QuestionsNew(
  { openModal, setOpenModal }:
  {openModal: boolean, setOpenModal: Function},
) {
  const { testLibraryId } = useParams();
  const [tableSelection, settableSelection] = useState<(string | number)[]>([]);
  const [questionPool] = useInternalStorage<any[]>('questionPool', []);
  const [, addQuestionList] = useInternalStorage<any[]>(String(testLibraryId), []);

  const navigate = useNavigate();
  const columns: TColumns[] = [
    {
      name: 'ID',
      column: 'id',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Question text',
      column: 'question',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Order',
      column: 'displayOrder',
      width: 150,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Randomize',
      column: 'randomize',
      width: 120,
      sortable: false,
      hidden: false,
    },
    {
      name: '',
      column: 'view',
      width: 120,
      sortable: false,
      hidden: false,
    },
  ];

  const dataSet: TRows[] = questionPool?.map(
    (ques: IQuestion) => ({
      ...ques,
      isCollapsable: false,
      randomize: ques?.randomize ? 'Yes' : 'No',
      details: [],
    }),
  );

  const handleSave = () => {
    const newQuesList = questionPool?.filter((q: IQuestion) => tableSelection.includes(q.id)) as unknown as IQuestion[]

    newQuesList.forEach((ques: IQuestion) => {
      addQuestionList(ques?.id, ques);
    });
    setOpenModal(false);
    enqueueSnackbar('Question Added Successfully', { variant: 'success' });
  };

  return (
    <DialogModal open={openModal} onClose={() => setOpenModal(false)}>
      <DialogModal.Title>
        New question
      </DialogModal.Title>
      <DialogModal.Content>

        <ViewFormLayout
          testId="question-new-pop-up"
          p={1}
          backgroundColor="#f5f5f5"
        >
          <ViewFormLayout.ActionBar>
            <Tooltip title=" Save new question">
              <Button variant="contained" onClick={() => navigate(`/questions/new#${testLibraryId}`)}>New Question</Button>
            </Tooltip>
          </ViewFormLayout.ActionBar>
          <ViewFormLayout.Body>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', pl: 2 }}>
              <CustomTable
                rows={dataSet}
                columns={columns}
                collapseAllRow={false}
                showColumnsControl={false}
                showSelectCheckbox
                onHandleSelectCheckbox={(selected: (string | number)[]) => settableSelection(selected)}
              />
            </Box>
          </ViewFormLayout.Body>
        </ViewFormLayout>

      </DialogModal.Content>
      <DialogModal.Actions>
        <Button
          variant="contained"
          onClick={() => handleSave()}
        >
          {BUTTON_TEXT.SAVE}
        </Button>
        <Button variant="text" onClick={() => setOpenModal(false)}>cancel</Button>
      </DialogModal.Actions>
    </DialogModal>
  )
}
