import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import ManageGroups from 'modules/administrator/ManageGroups';

function ManageGroupsPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Groups'));
  }, []);

  return (
    <Layout>
      <ManageGroups />
    </Layout>
  );
}

export default ManageGroupsPage;
