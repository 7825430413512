import React from 'react';
import { IUserJobPositionRequirements } from 'redux/interfaces';
import ViewFormLayout from 'components/ViewFormLayout';
import CustomTable from 'components/CustomTable';
import { TRows, TColumns } from 'components/CustomTable/interface';

// eslint-disable-next-line import/extensions
import jobPositionRequirements from './jobPositionRequirements.json';

export default function JobPositionRequirements() {
  const columns: TColumns[] = [
    {
      name: 'Requirement',
      column: 'Requirement',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Name',
      column: 'Name',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Rating',
      column: 'Rating',
      width: 150,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Comments',
      column: 'Comments',
      width: 150,
      sortable: true,
      hidden: false,
    },
  ];

  const dataSet: TRows[] = (jobPositionRequirements || []).map(
    (item: IUserJobPositionRequirements) => ({
      ...item,
      id: item.Id,
      isCollapsable: false,
      details: [],
      // eslint-disable-next-line comma-dangle
    })
  );

  return (
    <ViewFormLayout testId="available-development-plan">
      <ViewFormLayout.Title>
        Available Job Position Requirements
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <CustomTable rows={dataSet} columns={columns} collapseAllRow={false} />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
