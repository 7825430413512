import React from 'react';
import { Box } from '@mui/material';
import ViewFormLayout from 'components/ViewFormLayout';
import FormView, { IFormItem } from 'components/FormView';
import Questions from './Questions';

// eslint-disable-next-line import/extensions
import evaluationPreview from './evaluationPreview.json';

export default function Evaluation() {
  const EvaluationForm: IFormItem[] = [
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: evaluationPreview.CourseName,
      fieldId: 'CourseName',
      label: 'Course Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: evaluationPreview.Instructors,
      fieldId: 'Instructors',
      label: 'Instructors',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: evaluationPreview.EvaluationDate,
      fieldId: 'EvaluationDate',
      label: 'Evaluation Date',
      titleSize: 3,
    },
  ];

  const CommentsForm: IFormItem[] = [
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: evaluationPreview.ContentComments,
      fieldId: 'ContentComments',
      label: 'Content Comments',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: evaluationPreview.ExerciseComments,
      fieldId: 'ExerciseComments',
      label: 'Exercise Comments',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: evaluationPreview.ManagementComments,
      fieldId: 'ManagementComments',
      label: 'Magamenent Comments',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: evaluationPreview.InstructorComments,
      fieldId: 'InstructorComments',
      label: 'Instructor Comments',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: evaluationPreview.GeneralComments,
      fieldId: 'GeneralComments',
      label: 'General Comments',
      titleSize: 3,
    },
  ];

  return (
    <>
      <Box>
        <ViewFormLayout testId="evaluation">
          <ViewFormLayout.Title>
            Evaluation
          </ViewFormLayout.Title>
          <ViewFormLayout.Body>
            <FormView
              style={{ paddingLeft: 1, paddingRight: 1 }}
              fields={EvaluationForm}
              onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
            />
            <Questions questions={evaluationPreview.Questions} />
          </ViewFormLayout.Body>
        </ViewFormLayout>
      </Box>

      <Box mt={5}>
        <ViewFormLayout testId="evaluation">
          <ViewFormLayout.Title>
            Instructor Evaluation
          </ViewFormLayout.Title>
          <Questions questions={evaluationPreview.Questions} />
        </ViewFormLayout>
      </Box>

      <Box mt={5}>
        <ViewFormLayout testId="evaluation">
          <ViewFormLayout.Title>
            Comments
          </ViewFormLayout.Title>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={CommentsForm}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout>
      </Box>
    </>
  )
}
