/* eslint-disable max-len */
import React from 'react';
import { Typography, Chip, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { filterApplied } from './interface';
import { removeFilterValues } from './helpers';

interface FilterAppliedChipsProps {
    query: string,
    filterApplied: filterApplied[],
    onSetQuery: Function,
    onSettFilterApplied: Function,
    externalFilters?: React.ReactNode;
}
function FilterAppliedChips({
  query, filterApplied, onSetQuery, onSettFilterApplied, externalFilters,
} : FilterAppliedChipsProps) {
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      gap={2}
    >
      { query && (
      <Stack flexDirection="row" justifyItems="center" alignItems="center" p={1} gap={1} display={{ border: `1px dotted ${grey[200]}`, borderRadius: '8px' }}>
        <Typography variant="subtitle2">Search: </Typography>
        <Chip
          label={query}
          variant="outlined"
          onDelete={() => onSetQuery('')}
        />
      </Stack>

      )}
      {
          React.Children.toArray(filterApplied.map((filter: filterApplied) => (
            <Stack flexDirection="row" justifyItems="center" alignItems="center" p={1} gap={1} display={{ border: `1px dotted ${grey[200]}`, borderRadius: '8px' }}>
              <Typography variant="subtitle2">{`${filter?.filterLabel}:`}</Typography>
              {React.Children.toArray(filter.filterValue.map((val) => (
                <Chip
                  label={val}
                  variant="outlined"
                  onDelete={() => {
                    onSettFilterApplied((_fil: filterApplied[]) => removeFilterValues(_fil, filter?.filterId, [val]))
                    if (filter?.removeAction) {
                      filter?.removeAction()
                    }
                  }}
                />
              )))}
            </Stack>
          )))
        }
      {externalFilters}
    </Stack>
  );
}

export default FilterAppliedChips;
