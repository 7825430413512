import React, { useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import './YellowfinWidget.scss';
import { useParams } from 'react-router-dom';
import { useGetYellowFinTokenQuery } from 'services/dataApi';
import { IState } from 'redux/interfaces';
import { useSelector } from 'react-redux';

export default function YellowfinWidget() {
  const { yellowfinClientOrgRef } = useSelector((state: IState) => state.userProfile.yellowFin);
  const containerRef = useRef(null);
  let yfLoginToken = '';
  const initialized = useRef(false);
  const { reportId } = useParams();

  const {
    data: yellowfinSSOResponse,
    isLoading: isLoadingYellowfinSSOToken,
  } = useGetYellowFinTokenQuery(yellowfinClientOrgRef);

  useEffect(() => {
    if (!initialized.current && containerRef.current && !isLoadingYellowfinSSOToken) {
      yfLoginToken = yellowfinSSOResponse.securityToken;
      initialized.current = true;
      /* eslint-disable */
      document.querySelector('div#reportDiv')!.innerHTML = '';
      window['yellowfin'].init().then(() => {
        window['yellowfin'].newSession(yfLoginToken, yellowfinClientOrgRef).then(() => {
          window['yellowfin'].loadReport({
            reportId: reportId,
            element: document.querySelector('div#reportDiv'),
            showToolbar: true,
            showExport: false,
            showShare: false,
            showInfo: false,
            width: 1200,
            displayType: ['REPORT', 'CHART']
          });
        });
      });
      /* eslint- */
    }
  }, [yellowfinSSOResponse]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <div id="reportDiv" ref={containerRef} />
    </Box>
  );
}
