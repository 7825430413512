import React from 'react'
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import { Box, CardMedia, Typography } from '@mui/material';
import DialogModal from 'components/DialogModal';
import useLocalstorage from 'hooks/useLocalstorage';

export default function UserHighlightPopup() {
  const welcomeVideo = useSelector((state: IState) => state.userProfile.welcomeVideo)
  const userId = useSelector((state: IState) => state.userProfile.userId)
  const [showUserHighlightPopup, setShowUserHighlightPopup] = useLocalstorage<boolean>(`showUserHighlightPopup-${userId}`, true)

  return (
    <DialogModal open={showUserHighlightPopup} onClose={() => setShowUserHighlightPopup(false)}>
      <DialogModal.Title>
        {welcomeVideo.welcomeMessageTitle}
      </DialogModal.Title>
      <DialogModal.Content>
        <Box mb={2}>
          <Typography variant="h5" gutterBottom>
            {welcomeVideo.welcomeMessageBodyText}
          </Typography>
        </Box>
        <CardMedia
          height="600vh"
          component="iframe"
          title="Titulo"
          src={welcomeVideo.welcomeMessageVideoURL}
        />
        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            {welcomeVideo.welcomeMessageFooter}
          </Typography>
        </Box>
      </DialogModal.Content>
    </DialogModal>
  )
}
