import React from 'react';
import './shared.scss';
import ViewFormLayout from 'components/ViewFormLayout';

import MyCourses from './MyCourses';

export default function InformationCourses() {
  return (
    <ViewFormLayout testId="user-dashboard-courses">
      <ViewFormLayout.Body>
        <MyCourses />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
