import React, { JSX } from 'react';
import {
  COURSE_STATUS_BY_DATE,
  COURSE_STATUS_BY_DATE_COLORS,
  COURSE_STATUS_BY_DATE_LABEL,
  COURSE_STATUS_TOOLTIP_TEXT,
} from 'utils/constants';
import {
  Box,
  Tooltip,
} from '@mui/material';
import SquareIcon from 'components/SquareIcon';

export default function getStatusIconByDueDate(
  status: COURSE_STATUS_BY_DATE,
  dueDate: string,
): JSX.Element {
  switch (status) {
    case COURSE_STATUS_BY_DATE_LABEL.Overdue:
      return (
        <Tooltip title={COURSE_STATUS_TOOLTIP_TEXT.OVERDUE}>
          <Box display="flex" alignItems="center" gap={1}>
            <SquareIcon color={COURSE_STATUS_BY_DATE_COLORS.Overdue} size={20} />
            {dueDate}
          </Box>
        </Tooltip>
      );
    case COURSE_STATUS_BY_DATE_LABEL.Duesoon:
      return (
        <Tooltip title={COURSE_STATUS_TOOLTIP_TEXT.DUESOON}>
          <Box display="flex" alignItems="center" gap={1}>
            <SquareIcon color={COURSE_STATUS_BY_DATE_COLORS.Duesoon} size={20} />
            {dueDate}
          </Box>
        </Tooltip>
      );
    case COURSE_STATUS_BY_DATE_LABEL.Upcoming:
      return (
        <Tooltip title={COURSE_STATUS_TOOLTIP_TEXT.UPCOMING}>
          <Box display="flex" alignItems="center" gap={1}>
            <SquareIcon color={COURSE_STATUS_BY_DATE_COLORS.Upcoming} size={20} />
            {dueDate}
          </Box>
        </Tooltip>
      );
    case COURSE_STATUS_BY_DATE_LABEL.Future:
      return (
        <Tooltip title={COURSE_STATUS_TOOLTIP_TEXT.FUTURE}>
          <Box display="flex" alignItems="center" gap={1}>
            <SquareIcon color={COURSE_STATUS_BY_DATE_COLORS.Future} size={20} />
            {dueDate}
          </Box>
        </Tooltip>
      );
    case COURSE_STATUS_BY_DATE_LABEL.Flexible:
      return (
        <Tooltip title={COURSE_STATUS_TOOLTIP_TEXT.FLEXIBLE}>
          <Box display="flex" alignItems="center" gap={1}>
            <SquareIcon color={COURSE_STATUS_BY_DATE_COLORS.Flexible} size={20} />
            {dueDate}
          </Box>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={COURSE_STATUS_TOOLTIP_TEXT.FLEXIBLE}>
          <Box display="flex" alignItems="center" gap={1}>
            <SquareIcon color={COURSE_STATUS_BY_DATE_COLORS.Flexible} size={20} />
            {dueDate}
          </Box>
        </Tooltip>
      );
  }
}
