import {
  useTheme, useMediaQuery,
} from '@mui/material';

type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
function useResizeScreen(): ScreenSize {
  const theme = useTheme();
  let screenSize: ScreenSize = 'sm';
  if (useMediaQuery(theme.breakpoints.between(0, 599))) screenSize = 'xs';
  if (useMediaQuery(theme.breakpoints.between(600, 899))) screenSize = 'sm';
  if (useMediaQuery(theme.breakpoints.between(900, 1199))) screenSize = 'md';
  if (useMediaQuery(theme.breakpoints.between(1200, 1535))) screenSize = 'lg';
  if (useMediaQuery(theme.breakpoints.up(1535))) screenSize = 'xl';

  return screenSize;
}

export default useResizeScreen;
