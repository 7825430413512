import React from 'react';
import Layout from 'components/Layout';
import AdminUserProfile from 'modules/administrator/AdminUserProfile';
import { useParams } from 'react-router-dom';
import { FormProvider } from 'modules/administrator/AdminUserProfile/FormContext';

function AdminUserProfilePage() {
  const { userId } = useParams();

  return (
    <Layout>
      <FormProvider>
        <AdminUserProfile id={userId as string} />
      </FormProvider>
    </Layout>
  );
}

export default AdminUserProfilePage;
