import React, { useState } from 'react'
import FormView, { IFormItem } from 'components/FormView';
import { useParams } from 'react-router-dom';
import ViewFormLayout from 'components/ViewFormLayout';
// eslint-disable-next-line import/extensions
import courses from '../courses.json'

export default function CourseInformation() {
  const { courseId } = useParams();
  const [data] = useState(courses?.find((co) => String(co?.id) === String(courseId)));

  const AccountStatusForm: IFormItem[] = [
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: data?.active ? 'true' : undefined,
      fieldId: 'active',
      label: 'Active',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: '2', value: 'Online' },
        { key: '1', value: 'ITL' },
        { key: '-5', value: 'Certification' },
      ],
      fieldValue: String(data?.type),
      fieldId: 'type',
      label: 'Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.name),
      fieldId: 'name',
      label: 'Name',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.description),
      fieldId: 'description',
      label: 'Description',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.courseNumber),
      fieldId: 'number',
      label: 'Number',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: '1', value: 'Pass / Fail Only' },
        { key: '2', value: 'Pass' },
        { key: '3', value: 'Fail' },
      ],
      fieldValue: String(data?.grading),
      fieldId: 'grading',
      label: 'Grading',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: data?.passingScore,
      fieldId: 'passingScore',
      label: 'Passing Score',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.creditUnits),
      fieldId: 'creditUnits',
      label: 'Credit Units',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.creditHours),
      fieldId: 'creditHours',
      label: 'Credit Hours',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.creditOthers),
      fieldId: 'creditOthers',
      label: 'Credit Others',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: '1', value: 'Graded' },
        { key: '2', value: 'Peer Review' },
        { key: '3', value: 'Self-Paced Course Evaluation' },
      ],
      fieldValue: String(data?.evaluation),
      fieldId: 'evaluation',
      label: 'Evaluation',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: ' ', value: 'Select One' },
        { key: '1', value: 'Weekly' },
        { key: '2', value: 'Monthly' },
      ],
      fieldValue: String(data?.reminder || ' '),
      fieldId: 'reminder',
      label: 'Reminder',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: ' ', value: 'Select One' },
        { key: '1', value: 'Paper One' },
        { key: '2', value: 'Paper two' },
      ],
      fieldValue: String(data?.paperCertificate || ' '),
      fieldId: 'paperCertificate',
      label: 'Paper Certificate',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: ' ', value: 'Select One' },
        { key: '1', value: 'Vendor One' },
        { key: '2', value: 'Vendor two' },
      ],
      fieldValue: String(data?.trainingVendor || ' '),
      fieldId: 'trainingVendor',
      label: 'Training Vendor',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.vendorCode),
      fieldId: 'vendorCode',
      label: 'Vendor Code',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.duration),
      fieldId: 'duration',
      label: 'Duration',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: data?.onlyForCertification ? 'true' : undefined,
      fieldId: 'onlyForCertification',
      label: 'Only for Certification',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.wbtType),
      fieldId: 'wbtType',
      label: 'WBT Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.wbtType),
      fieldId: 'wbtType',
      label: 'WBT Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.wbtURL),
      fieldId: 'wbtURL',
      label: 'WBT URL',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.scormCourse),
      fieldId: 'scormCourse',
      label: 'Scorm Course',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.listPrice),
      fieldId: 'listPrice',
      label: 'List Price',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.version),
      fieldId: 'version',
      label: 'Version',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: data?.webConference ? 'true' : undefined,
      fieldId: 'webConference',
      label: 'Web Conference',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: data?.allowROMS ? 'true' : undefined,
      fieldId: 'allowROMS',
      label: 'Allow ROMS',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(data?.dependents),
      fieldId: 'dependents',
      label: 'Dependents',
      titleSize: 3,
    },
  ];

  return (
    <ViewFormLayout testId="course-information">
      <ViewFormLayout.Title>
        Course Information
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <FormView
          style={{ paddingLeft: 1, paddingRight: 1 }}
          fields={AccountStatusForm}
          onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
