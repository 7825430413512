/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React from 'react';

export interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    // eslint-disable-next-line no-unused-vars
    event: React.MouseEvent<HTMLButtonElement>,
    // eslint-disable-next-line no-unused-vars
    newPage: number
  ) => void;
}

export type TRows = {
  [key: string]: string | number | React.ReactNode,
  id: number,
  isCollapsable: boolean,
  details: any[],
  courseName?: string;
};

export type TColumns = {
  name: string,
  column: string,
  width?: number,
  height?: number,
  padding?: number,
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right',
  alignTitle?:'center' | 'inherit' | 'justify' | 'left' | 'right',
  sortable: boolean;
  hidden?: boolean;
};

export enum TableSortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
  UNSORTED = ''
}
export interface ICustomPaginationActionsTable {
  rows: TRows[];
  columns: TColumns[];
  collapseAllRow?: boolean;
  handleHidden?: Function;
  showSelectCheckbox?: boolean;
  showColumnsControl?: boolean;
  colSpanGroupTitle?: number;
  onHandleSelectCheckbox?: Function;
  isSingleSelection?: boolean;
  actions?: React.ReactNode;
  page: number,
  setPage: Function,
  rowsPerPage: number,
  setRowsPerPage: Function,
  totalRecords: number,
  sortDirection: string,
  setSortDirection: Function,
  sort: string,
  setSort: Function,
}

export interface ITableHeader {
  column: TColumns;
  sort?: string;
  sortDirection: string;
  handleDescendingSort: Function
  handleAscendingSort: Function
}
