/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { enqueueSnackbar } from 'notistack';
import { IFormValue } from 'utils/interfaces';
import { IQuestion, IState } from 'redux/interfaces';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AppBar, Box, Button, Grid, Stack, Tab, Tabs, Tooltip,
} from '@mui/material';
import AnswerForm from 'modules/administrator/AdminTestLibraries/Answers/Form';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import QuestionForm from 'modules/administrator/AdminTestLibraries/Questions/Form';
import useInternalStorage from 'hooks/useInternalStorage';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function EditQuestion() {
  const { questionId } = useParams();
  const goBackTo = useSelector((state: IState) => state.userProfile.goBackTo);
  const location = useLocation();
  const hash = location.hash.substring(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(0);
  const [questionPool, addQuestionPoolValue] = useInternalStorage<any[]>('questionPool', []);
  const [, addQuestionListValue] = useInternalStorage<any[]>(hash, []);

  const ques: IQuestion = questionPool?.find((t: IQuestion) => t.id === Number(questionId))
  const form = Object.entries(ques).map(([id, value]) => ({ id, value })) || [];

  const [formValue, setFormValue] = useState<IFormValue[]>(form);

  useEffect(() => {
    dispatch(setNavigationPage('Question detail'));
  }, []);

  if (!questionId) return <> </>;

  const handleFormResponse = (obj: { id: string, value: any }) => {
    setFormValue((_values) => {
      const existingIndex = _values?.findIndex((item) => item.id === obj.id);
      return existingIndex !== -1
        ? _values?.map((item, index) => (index === existingIndex ? obj : item))
        : [..._values, obj];
    })
  };
  const goBackHandler = () => {
    navigate(goBackTo || '/questions');
  }
  const handleSave = () => {
    const question = formValue?.reduce((result, item) => {
      result[item.id] = item.value;
      return result;
    }, {}) as IQuestion;

    addQuestionPoolValue(question.id, question);
    if (hash) {
      addQuestionListValue(question.id, question)
    }
    goBackHandler();
    enqueueSnackbar('Question Edited Successfully', { variant: 'success' });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={1}>
      <Stack
        p={1}
        flexDirection="row"
        justifyContent="flex-end"
        gap={1}
        bgcolor={(theme) => theme.palette.background.paper}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
          height: 'auto',
          width: '100%',
          marginBottom: 1,
        }}
      >
        <Stack flexDirection="row" gap={1}>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
            <Button variant="contained" onClick={handleSave}>{BUTTON_TEXT.SAVE}</Button>
          </Tooltip>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => goBackHandler()}>{BUTTON_TEXT.BACK}</Button>
          </Tooltip>
        </Stack>
      </Stack>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <AppBar position="static" sx={{ bgcolor: 'background.paper' }}>
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab
              icon={<PersonOutlineIcon />}
              iconPosition="start"
              label="Question"
            />
            <Tab
              icon={<GroupsIcon />}
              iconPosition="start"
              label="Answer"
            />
          </Tabs>
        </AppBar>
        <CustomTabPanel value={value} index={0}>
          <Box sx={{
            width: '100%', bgcolor: 'background.paper', pl: 2,
          }}
          >
            <QuestionForm
              onCallback={(obj: IFormValue) => handleFormResponse(obj)}
              question={ques}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box sx={{
            width: '100%', bgcolor: 'background.paper', pl: 2,
          }}
          >
            <AnswerForm
              onCallback={(obj: IFormValue) => handleFormResponse(obj)}
              question={ques}
            />
          </Box>
        </CustomTabPanel>
      </Box>
    </Grid>
  );
}
