/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import ResourceCenterHeroImage from '../images/resource_center_hero.webp';
import ProductCertificateImage from '../images/product_certificate.svg';
import ProductCalendarImage from '../images/product_calendar.svg';

import './welcome.scss';

export default function Welcome() {
  return (
    <Paper style={{ minWidth: '100%' }}>
      <Box
        display="flex"
        paddingTop="10em"
        paddingBottom="10em"
        maxWidth="1250px"
        marginLeft="auto"
        marginRight="auto"
        justifyContent="space-between"
      >
        <>
          <Box
            display="flex"
            width="55%"
            padding={2}
            justifyContent="center"
            position="relative"
          >
            <Box position="relative">
              <div className="hero-image">
                <img
                  className="hero-image"
                  src={ResourceCenterHeroImage}
                  sizes="(max-width: 479px) 85vw, (max-width: 767px) 300px, (max-width: 991px) 33vw, 300px"
                  alt="resouce center hero"
                />
              </div>
            </Box>
            <img
              className="product-image certificate"
              src={ProductCertificateImage}
              alt="Certificate"
              loading="lazy"
            />
            <img
              className="product-image calendar"
              src={ProductCalendarImage}
              alt="Calendar"
              loading="lazy"
            />
          </Box>
          <Box width="40%" padding={2}>
            <Box position="relative">
              <h2 className="h2-learnsoft">
                <span className="text-color-red">Welcome</span>
                &nbsp;to your Learning Dashboard
              </h2>
              <div className="type-accent-square" />
            </Box>
            <Box>
              <p className="p-learnsoft">
                You don&apos;t have any assigned courses yet. You can start enrolling in courses now.
              </p>
            </Box>
            <Box pl={4}>
              <Link to="/content-library" className="btn-yellow">
                Find courses
              </Link>
            </Box>
          </Box>
        </>
      </Box>
    </Paper>
  );
}
