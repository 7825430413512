import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { setNavigationPage } from 'redux/userProfileSlice';
import Layout from 'components/Layout';
import {
  Box, Button, Grid, Tooltip,
} from '@mui/material';
import EvaluationDetails from 'modules/EvaluationDetails';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import DialogModal from 'components/DialogModal';
import EvaluationPreview from 'modules/administrator/AdminEvaluations/EvaluationPreview';

function MyCourseDetailsPage() {
  const [openEvaluationPreview, setOpenEvaluationPreview] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { evaluationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let navPageName = 'Evaluation: ';
    if (evaluationId) {
      navPageName += ` ${evaluationId}`
    }
    dispatch(setNavigationPage(navPageName))
  }, [evaluationId, dispatch]);

  const handleClose = () => {
    setOpenEvaluationPreview(false)
  }

  const handleSave = () => {
    setOpenEvaluationPreview(false)
    enqueueSnackbar('Evaluation Saved Successfully', { variant: 'success' });
  }

  const handleClone = () => {
    enqueueSnackbar('Evaluation Cloned Successfully', { variant: 'success' });
    navigate('/admin/evaluations')
  }

  const handleSaveAndRedirect = () => {
    enqueueSnackbar('Evaluation Saved Successfully', { variant: 'success' });
    navigate('/admin/evaluations')
  }

  return (
    <Layout>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
          p={1}
          bgcolor={(theme) => theme.palette.background.paper}
          className="center-vertical"
          sx={{
            justifyContent: 'right',
            borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
            height: 'auto',
            marginBottom: 1,
          }}
        >
          <Box display="flex" gap={1}>

            <Box>
              <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.CLONE}>
                <Button variant="contained" onClick={handleClone}>{BUTTON_TEXT.CLONE}</Button>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.PREVIEW}>
                <Button variant="contained" onClick={() => setOpenEvaluationPreview(true)}>{BUTTON_TEXT.PREVIEW}</Button>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
                <Button variant="contained" onClick={handleSaveAndRedirect}>{BUTTON_TEXT.SAVE}</Button>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
                <Button variant="text" onClick={() => navigate('/admin/evaluations')}>{BUTTON_TEXT.CANCEL}</Button>
              </Tooltip>
            </Box>
          </Box>

        </Grid>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <EvaluationDetails />
        </Box>
        <DialogModal open={openEvaluationPreview} onClose={handleClose}>
          <DialogModal.Title>
            Evaluation Preview
          </DialogModal.Title>
          <DialogModal.Content backgroundGrey>
            <EvaluationPreview
              handleClose={handleClose}
              handleSave={handleSave}
            />
          </DialogModal.Content>
        </DialogModal>
      </Grid>
    </Layout>
  );
}

export default MyCourseDetailsPage;
