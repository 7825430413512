import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch } from 'react-redux';
import Layout from 'components/Layout';
import ScormAddNew from 'modules/administrator/AdminScormCourses/ScormCoursesDetail/ScormAddNew';

function ScormCursesNew() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('New SCORM Course'))
  }, []);

  return (
    <Layout>
      <Box sx={{ width: '100%' }}>
        <ScormAddNew />
      </Box>
    </Layout>
  );
}

export default ScormCursesNew;
