/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import { IEvaluation } from 'redux/interfaces';
import { TColumns, TRows } from 'components/CustomTable/interface';
import CustomTable from 'components/CustomTable';
import DialogModal from 'components/DialogModal';
import { useSnackbar } from 'notistack';
import EvaluationPreview from '../EvaluationPreview';
// eslint-disable-next-line import/extensions
import evaluationsList from '../evaluations.json'

type OpenModalFunction = (evaluationId: number) => void;
type EditEvaluationFunction = () => void;

function getActionButtons(openModalFn: OpenModalFunction, editEvaluationFn: EditEvaluationFunction, evaluationId: number) {
  return (
    <Box display="flex">
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.EDIT_EVALUATION}>
        <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={editEvaluationFn}>
          <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.CLONE_EVALUATION}>
        <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => {}}>
          <ContentCopyIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.PREVIEW_EVALUATION}>
        <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => openModalFn(evaluationId)}>
          <VisibilityIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

function createData(items: IEvaluation[], openModalFn: OpenModalFunction, editEvaluationFn: EditEvaluationFunction): TRows[] {
  return items?.map((item: IEvaluation) => ({
    ...item,
    preview: getActionButtons(openModalFn, editEvaluationFn, item.Id),
    Name: item.Name,
    details: [],
    id: Number(item.Id),
    isCollapsable: false,
  }));
}

export default function EvaluationsTable() {
  const [openEvaluationPreview, setOpenEvaluationPreview] = useState(false);
  const evaluationId = useRef<number>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpenModal = (id: number) => {
    setOpenEvaluationPreview(true);
    evaluationId.current = id;
  };
  const handleEditEvaluation = () => navigate('/admin/evaluations/details/1')
  const [dataSet] = useState<TRows[] | []>(createData(evaluationsList, handleOpenModal, handleEditEvaluation));

  const columns: TColumns[] = [
    {
      name: 'ID',
      column: 'Id',
      width: 200,
      sortable: true,
    },
    {
      name: 'Name',
      column: 'Name',
      width: 200,
      sortable: true,
    },
    {
      name: 'Type',
      column: 'Type',
      width: 200,
      sortable: true,
    },
    {
      name: 'Questions',
      column: 'Questions',
      width: 200,
      sortable: true,
    },
    {
      name: 'Actions',
      column: 'preview',
      width: 120,
      sortable: false,
    },
  ];

  const handleClose = () => {
    setOpenEvaluationPreview(false);
  }

  const handleSave = () => {
    setOpenEvaluationPreview(false);
    enqueueSnackbar('Evaluation Saved Successfully', { variant: 'success' });
  }

  return (

    <>
      <CustomTable
        rows={dataSet}
        columns={columns}
        collapseAllRow={false}
        showSelectCheckbox={false}
      />
      <DialogModal open={openEvaluationPreview} onClose={() => setOpenEvaluationPreview(false)}>
        <DialogModal.Title>
          Evaluation Preview
        </DialogModal.Title>
        <DialogModal.Content backgroundGrey>
          <EvaluationPreview handleClose={handleClose} handleSave={handleSave} evaluationId={evaluationId.current} />
        </DialogModal.Content>
      </DialogModal>
    </>

  )
}
