import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function ScormTypeGroup(
  { scormType, value, onChange } :
  {scormType: {[key:string]: string}, value: string, onChange: Function},
) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value)
  };
  return (
    <FormControl>
      <RadioGroup
        row
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel disabled value={scormType.CREATE_LEARNING_OBJECT} control={<Radio />} label="Yes" />
        <FormControlLabel disabled value={scormType.NONE} control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
}
