import React from 'react';
import ViewFormLayout from 'components/ViewFormLayout';
import FormView, { IFormItem } from 'components/FormView';

export default function NewUserForm() {
  const personalInformationForm: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'GroupName',
      label: 'Group Name',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'GroupDescription',
      label: 'Group Description',
      titleSize: 3,
    },
  ]

  const internalInformationForm: IFormItem[] = [
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'CreatedBy',
      label: 'Created By',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'CreatedDate',
      label: 'Created Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'input',
      options: undefined,
      fieldValue: '',
      fieldId: 'Updated By',
      label: 'Updated By',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'date',
      options: undefined,
      fieldValue: '',
      fieldId: 'UpdatedDate',
      label: 'Updated Date',
      titleSize: 3,
    },
  ]

  return (
    <>
      <ViewFormLayout testId="personal-information">
        <ViewFormLayout.Title>
          Personal Information
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={personalInformationForm}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
      <ViewFormLayout testId="employee-information">
        <ViewFormLayout.Title>
          Employee Information
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={internalInformationForm}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
    </>
  )
}
