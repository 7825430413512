import React from 'react';
import { InputLabel, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { TestQuestion } from 'services/interfaces';

function TextBox({ question, handleResponse } :
  {question: TestQuestion, handleResponse: Function}) {
  return (
    <Stack>
      <InputLabel>
        <Typography variant="body1">{question?.testQuestionText}</Typography>
        {question.answerSummary && <Typography variant="caption">{question.answerSummary}</Typography>}
      </InputLabel>
      <TextField
        variant="outlined"
        id={String(question.testQuestionId)}
        multiline
        rows={3}
        fullWidth
        autoFocus
        margin="normal"
        onChange={(e) => handleResponse({ id: e?.target.id, value: e?.target?.value })}
      />
    </Stack>
  );
}

export default TextBox;
