/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { BaseQueryApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IState } from 'redux/interfaces';
import handleUnauthorizedResponse from './serviceHelper';

const learnsoftGrantType = 'credentials';
const baseUrlApi = process.env.REACT_APP_SUCCESSION_PLANNING_BASE_URL;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlApi,
    prepareHeaders: (headers: Headers, api: Pick<
      BaseQueryApi,
      'getState' | 'extra' | 'endpoint' | 'type' | 'forced'
    >) => {
      const getState = api?.getState() as IState;
      const { accessToken } = getState.userProfile || null;

      // If we have a token set in state, let's assume that we should be passing it.
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
    validateStatus: (response: Response, body: any): boolean => {
      if (response.status === 401) {
        return handleUnauthorizedResponse()
      }
      return true;
    },
  }),

  endpoints: (builder) => ({
    getLoginUser: builder.mutation({
      async queryFn(
        args,
        { signal, dispatch, getState },
        extraOptions,
        fetchWithBQ,
      ) {
        try {
          const { password, userId, clientId } = args;

          const data = {
            ClientId: clientId,
            UserId: userId,
            Password: password,
            GrandType: learnsoftGrantType,
          }

          const logginResult: any = await fetchWithBQ({
            url: 'auth/token/user',
            method: 'POST',
            body: new URLSearchParams(data),
          });

          if (logginResult.error) {
            return {
              error: logginResult.error,
            }
          }

          return logginResult;
        } catch {
          return { error: { error: 'An error on loggin process happens' } };
        }
      },
    }),
    getCompanyDetails: builder.query({
      query: (ClientId) => ({
        url: `auth/token/company/${ClientId}/configuration`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetLoginUserMutation,
  useGetCompanyDetailsQuery,
} = authApi;
