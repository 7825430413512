import React from 'react'
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import { TRows, TColumns } from 'components/CustomTable/interface';
import ViewFormLayout from 'components/ViewFormLayout';
import CustomTable from 'components/CustomTable';
import { formatDate } from 'utils/helpers';
import { UserLicense } from 'services/interfaces';

export default function Licenses() {
  const userLicenses = useSelector((state: IState) => state.userProfile?.user?.userLicenses);

  const columns:TColumns[] = [
    {
      name: 'License',
      column: 'licenseTypeId',
      width: 100,
      sortable: true,
    },
    {
      name: 'Acquired',
      column: 'acquiredDate',
      width: 150,
      sortable: true,
    },
    {
      name: 'Expires On',
      column: 'expiryDate',
      width: 150,
      sortable: true,
    },
    {
      name: 'State',
      column: 'stateCode',
      width: 100,
      sortable: true,
    },
    {
      name: 'License Number',
      column: 'licenseNumber',
      width: 150,
      sortable: true,
    },
  ];

  const dataSet: TRows[] = userLicenses?.map((lic : UserLicense) => ({
    ...lic,
    id: parseInt(lic?.licenseNumber, 10),
    acquiredDate: formatDate(lic?.acquiredDate),
    expiryDate: formatDate(lic?.expiryDate),
    isCollapsable: false,
    details: [],
  })) || [];

  return (
    <ViewFormLayout testId="current-licenses">
      <ViewFormLayout.Title>
        Current Licenses
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <CustomTable
          rows={dataSet}
          columns={columns}
          collapseAllRow={false}
          defaultSort="licenseTypeId"
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
