import React from 'react';
import {
  Stack, Box,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';
import { TestQuestion } from 'services/interfaces';

function RadioButtonQuestion({ question, handleResponse } :
  {question: TestQuestion, handleResponse: Function}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleResponse({ id: event.target.name, value: event.target.value });
  };

  const isChecked = (id: number) => {
    if (question.answerId) {
      return question.answerId === id
    }
    return false;
  }

  const isDisabled = () => {
    if (question.answerId && question.answerId !== null) {
      return true
    }
    return false
  }

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', pr: 4 }}>
      <FormControl fullWidth margin="normal">
        <FormLabel id="demo-radio-buttons-group-label">{question?.testQuestionText}</FormLabel>
        {question.answerSummary && <Typography variant="caption">{question.answerSummary}</Typography>}
        <RadioGroup
          sx={{ pl: 3 }}
          name={String(question.testQuestionId)}
          onChange={(e) => handleChange(e)}
        >
          {React.Children.toArray(question?.testAnswers?.map((item) => (
            <Stack flexDirection="row" alignItems="center" justifyContent="space-between" flexGrow={2}>
              {
                isDisabled() ? (
                  <FormControlLabel
                    value={item.answerText}
                    control={<Radio />}
                    checked={isChecked(item.testAnswerId)}
                    label={item.answerText}
                    disabled
                  />
                ) : (
                  <FormControlLabel
                    value={item.answerText}
                    control={<Radio />}
                    label={item.answerText}
                  />
                )
              }

            </Stack>
          )))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default RadioButtonQuestion;
