/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Box, Container, CssBaseline, Toolbar,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import { DEFAULT_APP_THEME_PRIMARY_COLOR } from 'utils/constants';
import { useGetUserDetailQuery } from 'services/dataApi';
import LoadingBox from 'components/LoadingBox';
import TopMenu from './TopMenu';
import LeftSideMenu from './LeftSideMenu';
import Copyright from './Copyright';
import { ILayout } from './shared';

function Layout({ children }: ILayout) {
  const { primaryColor, userId } = useSelector((state: IState) => state.userProfile);
  const { isLoading: isLoadingUserData } = useGetUserDetailQuery({ userId });
  const mdTheme = createTheme({
    palette: {
      primary: {
        // eslint-disable-next-line max-len
        main: String(primaryColor?.trim()).length > 0 ? primaryColor : DEFAULT_APP_THEME_PRIMARY_COLOR,
        light: '#397BD3',
        dark: '#053E8C',
        contrastText: '#fff',
      },
      secondary: {
        main: '#F2395A',
        light: '#F4607B',
        dark: '#A9273E',
        contrastText: '#fff',
      },
      error: {
        main: '#D32F2F',
        light: '#D32F2F',
        dark: '#932020',
        contrastText: '#fff',
      },
      warning: {
        main: '#ED6C02',
        light: '#F08934',
        dark: '#A54B01',
        contrastText: '#fff',
      },
      info: {
        main: '#0288D1',
        light: '#03A9F4',
        dark: '#01579B',
        contrastText: '#fff',
      },
      success: {
        main: '#2E7D32',
        light: '#57975B',
        dark: '#205723',
        contrastText: '#fff',
      },
      divider: 'rgba(0,0,0,0.12)',
    },
  });
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: (theme) => theme?.palette?.grey[100],
          minHeight: '91vh',
        }}
      >
        <CssBaseline />
        {!isLoadingUserData && <TopMenu {...{ open, toggleDrawer }} />}
        <LeftSideMenu {...{ navigate, open, toggleDrawer }} />
        {!isLoadingUserData ? (
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => (theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900]),
              flexGrow: 1,
              overflow: 'auto',
              display: 'flex', // Use flexbox layout
              flexDirection: 'column', // Arrange children vertically
              justifyContent: 'space-between', // Space out children, placing Copyright at the bottom
              minHeight: '100%', // Ensure the Box covers the full viewport height
            }}
          >
            <Toolbar />
            <Container
              maxWidth={false}
              sx={{ mt: 2, mb: 2, flexGrow: 1 }}
            >
              {children}
            </Container>
            <Copyright data-testid="copyright" />
          </Box>
        ) : <LoadingBox />}
      </Box>
    </ThemeProvider>
  );
}

export default Layout;
