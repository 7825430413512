/* eslint-disable max-len */
import React, { useState } from 'react'
import {
  Box,
  Button,
  Paper,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import {
  ACTION_ITEM_TOOLTIP_TEXT,
  BUTTON_TEXT, PROFILE_TEXT,
  REACT_APP_COURSE_ENGINE_URL,
} from 'utils/constants';
import { useGetScormLinkQuery } from 'services/dataApi';
import LoadingBox from 'components/LoadingBox';
import DialogModal from 'components/DialogModal';

interface ScormLaunch {
  registrationId: string,
}
export default function ScormLauncher({
  registrationId,
} : ScormLaunch) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }))
  const [openScormModal, setOpenScormModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true)
  const skipQuery = !registrationId;
  const { data: scormLink, isLoading: isLoadingLink } = useGetScormLinkQuery({ registrationId }, {
    skip: skipQuery,
  });

  const noContent = () => (
    <Item>
      <Typography>{PROFILE_TEXT.NO_CONTENT_AVAILABLE}</Typography>
    </Item>
  )

  const coursesContent = () => (
    <Item>
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.LAUNCH_SCORM_ACTIVITY}>
        <Button
          variant="contained"
          onClick={() => {
            setOpenScormModal(true)
            setIsLoading(true)
          }}
        >
          {BUTTON_TEXT.LAUNCH_SCORM_ACTIVITY}
        </Button>
      </Tooltip>
    </Item>
  )

  const scormBox = scormLink ? coursesContent() : noContent();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center" height="85%" maxHeight="100%" p={2} bgcolor="#fff">
      {isLoadingLink ? <LoadingBox /> : scormBox}
      <DialogModal open={openScormModal} onClose={() => setOpenScormModal(false)}>
        <DialogModal.Title>
          Scorm Player
        </DialogModal.Title>
        <DialogModal.Content>
          {isLoading && <LoadingBox /> }
          <iframe
            src={`${REACT_APP_COURSE_ENGINE_URL}${scormLink}`}
            title="SCORM Content"
            width="100%"
            height="700px"
            style={{ border: 'none' }}
            onLoad={() => {
              setIsLoading(false)
            }}
          />
        </DialogModal.Content>
      </DialogModal>
    </Box>
  )
}
