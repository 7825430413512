import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  testLibrariesList: [],
  preTestAnswer: [],
};

export const testLibrariesSlice = createSlice({
  name: 'testLibraries',
  initialState,
  reducers: {
    resetAllFilters: () => initialState,
    setTestLibrary: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.testLibrariesList = action?.payload;
    },
    setPreTestAnswer: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.preTestAnswer = action?.payload;
    },
  },
});

export const {
  resetAllFilters,
  setTestLibrary,
} = testLibrariesSlice.actions;

export default testLibrariesSlice.reducer;
