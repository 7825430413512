import { BaseQueryApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IState } from 'redux/interfaces';
import { ChangePasswordRequest } from './interfaces';
import handleUnauthorizedResponse from './serviceHelper';

const baseUrlApi = process.env.REACT_APP_NEXTGEN_BASE_URL;

export const tokenApi = createApi({
  reducerPath: 'tokenJsonServerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlApi,
    prepareHeaders: (headers: Headers, api: Pick<
      BaseQueryApi,
      'getState' | 'extra' | 'endpoint' | 'type' | 'forced'
    >) => {
      const getState = api?.getState() as IState;
      const { accessToken } = getState.userProfile || null;

      // If we have a token set in state, let's assume that we should be passing it.
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
    validateStatus: (response: Response): boolean => {
      if (response.status === 401) {
        return handleUnauthorizedResponse()
      }
      return response.status !== 401;
    },
  }),
  endpoints: (builder) => ({

    validateToken: builder.query<void, void>({
      query: () => ({
        url: '/api/users/validate-token',
        method: 'GET',
      }),
    }),

    changePassword: builder.mutation<void, ChangePasswordRequest>({
      query: ({ userId, newPassword }) => ({
        url: '/api/users/change-password',
        method: 'POST',
        body: {
          userId,
          newPassword,
        },
      }),
    }),
  }),
});

export const {
  useValidateTokenQuery,
  useChangePasswordMutation,
} = tokenApi;
