import React, { useEffect } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import TextFieldStyled from 'components/TextFieldStyled';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';

interface SearchBarProps {
  onSearch: Function;
  onReset: Function;
  onSearchQuery: Function;
  searchQuery: string;
}

function SearchBar({
  searchQuery = '', onSearchQuery, onSearch, onReset,
}: SearchBarProps) {
  const handleSearch = () => {
    onSearch();
  };
  const handleReset = () => {
    onReset();
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchQuery(event.target.value);
  };

  useEffect(() => {

  }, [searchQuery]);
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" gap={1} bgcolor="white" p={0.5} width="100%">
      <TextFieldStyled
        placeholder="Search by Name, Description or Number"
        value={searchQuery}
        onChange={handleInputChange}
      />
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SEARCH}>
        <Button sx={{ textTransform: 'none' }} variant="contained" color="primary" onClick={handleSearch}>
          {BUTTON_TEXT.SEARCH}
        </Button>
      </Tooltip>

      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.RESET}>
        <Button sx={{ textTransform: 'none' }} variant="contained" color="primary" onClick={handleReset}>
          {BUTTON_TEXT.RESET}
        </Button>
      </Tooltip>

    </Box>
  );
}

export default SearchBar;
