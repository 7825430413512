import React, { useRef, useState } from 'react';
import { IUserAvailableEvaluations } from 'redux/interfaces';
import ViewFormLayout from 'components/ViewFormLayout';
import CustomTable from 'components/CustomTable';
import { TRows, TColumns } from 'components/CustomTable/interface';
import DialogModal from 'components/DialogModal';
import ProgressBar from 'components/ProgressBar';
import { EvaluationProgressStatus } from 'utils/constants';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import Evaluation from './Evaluation';
// eslint-disable-next-line import/extensions
import availableEvaluations from './availableEvaluations.json';

export default function AvailableEvaluations() {
  const [openUserEvaluation, setOpenUserEvaluation] = useState(false);
  const evaluationId = useRef<number>();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const columns: TColumns[] = [
    {
      name: 'Assigned Date',
      column: 'AssignedDate',
      sortable: true,
      hidden: false,
    },
    {
      name: 'User',
      column: 'User',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Type',
      column: 'Type',
      width: 150,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Evaluation',
      column: 'Evaluation',
      width: 120,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Progress',
      column: 'Progress',
      width: 340,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Due Date',
      column: 'DueDate',
      width: 120,
      sortable: true,
      hidden: false,
    },
  ];

  const steps = [
    EvaluationProgressStatus.Assigned,
    EvaluationProgressStatus.InProgress,
    EvaluationProgressStatus.ApproverAssigned,
    EvaluationProgressStatus.ApproverSignedOff,
    EvaluationProgressStatus.EmployeeSignOffRequired,
    EvaluationProgressStatus.EmployeeSignedOff,
    EvaluationProgressStatus.ManageCompletes,
  ];

  const handleOpenModal = (id: number) => {
    setOpenUserEvaluation(true)
    evaluationId.current = id;
  }

  const dataSet: TRows[] = (availableEvaluations || []).map(
    (evaluation: IUserAvailableEvaluations) => ({
      ...evaluation,
      id: evaluation.Id,
      isCollapsable: false,
      details: [],
      Evaluation: <span style={{ cursor: 'pointer', color: theme.palette.primary.main }} role="button" tabIndex={0} onClick={() => handleOpenModal(evaluation.Id)} onKeyDown={() => handleOpenModal(evaluation.Id)}>{evaluation.Evaluation}</span>,
      Progress: <ProgressBar steps={steps} activeStep={evaluation.Progress} />,
    }),
  );

  const handleSave = () => {
    setOpenUserEvaluation(false)
    enqueueSnackbar('Evaluation Saved Successfully', { variant: 'success' });
  }

  const handleClose = () => {
    setOpenUserEvaluation(false)
  }

  return (
    <ViewFormLayout testId="available-evaluation">
      <ViewFormLayout.Title>Available Evaluations</ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <CustomTable rows={dataSet} columns={columns} collapseAllRow={false} />
      </ViewFormLayout.Body>

      <DialogModal open={openUserEvaluation} onClose={handleClose}>
        <DialogModal.Title>
          User Evaluation
        </DialogModal.Title>
        <DialogModal.Content backgroundGrey>
          <Evaluation
            onClose={handleClose}
            onSave={handleSave}
            evaluationId={evaluationId.current}
          />
        </DialogModal.Content>
      </DialogModal>
    </ViewFormLayout>
  );
}
