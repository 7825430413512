/* eslint-disable no-unused-vars */
import { ReactNode } from 'react';

// eslint-disable-next-line no-shadow
export enum iconMap {
  Dashboard,
  Transcript,
  ContentLibrary,
  DemmoCourseName,
  Profile,
  Courses,
  Assignments,
  Certifications,
  Evaluations,
  EvaluationQuestionGroup,
  RatingTypes,
  SCORM,
  Facilities,
  Vendors,
  Notifications,
  Users,
  Groups,
  Jobs,
  Goals,
  Reports,
  Quiz
}
export type NavigationMenu = {
  id: string;
  url: string;
  title: string;
  icon: iconMap;
};

export const NAVIGATION_MENU: NavigationMenu[] = [
  {
    id: 'userDashboard', url: '/dashboard', title: 'My Tasks', icon: iconMap.Dashboard,
  },
  {
    id: 'contentLibrary', url: '/content-library', title: 'Content Library', icon: iconMap.ContentLibrary,
  },
  {
    id: 'transcript', url: '/transcript', title: 'My Transcript', icon: iconMap.Transcript,
  },
  {
    id: 'profile', url: '/profile', title: 'My Profile', icon: iconMap.Profile,
  },
];

export const NAVIGATION_MANAGE_COURSES: NavigationMenu[] = [
  {
    id: 'courses', url: '/admin/courses', title: 'Courses', icon: iconMap.Courses,
  },
  {
    id: 'assignments', url: '/admin/assignments', title: 'Assignments', icon: iconMap.Assignments,
  },
  {
    id: 'certifications', url: '/admin/certifications', title: 'Certifications', icon: iconMap.Certifications,
  },
  {
    id: 'evaluations', url: '/admin/evaluations', title: 'Evaluations', icon: iconMap.Evaluations,
  },
  {
    id: 'evaluations', url: '/admin/evaluation-question-group-list', title: 'Question Groups', icon: iconMap.EvaluationQuestionGroup,
  },
  {
    id: 'ratingTypes', url: '/admin/rating-types', title: 'Rating Types', icon: iconMap.RatingTypes,
  },
  {
    id: 'scorm', url: '/admin/scorm-courses/new', title: 'SCORM', icon: iconMap.SCORM,
  },
  {
    id: 'facilities', url: '/admin/facilities', title: 'Facilities', icon: iconMap.Facilities,
  },
  {
    id: 'vendors', url: '/admin/vendors', title: 'Vendors', icon: iconMap.Vendors,
  },
  {
    id: 'notifications', url: '/admin/notifications', title: 'Notifications', icon: iconMap.Notifications,
  },
  {
    id: 'testLibraries', url: '/admin/test-libraries', title: 'Test Libraries', icon: iconMap.Quiz,
  },
  {
    id: 'questions', url: '/questions', title: 'Questions', icon: iconMap.Quiz,
  },
];

export const NAVIGATION_MANAGE_REPORTS: NavigationMenu[] = [
  {
    id: 'reports', url: '/admin/reports', title: 'Reports', icon: iconMap.Reports,
  },
]

export const NAVIGATION_MANAGE_USERS: NavigationMenu[] = [
  {
    id: 'users', url: '/admin/users', title: 'Users', icon: iconMap.Users,
  },
  {
    id: 'groups', url: '/admin/groups', title: 'Groups', icon: iconMap.Groups,
  },
  {
    id: 'jobs', url: '/admin/jobs', title: 'Jobs', icon: iconMap.Jobs,
  },
  {
    id: 'goals', url: '/admin/goals', title: 'Goals', icon: iconMap.Goals,
  },
]

export interface ILeftSideMenu {
  navigate: Function,
  open: boolean,
  toggleDrawer: () => void
}

export interface ILayout {
  children: ReactNode,
}
