import React from 'react';
import Box from '@mui/material/Box';
import LanguageIcon from '@mui/icons-material/Language';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import { COURSE_TYPES_ENUM, COURSE_TYPE_ID } from 'utils/constants';

export default function getType(
  { type, getIcon = false } :
  {type: number | string, getIcon?: boolean},
) {
  if (type === COURSE_TYPES_ENUM.ONLINE || Number(type) === COURSE_TYPES_ENUM.ONLINE) {
    if (getIcon) return <LanguageIcon fontSize="large" />
    return (
      <Box display="flex" alignItems="center">
        <LanguageIcon />
        {COURSE_TYPE_ID[type]}
      </Box>
    );
  }
  if (type === COURSE_TYPES_ENUM.CLASSROOM || Number(type) === COURSE_TYPES_ENUM.CLASSROOM) {
    if (getIcon) return <CoPresentOutlinedIcon fontSize="large" />
    return (
      <Box display="flex" alignItems="center">
        <CoPresentOutlinedIcon />
        {COURSE_TYPE_ID[type]}
      </Box>
    );
  }
  if (type === COURSE_TYPES_ENUM.CERTIFICATION
    || Number(type) === COURSE_TYPES_ENUM.CERTIFICATION) {
    if (getIcon) return <WorkspacePremiumIcon fontSize="large" />
    return (
      <Box display="flex" alignItems="center">
        <WorkspacePremiumIcon />
        {COURSE_TYPE_ID[type]}
      </Box>
    );
  }
  if (type === COURSE_TYPES_ENUM.CERTIFICATION_1
    || Number(type) === COURSE_TYPES_ENUM.CERTIFICATION_1) {
    if (getIcon) return <WorkspacePremiumIcon fontSize="large" />
    return (
      <Box display="flex" alignItems="center">
        <WorkspacePremiumIcon />
        {COURSE_TYPE_ID[type]}
      </Box>
    );
  }
  if (type === COURSE_TYPES_ENUM.MANUAL
    || Number(type) === COURSE_TYPES_ENUM.MANUAL) {
    if (getIcon) return <FeedOutlinedIcon fontSize="large" />
    return (
      <Box display="flex" alignItems="center">
        <FeedOutlinedIcon />
        {COURSE_TYPE_ID[type]}
      </Box>
    );
  }
  if (type === COURSE_TYPES_ENUM.ASSIGNMENT || Number(type) === COURSE_TYPES_ENUM.ASSIGNMENT) {
    if (getIcon) return <WorkspacePremiumIcon fontSize="large" />
    return (
      <Box display="flex" alignItems="center">
        <ListAltIcon />
        {COURSE_TYPE_ID[type]}
      </Box>
    );
  }
  return null
}
