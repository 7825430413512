import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch } from 'react-redux';
import Layout from 'components/Layout';
import ScormCoursesDetail from 'modules/administrator/AdminScormCourses/ScormCoursesDetail';

function ScormCursesDetail() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Scorm Curses detail'))
  }, []);

  return (
    <Layout>
      <Box sx={{ width: '100%' }}>
        <ScormCoursesDetail />
      </Box>
    </Layout>
  );
}

export default ScormCursesDetail;
