/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'redux/interfaces';
import { useLocation } from 'react-router-dom';
import {
  List,
  Divider,
  Toolbar,
  Drawer as MuiDrawer,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Box,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  ExpandLess,
  ExpandMore,
  Language as LanguageIcon,
  Person,
  School,
} from '@mui/icons-material';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import Assessment from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GradeIcon from '@mui/icons-material/Grade';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import NotificationsIcon from '@mui/icons-material/Notifications';
import QuizIcon from '@mui/icons-material/Quiz';
import StorefrontIcon from '@mui/icons-material/Storefront';
import WorkIcon from '@mui/icons-material/Work';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ViewListIcon from '@mui/icons-material/ViewList';
import { styled, useTheme } from '@mui/material/styles';
import {
  ILeftSideMenu, NAVIGATION_MANAGE_COURSES, NAVIGATION_MENU, NAVIGATION_MANAGE_USERS, NAVIGATION_MANAGE_REPORTS, NavigationMenu, iconMap,
} from 'components/Layout/shared';

function LeftSideMenu({ navigate, open, toggleDrawer }: ILeftSideMenu) {
  const isAdmin = useSelector((state: IState) => state.userProfile.admin);
  const CompanyLogotype = useSelector((state: IState) => state.userProfile.companyImageLocation);
  const companyName = useSelector((state: IState) => state.userProfile.companyName);
  const [openReports, setOpenReports] = React.useState(true);
  const [openManageCourses, setOpenManageCourses] = React.useState(true);
  const [openManageUsers, setOpenManageUsers] = React.useState(true);
  const theme = useTheme();
  const location = useLocation();

  const DRAWERWIDTH = 240;
  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
    // eslint-disable-next-line no-shadow
  })(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: DRAWERWIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

  const ICON_MAP = {
    [iconMap.Dashboard]: <DashboardIcon />,
    [iconMap.Transcript]: <Assessment />,
    [iconMap.ContentLibrary]: <LocalLibraryIcon />,
    [iconMap.DemmoCourseName]: <LanguageIcon />,
    [iconMap.Profile]: <Person />,
    [iconMap.Courses]: <School />,
    [iconMap.Assignments]: <FormatListNumberedIcon />,
    [iconMap.Certifications]: <WorkspacePremiumIcon />,
    [iconMap.Evaluations]: <ChecklistIcon />,
    [iconMap.EvaluationQuestionGroup]: <ViewListIcon />,
    [iconMap.RatingTypes]: <GradeIcon />,
    [iconMap.SCORM]: <CloudUploadIcon />,
    [iconMap.Facilities]: <LocationCityIcon />,
    [iconMap.Vendors]: <StorefrontIcon />,
    [iconMap.Notifications]: <NotificationsIcon />,
    [iconMap.Users]: <GroupIcon />,
    [iconMap.Groups]: <GroupsIcon />,
    [iconMap.Jobs]: <WorkIcon />,
    [iconMap.Goals]: <AdsClickIcon />,
    [iconMap.Reports]: <BarChartIcon />,
    [iconMap.Quiz]: <QuizIcon />,
  }

  const isActiveLink = (url: string) => {
    const path = location.pathname;
    if (url === '/dashboard' && path === '/') return true;
    return path === url;
  }

  const getMenuList = (_navigate: Function, menus: NavigationMenu[]) => React.Children.toArray(menus?.map((menu) => (
    <ListItemButton
      onClick={() => _navigate(menu.url)}
      title={menu.title}
      sx={{ backgroundColor: `${isActiveLink(menu.url) ? theme.palette.grey[100] : ''}`, cursor: 'pointe' }}
    >
      <ListItemIcon>
        <div style={{ color: `${isActiveLink(menu.url) ? theme.palette.primary.main : '#232323'}` }}>
          {ICON_MAP[menu?.icon]}
        </div>
      </ListItemIcon>
      <ListItemText role="menuitem" primary={menu?.title} sx={{ color: `${isActiveLink(menu.url) ? theme.palette.primary.main : theme.palette.secondary}` }} />
    </ListItemButton>
  )));

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: [1],
        }}
      >
        {
          open ? (
            <Box ml={2}>
              <img style={{ width: '125px' }} src={CompanyLogotype} alt={companyName!} />
            </Box>
          ) : null
        }
        <IconButton onClick={toggleDrawer}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {getMenuList(navigate, NAVIGATION_MENU)}
      </List>
      {
        isAdmin && (
          <>
            <List component="nav">
              <ListItemButton onClick={() => setOpenReports((openReports) => !openReports)} title="Reports">
                <Box display="flex" justifyContent="space-between" width="100%">
                  {open ? <Typography variant="subtitle2">REPORTS</Typography> : <Typography variant="subtitle2">R</Typography>}
                  {openReports ? <ExpandLess /> : <ExpandMore />}
                </Box>
              </ListItemButton>
              <Collapse in={openReports} timeout="auto" unmountOnExit>
                {getMenuList(navigate, NAVIGATION_MANAGE_REPORTS)}
              </Collapse>
            </List>
            <List component="nav">
              <ListItemButton onClick={() => setOpenManageCourses((openManageCourses) => !openManageCourses)} title="Manage Courses">
                <Box display="flex" justifyContent="space-between" width="100%">
                  {open ? <Typography variant="subtitle2">MANAGE COURSES</Typography> : <Typography variant="subtitle2">C</Typography>}
                  {openManageCourses ? <ExpandLess /> : <ExpandMore />}
                </Box>
              </ListItemButton>
              <Collapse in={openManageCourses} timeout="auto" unmountOnExit>
                {getMenuList(navigate, NAVIGATION_MANAGE_COURSES)}
              </Collapse>

            </List>
            <List component="nav">
              <ListItemButton onClick={() => setOpenManageUsers((openManageUsers) => !openManageUsers)} title="Manage Users">
                <Box display="flex" justifyContent="space-between" width="100%">
                  {open ? <Typography variant="subtitle2">MANAGE USERS</Typography> : <Typography variant="subtitle2">U</Typography>}
                  {openManageUsers ? <ExpandLess /> : <ExpandMore />}
                </Box>
              </ListItemButton>
              <Collapse in={openManageUsers} timeout="auto" unmountOnExit>
                {getMenuList(navigate, NAVIGATION_MANAGE_USERS)}
              </Collapse>
            </List>
          </>
        )
      }

    </Drawer>
  );
}

export default LeftSideMenu;
