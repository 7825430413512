import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Copyright() {
  const { t } = useTranslation();
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
    >
      {t('copyright', { date: new Date().getFullYear() })}
    </Typography>
  );
}

export default Copyright;
