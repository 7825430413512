import React from 'react'
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import {
  Box, IconButton, Stack, Tooltip,
  Typography,
} from '@mui/material';
import { Attachment } from 'services/interfaces';
import { TColumns, TRows } from 'components/CustomTable/interface';
import CustomTable from 'components/CustomTable';
import DownloadIcon from '@mui/icons-material/Download';
import FormView, { IFormItem } from 'components/FormView';
import ViewFormLayout from 'components/ViewFormLayout';
import { CourseDetails } from 'redux/interfaces';

interface IMyCourseDetailsProps {
    course?: CourseDetails;
}

function getActionButtons(name: string, url: string) {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Box display="flex">
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.DOWNLOAD_ATTACHMENT_FILE}>
        <IconButton onClick={handleDownload} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <DownloadIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default function MyCourseDetails({ course } : IMyCourseDetailsProps) {
  const isCourseEmpty = !(course && Object.keys(course).length > 0);
  const studentInfo: IFormItem[] = [
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.userFullName,
      fieldId: 'name',
      label: 'Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.userPhone,
      fieldId: 'phone',
      label: 'Phone',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.courseProcessStatusTypeName,
      fieldId: 'currentStatus',
      label: 'Current Status',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(course?.userId),
      fieldId: 'userId',
      label: 'User ID',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.userDepartmentName,
      fieldId: 'department',
      label: 'Department',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.userEmail,
      fieldId: 'email',
      label: 'Email',
      titleSize: 3,
    },

  ]

  const authorizerInfo: IFormItem[] = [
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.authorizerName,
      fieldId: 'authorizerName',
      label: 'Authorizer Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.authorizerPhone,
      fieldId: 'authorizerPhone',
      label: 'Authorizer Phone',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.authorizerEmail,
      fieldId: 'authorizerEmail',
      label: 'Authorizer Email',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.authorizerPhone,
      fieldId: 'authorizerFax',
      label: 'Authorizer Fax',
      titleSize: 3,
    },
  ]

  const sessionInfo: IFormItem[] = [
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.courseName,
      fieldId: 'courseName',
      label: 'Course Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.courseDescription,
      fieldId: 'courseDescription',
      label: 'Description',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.duration,
      fieldId: 'courseDuration',
      label: 'Duration',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.vendorName,
      fieldId: 'courseVendor',
      label: 'Vendor',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.facilityAddress,
      fieldId: 'courseLocation',
      label: 'Location',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: 'TBD',
      fieldId: 'courseOldStatus',
      label: 'Old Status',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(course?.startDateTime),
      fieldId: 'courseStartDateTime',
      label: 'Start Date Time',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(course?.endDateTime),
      fieldId: 'courseEndDateTime',
      label: 'End Date Time',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(course?.dueDate),
      fieldId: 'courseDueDate',
      label: 'Due Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.instructorNameSummary,
      fieldId: 'courseInstructors',
      label: 'Instructor(s)',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.address,
      fieldId: 'courseDirection',
      label: 'Direction',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: 'TBD',
      fieldId: 'courseNotes',
      label: 'Notes',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: 'TBD',
      fieldId: 'courseAttempts',
      label: 'Attempt',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.publicDescription,
      fieldId: 'courseOutline',
      label: 'Outline',
      titleSize: 3,
    },
  ]

  const internalInformation: IFormItem[] = [
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(course?.createdByUserId),
      fieldId: 'createdBy',
      label: 'Created By userId',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.createdDate,
      fieldId: 'createdDate',
      label: 'Created Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: String(course?.updatedByUserId),
      fieldId: 'updatedBy',
      label: 'Updated By',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: course?.updatedDate,
      fieldId: 'updatedDate',
      label: 'Updated Date',
      titleSize: 3,
    },
  ];

  const attachmentColumns: TColumns[] = [
    {
      name: 'AttachmentName',
      column: 'AttachmentName',
      width: 150,
      sortable: true,
      align: 'center',
      alignTitle: 'center',
    },
    {
      name: 'AttachmentFileName',
      column: 'AttachmentFileName',
      width: 150,
      sortable: true,
      align: 'center',
      alignTitle: 'center',
    },
    {
      name: 'AttachmentFileExt',
      column: 'AttachmentFileExt',
      width: 150,
      sortable: true,
      align: 'center',
      alignTitle: 'center',
    },
    {
      name: 'CreatedDate',
      column: 'CreatedDate',
      width: 150,
      sortable: true,
      align: 'center',
      alignTitle: 'center',
    },
    {
      name: 'Actions',
      column: 'actions',
      width: 150,
      sortable: true,
      align: 'right',
      alignTitle: 'left',
    },
  ];

  const attachmentDataSet: TRows[] = (course?.attachments || []).map(
    (item: Attachment) => ({
      ...item,
      id: item.attachmentId,
      isCollapsable: false,
      details: [],
      actions: getActionButtons(item.attachmentName, String(course?.attachmentFileName)),
    }),
  );

  return (
    <Stack p={1}>
      {isCourseEmpty ? <Typography alignContent="center"> No content available!</Typography> : (
        <>
          <ViewFormLayout testId="student-information">
            <ViewFormLayout.Title>
              Student Information
            </ViewFormLayout.Title>
            <ViewFormLayout.Body>
              <FormView
                style={{ paddingLeft: 1, paddingRight: 1 }}
                fields={studentInfo}
                onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
              />
            </ViewFormLayout.Body>
          </ViewFormLayout>
          <ViewFormLayout testId="authorizer-information">
            <ViewFormLayout.Title>
              Authorizer Information
            </ViewFormLayout.Title>
            <ViewFormLayout.Body>
              <FormView
                style={{ paddingLeft: 1, paddingRight: 1 }}
                fields={authorizerInfo}
                onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
              />
            </ViewFormLayout.Body>
          </ViewFormLayout>
          <ViewFormLayout testId="session-information">
            <ViewFormLayout.Title>
              Session Information
            </ViewFormLayout.Title>
            <ViewFormLayout.Body>
              <FormView
                style={{ paddingLeft: 1, paddingRight: 1 }}
                fields={sessionInfo}
                onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
              />
            </ViewFormLayout.Body>
          </ViewFormLayout>
          <ViewFormLayout testId="attachment-information">
            <ViewFormLayout.Title>
              Attachment
            </ViewFormLayout.Title>
            <ViewFormLayout.Body>
              <CustomTable
                rows={attachmentDataSet}
                columns={attachmentColumns}
                collapseAllRow={false}
                defaultSort="dueDate"
              />
            </ViewFormLayout.Body>
          </ViewFormLayout>
          <ViewFormLayout testId="internal-information">
            <ViewFormLayout.Title>
              Internal Information
            </ViewFormLayout.Title>
            <ViewFormLayout.Body>
              <FormView
                style={{ paddingLeft: 1, paddingRight: 1 }}
                fields={internalInformation}
                onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
              />
            </ViewFormLayout.Body>
          </ViewFormLayout>
        </>
      )}
    </Stack>
  )
}
