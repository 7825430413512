import React, { useRef } from 'react';
import {
  Box, TextField, Button, Container, Typography, Grid,
} from '@mui/material';

function GroupsFilterForm({ onSearch, onReset }: { onSearch: Function, onReset: Function }) {
  const formRef = useRef<HTMLFormElement | null>(null);
  const groupId = useRef<HTMLInputElement | null>(null);
  const name = useRef<HTMLInputElement | null>(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = {
      groupId: groupId.current?.value || null,
      name: name.current?.value || null,
    };
    scrollToTop()
    onSearch(formData);
  };

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    scrollToTop()
    onReset();
  };

  return (
    <Container maxWidth="lg" sx={{ p: 2 }}>
      <Box component="form" onSubmit={handleSubmit} ref={formRef}>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Group Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Group ID" name="groupId" inputRef={groupId} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Name" name="name" inputRef={name} />
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }} display="flex" flexDirection="row-reverse" gap={1}>
          <Button type="submit" variant="contained" color="primary">
            Search
          </Button>
          <Button onClick={handleReset}>
            Reset
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default GroupsFilterForm;
