import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import AdminScormCourses from 'modules/administrator/AdminScormCourses';

function ScormCurses() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Scorm Courses'))
  }, []);

  return (
    <Layout>
      <AdminScormCourses />
    </Layout>
  );
}

export default ScormCurses;
