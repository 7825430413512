import React from 'react'
import {
  Alert,
  Stack,
} from '@mui/material'

import { LearningObject } from 'modules/SearchLearningObject/shared'

type PreviewLaunchType = Pick<LearningObject, 'wbtURL' | 'learningObjectName'>;

function PreviewLaunch(
  { previewSelected }: { previewSelected: PreviewLaunchType },
) {
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {!previewSelected?.wbtURL ? <Alert severity="error">The preview content is not defined, contact the admin!</Alert>
        : (
          <iframe
            title={previewSelected?.learningObjectName}
            src={previewSelected?.wbtURL}
            width="100%"
            style={{ border: 0, height: '80vh' }}
          />
        )}
    </Stack>
  )
}

export default PreviewLaunch;
