/* eslint-disable max-len */
import { enqueueSnackbar } from 'notistack';
import React, {
  createContext, useContext, useState, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateUserProfileMutation } from 'services/dataApi';
import { UpdateUserProfileRequest } from 'services/interfaces';

interface IFormResponse {
  id: string;
  value: any;
}

function updateArrayState<T extends { id: string }>(
  prevState: T[],
  newResponse: T,
): T[] {
  const existingIndex = prevState.findIndex((item) => item.id === newResponse.id);

  if (existingIndex !== -1) {
    const updatedState = [...prevState];
    updatedState[existingIndex] = newResponse;
    return updatedState;
  }
  return [...prevState, newResponse];
}

function extractValues(
  accountStatus: IFormResponse[],
  personalInformation: IFormResponse[],
  employeeInformation: IFormResponse[],
): UpdateUserProfileRequest {
  const initialUserDetails: { [key: string]: string | number } = {}

  const allInformation = [...accountStatus, ...personalInformation, ...employeeInformation];

  allInformation.forEach((curr) => {
    if (curr?.id === 'schoolId') {
      initialUserDetails[curr?.id] = Number(curr?.value)
    }
    initialUserDetails[curr?.id] = curr?.value as string
  });
  const finalUserDetails: UpdateUserProfileRequest = {
    ...initialUserDetails,
    userId: 0,
  };

  return finalUserDetails;
}

const FormContext = createContext<any>(null);

// Crear un proveedor de contexto
export function FormProvider({ children }: { children: React.ReactNode }) {
  const { userId } = useParams();
  const [updateProfile, { isLoading: isLoadingSave }] = useUpdateUserProfileMutation();

  const [accountStatus, setAccountStatus] = useState<IFormResponse[]>([]);
  const [personalInformation, setPersonalInformation] = useState<IFormResponse[]>([]);
  const [employeeInformation, setEmployeeInformation] = useState<IFormResponse[]>([]);

  const handleAccountStatusChange = (newResponse: IFormResponse) => {
    setAccountStatus((prevState) => updateArrayState(prevState, newResponse));
  };

  const handlePersonalInformationChange = (newResponse: IFormResponse) => {
    setPersonalInformation((prevState) => updateArrayState(prevState, newResponse));
  };

  const handleEmployeeInformationChange = (newResponse: IFormResponse) => {
    setEmployeeInformation((prevState) => updateArrayState(prevState, newResponse));
  };

  const handleSubmitForm = async () => {
    const formValues = (extractValues(accountStatus, personalInformation, employeeInformation));
    try {
      const updateProfileForm: UpdateUserProfileRequest = { ...formValues, userId: Number(userId) }
      await updateProfile(updateProfileForm).unwrap();
      enqueueSnackbar('Changes were saved Successfully', { variant: 'success' });
    } catch (error) {
      console.log({ error })
    }
  };

  // Memorizar el valor de 'value' usando useMemo
  const value = useMemo(() => ({
    accountStatus,
    personalInformation,
    employeeInformation,
    isLoadingSave,
    handleAccountStatusChange,
    handlePersonalInformationChange,
    handleEmployeeInformationChange,
    handleSubmitForm,
  }), [accountStatus, personalInformation, employeeInformation]);

  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  );
}

// Hook para usar el contexto
export const useFormContext = () => useContext(FormContext);
