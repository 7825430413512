import React from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ViewFormLayout from 'components/ViewFormLayout'
import TestLibrariesTable from './TestLibrariesTable'

export default function AdminTestLibraries() {
  const navigate = useNavigate();
  return (
    <ViewFormLayout testId="administrator-test-libraries">
      <ViewFormLayout.ActionBar>
        <Button variant="contained" onClick={() => navigate('new')}>New Test</Button>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <TestLibrariesTable />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
