import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ISupervisors } from 'redux/interfaces';
import { ListItemIcon } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

export default function AuthorizerList({ authorizers } : {authorizers: ISupervisors[]}) {
  return (
    <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {authorizers?.map((authorizer: ISupervisors) => (
        <ListItem
          key={authorizer?.supervisorId}
          disablePadding
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText id={String(authorizer?.supervisorId)} primary={authorizer?.name} />
        </ListItem>
      ))}
    </List>
  );
}
