import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TablePaginationActionsProps } from 'components/Table/interface';
import { Typography } from '@mui/material';
import LoadingIcon from 'components/LoadingIcon';
import ObjectCard from './ObjectCard';
import { ILerningObjectsList } from './shared';
import './LearningObject.style.scss';

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const {
    count, page, rowsPerPage, onPageChange,
  } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function LearningObjectTable(
  {
    rows, isLoading, onOpenCertificationDetail, onOpenEnrolledSelfDetail,
  }: ILerningObjectsList,
) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    if (page !== 0) setPage(0)
  }, [rows]);

  return (
    <TableContainer className="object-table" data-testid="learning-object-table">
      <Table sx={{ minWidth: 500, overflow: 'hidden' }} aria-label="custom pagination table">
        <TableHead>
          <TableRow sx={{ height: 40 }}>
            <TableCell sx={{ height: 40 }}>
              <Typography variant="subtitle1">Course Name</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading
          && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`${index} - prop`}>
              <TableCell sx={{ padding: '8px 8px' }} className="object-container-base">
                <ObjectCard
                  learningObject={row}
                  onOpenCertificationDetail={onOpenCertificationDetail}
                  onOpenEnrolledSelfDetail={onOpenEnrolledSelfDetail}
                />
              </TableCell>
            </TableRow>
          ))}
          {!isLoading && rows.length === 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={1}> No records found.</TableCell>
            </TableRow>
          )}
          {isLoading && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={1}>
              <LoadingIcon />
            </TableCell>
          </TableRow>
          )}
        </TableBody>
        <TableFooter>
          {!isLoading && rows.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={1}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
