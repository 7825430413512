import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import ScormCoursesTable from './ScormCoursesTable';

export default function AdminScormCourses() {
  const navigate = useNavigate();

  return (
    <ViewFormLayout testId="administrator-scorm-courses">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.NEW_SCORM_COURSE}>
          <Button variant="contained" onClick={() => navigate('new')}>{BUTTON_TEXT.NEW_SCORM_COURSE}</Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <ScormCoursesTable />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
