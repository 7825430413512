import React from 'react'
import { Box, Typography } from '@mui/material'
import Divider from '@mui/material/Divider';

import Question from './Question';
import { EvaluationSection } from '.';

interface ISection{
  section: EvaluationSection
}

export default function Section({ section }: ISection) {
  return (
    <section>
      <Box p={2}>
        <Typography variant="subtitle1" gutterBottom>
          {section.title}
        </Typography>
      </Box>
      {
        React.Children.toArray(
          section.questions.map(
            (question, index) => <Question question={question} questionNum={index + 1} />,
          ),
        )
      }
      <Divider />
    </section>
  )
}
