/* eslint-disable max-len */
import React from 'react'
import { Box, IconButton, Tooltip } from '@mui/material';
import { IQuestion } from 'redux/interfaces';
import { setGoBackTo } from 'redux/userProfileSlice';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTable from 'components/CustomTable'
import EditIcon from '@mui/icons-material/Edit';
import ViewFormLayout from 'components/ViewFormLayout'

export default function QuestionList({ questions, parent }: { questions: IQuestion[], parent?: string }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const columns: TColumns[] = [
    {
      name: 'ID',
      column: 'id',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Question text',
      column: 'question',
      sortable: true,
      hidden: false,
    },
    {
      name: 'Order',
      column: 'displayOrder',
      width: 150,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Randomize',
      column: 'randomize',
      width: 120,
      sortable: false,
      hidden: false,
    },
    {
      name: '',
      column: 'view',
      width: 120,
      sortable: false,
      hidden: false,
    },
  ];

  function getActionButtons(questionId: number) {
    return (
      <Box display="flex">
        <Tooltip title="Edit question">
          <IconButton
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            onClick={() => {
              dispatch(setGoBackTo(location.pathname));
              if (parent === '#testLibrary') {
                navigate(`/questions/detail/${questionId}${parent}`)
              } else {
                navigate(`/questions/detail/${questionId}`)
              }
            }}
          >
            <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  const dataSet: TRows[] = questions?.map(
    (ques: IQuestion) => ({
      ...ques,
      isCollapsable: false,
      randomize: ques?.randomize ? 'Yes' : 'No',
      view: getActionButtons(ques?.id),
      details: [],
    }),
  );

  return (
    <ViewFormLayout testId="question-table">
      <ViewFormLayout.Title>Question</ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <CustomTable
          rows={dataSet}
          columns={columns}
          collapseAllRow={false}
          showColumnsControl={false}
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
