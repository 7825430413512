/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, ReactNode } from 'react'
import { FormControl, Select } from '@mui/material';

interface SelectStyledProps {
  children: ReactElement | ReactNode;
  [key: string]: any,
}

function SelectStyled({ children, ...props }: SelectStyledProps) {
  return (
    <FormControl fullWidth hiddenLabel>
      <Select
        variant="standard"
        sx={{
          '&.Mui-disabled::before': {
            content: '""',
            border: 'none',
          },
          '&::before': {
            content: '""',
            border: 'none',
          },
        }}
        {...props}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default SelectStyled
