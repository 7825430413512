import React from 'react'
import {
  Box, Button, IconButton, Stack, Tooltip,
} from '@mui/material'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ViewFormLayout from 'components/ViewFormLayout'
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import Evaluation from './Evaluation';
import EvaluationChecklist from './EvaluationChecklist';

interface IEvaluationPreviewProps{
  handleClose: () => void;
  handleSave: () => void;
  evaluationId?: number;
}

export const CHECKLIST_FOR_DEMO_ID = 5;

export default function EvaluationPreview(
  { handleSave, handleClose, evaluationId }: IEvaluationPreviewProps,
) {
  return (
    <div>
      <Stack spacing={2}>
        <ViewFormLayout testId="evaluation-preview">
          <ViewFormLayout.ActionBar>
            <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
              <Box>
                <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.PRINT_EVALUATION}>
                  <IconButton
                    sx={{ color: (theme) => theme.palette.primary.main }}
                    aria-label={ACTION_ITEM_TOOLTIP_TEXT.PRINT_EVALUATION}
                  >
                    <LocalPrintshopIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box display="flex" gap={2}>
                <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
                  <Button variant="contained" onClick={handleSave}>{BUTTON_TEXT.SAVE}</Button>
                </Tooltip>
                <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.CANCEL}>
                  <Button onClick={handleClose}>{BUTTON_TEXT.CANCEL}</Button>
                </Tooltip>

              </Box>
            </Box>
          </ViewFormLayout.ActionBar>
          <ViewFormLayout.Body>
            <Box p={2}>
              {
                evaluationId === CHECKLIST_FOR_DEMO_ID ? <EvaluationChecklist /> : <Evaluation />
              }
            </Box>
          </ViewFormLayout.Body>
        </ViewFormLayout>
      </Stack>
    </div>
  )
}
