import React, { useState, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MAP_FILTERS } from 'utils/constants';

const options: string[] = [
  'All',
  'Classroom',
  'Online',
  'Certification',
];

function FilterList({ defaultValue, onSelected, resetAll }:
  { defaultValue: string, onSelected: Function, resetAll: boolean }) {
  const [selectedOption, setSelectedOption] = useState<string>(defaultValue);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filter: string = String(event.target.value);
    setSelectedOption(filter);
    onSelected(MAP_FILTERS[filter])
  };
  useEffect(() => {
    if (resetAll) {
      setSelectedOption('All')
    }
  }, [resetAll]);
  return (
    <RadioGroup
      aria-label="options"
      name="options"
      value={selectedOption}
      onChange={handleOptionChange}
    >
      {options?.map((option) => (
        <FormControlLabel
          key={option}
          value={option}
          control={(
            <Radio sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 14,
              },
            }}
            />
          )}
          label={option}
        />
      ))}
    </RadioGroup>
  );
}

export default FilterList;
