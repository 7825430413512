import {
  Box,
  Button,
  styled,
  Paper,
  Typography,
} from '@mui/material';
import React from 'react'
import ReactPlayer from 'react-player'
import { isAPDF, isAVideo } from 'utils/helpers';
import { BUTTON_TEXT, ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';

interface MyCourseContentProps {
  resource: string;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function MyCourseContent({ resource }: MyCourseContentProps) {
  if (
    isAVideo(resource)
  ) {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={resource}
          width="100%"
          height="100%"
          controls
        />
      </div>

    )
  }

  if (isAPDF(resource)) {
    return (
      <Box height="800px" width="100%" display="flex" justifyContent="center" alignContent="center" bgcolor="#fff">
        <object data={resource} type="application/pdf" width="100%" height="100%">
          <Item>
            <Typography alignContent="center">
              {ACTION_ITEM_TOOLTIP_TEXT.LAUNCH_LEARNING_ACTIVITY_NEW_TAB}
            </Typography>
            <a href={resource} target="_new">
              <Button variant="contained">
                {BUTTON_TEXT.LAUNCH_LEARNING_ACTIVITY}
              </Button>
            </a>
          </Item>
        </object>
      </Box>
    )
  }

  return (
    <Typography alignContent="center">
      {ACTION_ITEM_TOOLTIP_TEXT.LAUNCH_LEARNING_ACTIVITY_NO_PREVIEW_SUPPORT}
    </Typography>
  )
}
