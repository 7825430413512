import React, { useEffect, useState } from 'react'
import { find } from 'lodash'
import {
  Alert,
  Button,
  Tooltip,
} from '@mui/material'
import { useGetEvaluationsQuery, useSubmitEvaluationMutation } from 'services/dataApi';
import ViewFormLayout from 'components/ViewFormLayout';
import { ITestQuestionResponse } from 'utils/interfaces';
import QuestionList from 'modules/LearnerTest/questionList';
import {
  EvaluationAnswer,
  EvaluationQuestion,
  EvaluationRating,
  EvaluationResponse,
  TestAnswer,
  TestQuestion,
} from 'services/interfaces';
import LoadingIcon from 'components/LoadingIcon';

function getQuestions(data: EvaluationResponse) {
  if (!data.questions) return []

  const questions = data.questions.map((question) => {
    let answers: TestAnswer[] = [];
    if (question.ratings) {
      answers = question.ratings.map((rating) => ({
        testAnswerId: rating.evaluationRatingTypeId,
        answerText: rating.evaluationRatingTypeName,
      }))
    }
    return {
      answerId: question.answerId,
      testQuestionId: question.evaluationQuestionId,
      testId: question.evaluationQuestionGroupId,
      testQuestionText: question.evaluationQuestion,
      testQuestionType: 'radioButton',
      testAnswers: answers,
      forceComments: question.forceComments,
    }
  })
  return questions as TestQuestion[];
}

function getAnswers(
  answers: ITestQuestionResponse[],
  questions: EvaluationQuestion[],
  evaluationId: number,
): EvaluationAnswer[] {
  const newAnswers: EvaluationAnswer[] = []
  answers.forEach((ans) => {
    const question = find(questions, { evaluationQuestionId: Number(ans.id) }) as EvaluationQuestion
    if (question?.ratings && question?.ratings.length > 0) {
      const evaluationRating = find(question.ratings, { evaluationRatingTypeName: ans.value }) as
      EvaluationRating;
      if (evaluationRating && evaluationRating.evaluationRatingTypeId) {
        const newAnswer = {
          evaluationQuestionId: Number(ans.id),
          evaluationId,
          evaluationRatingId: evaluationRating.evaluationRatingTypeId,
          instructorUserId: 0,
          evaluationDate: (new Date()).toISOString(),
          comments: '',
        }
        newAnswers.push(newAnswer)
      }
    } else if (question.forceComments) {
      const newAnswer = {
        evaluationQuestionId: Number(ans.id),
        evaluationId,
        instructorUserId: 0,
        evaluationDate: (new Date()).toISOString(),
        comments: ans.value as string,
      }
      newAnswers.push(newAnswer)
    }
  })
  return newAnswers
}

function LaunchEvaluationView({ evaluationId, sessionId }:
  {evaluationId: number, sessionId: number}) {
  const [testResponse, setTestResponse] = React.useState<ITestQuestionResponse[]>([]);
  const skipQuery = !evaluationId || !sessionId;
  // eslint-disable-next-line max-len
  const { data, isLoading } = useGetEvaluationsQuery({ evaluationId, sessionId }, { skip: skipQuery })
  const [error, setError] = useState('');
  const [
    submitEvaluation,
    { isLoading: isLoadingSubmitEvaluation }] = useSubmitEvaluationMutation();
  const [submitDone, setSubmitDone] = useState(false)

  const handleSubmitAction = async () => {
    setError('');
    if (data && data.questions && data.evaluationId) {
      try {
        await submitEvaluation({
          sessionId,
          answers: getAnswers(testResponse, data.questions, data.evaluationId),
        }).unwrap();
      } catch (error: any) {
        setError(error?.data);
      }
      setSubmitDone(true)
    }
  };

  const handleFetchAnswers = () => {
    const responses: ITestQuestionResponse[] = [];
    if (data?.questions) {
      data.questions.forEach((question) => {
        if (question.answerId) {
          const answer = find(question.ratings, { evaluationRatingTypeId: question.answerId })
          if (answer) {
            responses.push({
              id: String(answer.evaluationRatingTypeId),
              value: answer.evaluationRatingTypeName,
            })
          }
        }
      })
      setTestResponse(responses)
    }
  }

  useEffect(() => {
    if (!isLoading) {
      handleFetchAnswers();
    }
  }, [isLoading])

  return (
    <ViewFormLayout testId="my-courses-launch-evaluation">
      <ViewFormLayout.ActionBar hasborderBottom>
        <Tooltip title="Please click here to submit the Evaluation">
          <Button disabled={isLoadingSubmitEvaluation || submitDone} variant="contained" onClick={handleSubmitAction}>
            {isLoadingSubmitEvaluation ? <LoadingIcon /> : 'Submit'}
          </Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        {isLoading
          ? <LoadingIcon />
          : <QuestionList questions={getQuestions(data!)} setTestResponse={setTestResponse} />}
        {error !== '' && <Alert severity="warning">{error}</Alert> }
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}

export default LaunchEvaluationView
