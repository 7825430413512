/* eslint-disable max-len */
import React from 'react';
import { Button, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateFilterValues } from './helpers';
import { LabelValues, filterApplied } from './interface';
import SearchBar from './searchBar';
import SelectFilter from './dropdownfilter';

interface IFilterBarProps {
  showSearchBar: boolean,
  query: string,
  filters: LabelValues[],
  onSearch: Function,
  filterApplied: filterApplied[],
  onSetFilterApplied: Function,
  onClearAllFilters?: Function,
  hasExternalFilters?: boolean,
}

function FilterBar({
  showSearchBar, query, onSearch, filterApplied, filters, onSetFilterApplied, onClearAllFilters, hasExternalFilters,
}: IFilterBarProps) {
  const handleFilterChanges = (_filtro: LabelValues, values: string[]) => {
    const orderMap = {};
    filters.forEach((filter, index) => {
      orderMap[filter.key] = index;
    });

    onSetFilterApplied((_filters: filterApplied[]) => updateFilterValues(_filters, _filtro, values).sort((a, b) => orderMap[a.filterId] - orderMap[b.filterId]))
  }

  return (
    <Stack sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: 3,
    }}
    >
      {showSearchBar && <SearchBar onSearch={onSearch} search={query} />}
      {React.Children.toArray(filters?.map((filtro) => {
        const selected = filterApplied.find((filter) => filter.filterId === filtro?.key)?.filterValue || [];
        return React.Children.toArray(<SelectFilter filtro={filtro} selected={selected} onChange={(val: string[]) => handleFilterChanges(filtro, val)} />)
      }))}
      {(filterApplied?.length > 0 || hasExternalFilters) && (
        <Button
          id="demo-customized-button"
          variant="text"
          color="error"
          disableElevation
          onClick={() => {
            onSetFilterApplied([]);
            if (typeof onClearAllFilters === 'function') {
              onClearAllFilters();
            }
          }}
          startIcon={<DeleteIcon color="error" />}
          sx={{ textTransform: 'none' }}
        >
          Clear filters
        </Button>
      )}
    </Stack>
  );
}

export default FilterBar;
