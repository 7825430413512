/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Badge, BadgeProps, Button, Collapse, styled,
} from '@mui/material';
import { GetJobPositionRequest, UserManagementRequest } from 'services/interfaces';
import { IGNORE_FILTERS_KEY } from 'utils/constants';
import SearchableModal from 'components/SearchableModal';
import ViewFormLayout from 'components/ViewFormLayout';
import JobPositionsFilterForm from './JobPositionsFilterForm';
import JobPositionsTable from './JobPositionsTable';

const INITIAL_FILTERS: GetJobPositionRequest = {
  PageNumber: 1,
  PageSize: 10,
  SortingBy: 'groupId',
  Ascending: true,
  jobPositionId: null,
  jobPosition: null,
  jobPositionDescription: null,
}

interface CatalogProps {
  open: boolean;
  onClose: Function;
  onCallback: Function;
}
export default function JobPositionsCatalog({ open, onClose, onCallback }: CatalogProps) {
  const [showFilters, setShowFilters] = useState<boolean>();
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [filters, setFilters] = useState<GetJobPositionRequest>(INITIAL_FILTERS);

  const handleToggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -10,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const filtersApplyed = (filters: GetJobPositionRequest): number => Object.entries(filters)
    .filter(([key, value]) => value !== null && value !== 0 && !IGNORE_FILTERS_KEY.includes(key))
    .length;

  return (
    <SearchableModal open={open} onClose={onClose} title="Job Position List">
      <ViewFormLayout pl={0} pr={0} testId="users-list">
        <ViewFormLayout.ActionBar>
          <Button
            variant="contained"
            onClick={handleToggleFilters}
          >
            <StyledBadge badgeContent={filtersApplyed(filters)} color="secondary">
              {showFilters ? 'Hide Filters' : 'Filters'}
            </StyledBadge>
          </Button>
          <Button
            variant="contained"
            onClick={() => onCallback(selectedRow)}
          >
            <StyledBadge badgeContent={selectedRow?.length} color="secondary">
              Add
            </StyledBadge>
          </Button>
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Body>
          <Collapse in={showFilters}>
            <JobPositionsFilterForm
              onSearch={(data: UserManagementRequest) => {
                setFilters((_data) => ({ ..._data, ...data }))
                handleToggleFilters()
              }}
              onReset={() => {
                setFilters(INITIAL_FILTERS);
                handleToggleFilters()
              }}
            />
          </Collapse>
          <JobPositionsTable filters={filters} onCallback={(obj: any) => setSelectedRow(obj)} />
        </ViewFormLayout.Body>
      </ViewFormLayout>
    </SearchableModal>
  );
}
